import { Controller, useFormContext } from "react-hook-form"
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  TextField
} from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import {
  getFieldError,
  getDefaultValue,
  getOptionLabelForAutoComplete
} from "./Helper"
import { getTranslations } from "src/utils/helper"
import { useTranslation } from "react-i18next"

interface Option {
  label: string
  value: any
  default?: boolean
}

export interface Props {
  inputAttributes: {
    name: string
    type?: "text" | "email" | "tel"
    label: string
    placeholder?: string
    className?: string
    id?: string
    required?: boolean
    size?: "medium" | "small"
    color?: "error" | "primary" | "secondary" | "info" | "success" | "warning"
    variant?: "standard" | "filled" | "outlined"
    fullWidth?: boolean
    InputProps?: any
    value?: any[]
    defaultValue?: any[]
    multiline?: boolean
    rows?: number
    maxRows?: number
    onChange?: Function
    disabled?: boolean
    options: Option[]
    freeSolo?: boolean
    isAll?: boolean
    isNotSort?: boolean
  }
}

const MUIMultiAutoCompleteText = ({ inputAttributes }: Props) => {
  const { t } = useTranslation<any>()

  let {
    name,
    type,
    label,
    placeholder,
    className,
    id,
    size,
    color,
    variant,
    fullWidth,
    required,
    value,
    defaultValue,
    onChange,
    options,
    disabled,
    freeSolo,
    isNotSort
  } = inputAttributes
  const {
    control,
    formState: { errors }
  } = useFormContext() // retrieve all hook methods

  let newOptions = !isNotSort
    ? [...options].sort((a, b) => a.label.localeCompare(b.label))
    : options

  let defVal: any = getDefaultValue(value, defaultValue, newOptions, true)

  const { isError, ErrorText } = getFieldError(name, errors)

  const optionsWithAll: Option[] = [
    ...(newOptions.length > 0
      ? [
          {
            value: "All",
            label: "All"
          }
        ]
      : []),
    ...newOptions
  ]

  return (
    <Controller
      name={`${name}`}
      control={control}
      defaultValue={defVal}
      render={({ field: { ref, ...field } }) => {
        return (
          <FormControl
            className="custom-form-control select no-border"
            variant="filled"
          >
            <Autocomplete
              ref={ref}
              className={`${className} auto-complete-full`}
              id={id ?? name}
              freeSolo={freeSolo ? true : false}
              options={optionsWithAll}
              value={[field.value].flat(Infinity)}
              multiple
              onChange={(e, v, u, i) => {
                let vals: any[] = v
                if (i?.option.value === "All") {
                  if (u === "selectOption") {
                    vals = newOptions
                  } else if (u === "removeOption") {
                    vals = []
                  }
                }
                field.onChange(
                  vals.map(i => (typeof i === "object" ? i.value : i))
                )
                updateWarnOnTabChangeToStore(true)
                if (onChange) onChange()
              }}
              disabled={disabled ?? false}
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) => {
                if (option.value === "All") {
                  return newOptions.length === field.value.length
                }
                return option.value === value
              }}
              getOptionLabel={option => {
                return getOptionLabelForAutoComplete(option, newOptions)
              }}
              renderOption={(props, { label }, { selected }) => {
                return (
                  <li {...props}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checkedIcon={<CheckIcon />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                      }
                      label={<ListItemText primary={label} />}
                    />
                  </li>
                )
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={`${getTranslations(t, label)}`}
                  fullWidth={fullWidth ?? true}
                  required={required ?? false}
                  size={size ?? "small"}
                  placeholder={placeholder}
                  disabled={disabled ?? false}
                  type={type ?? "text"}
                  variant={variant ?? "filled"}
                  color={color ?? "primary"}
                  error={isError}
                  helperText={ErrorText}
                />
              )}
            />
          </FormControl>
        )
      }}
    />
  )
}

export default MUIMultiAutoCompleteText
