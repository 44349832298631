import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"

import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { PATH } from "src/constants/paths"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { setNewReportColumns } from "src/redux/slices/configSlice"
import {
  getTranslations,
  setWeekFieldError,
  validateDecimal
} from "src/utils/helper"
import { updateWarnOnTabChangeToStore } from "../helper"
import { getCurrencySymbol } from "../CountFields/Constants"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import OutputFilesBreadCrumb from "./OutputFilesBreadCrumb"
import { getCurrencySymbolField } from "../ColumnHelper"
import { DEFAULT_RADIX } from "src/utils/constants"
interface INewColumn {
  DataType: string
  FilterType: string
  ColumnName: string
  SortOrder: string
  SortType: string
  DataSource: string
  Suppress: boolean
  Weight: string
  NumberOfDecimals: string
  CurrencySymbol: string
}
const CreateEditColumn = () => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  let search = window.location.search || ""
  const location = useLocation() as any
  const { cMap, cFullMap, cDisplayMap } = getCurrencySymbol()

  const dispatch = useAppThunkDispatch()
  const configData = useSelector((state: RootState) => state.config)
  const reportColumnsData = useSelector(
    (state: RootState) => state.config.newReportColumns
  )
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )
  const isEdit = location.state.isEdit
  const editReportingColumnData = location.state.editColumnObj
  const storeId = location.state.storeId
  const reportId = location.state.storeId

  const dataTypeValues = ["", "DECIMAL", "ALPHA", "NUMERIC"]
  const currencies = [
    "No Currency Symbol",
    "$ - USD - US Dollar",
    "$ - MXN - MEXICAN PESO",
    "$ - CAD - Canadian Dollar",
    "¥ - CNY - Chinese Yuan"
  ]
  const filterTypeValues = [
    "",
    "CHOOSE1",
    "CONTAINS",
    "GREATER_OR_LESS_THAN",
    "EXACT_MATCH"
  ]
  const sqlQueryColumns = useSelector(
    (state: RootState) => state.config.newReportSQLQueryColumns
  )

  const [weekIVFields, setWeekIVFields] = useState({
    ColumnName: ""
  })
  const getBreadcrumbConfig = () => {
    let currentPath: string = `audits`
    let variables: any = {
      audits: location.state.isEdit
        ? getTranslations(t, Translates.Edit_Column)
        : getTranslations(t, Translates.New_Column)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }

  const onCancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      if (reportId) {
        history.push(
          PATH.EDIT_REPORTING.replace(":storeId", `${reportId}`) + search,
          {
            showLatestColumnValue: true
          }
        )
      } else {
        history.push(PATH.CREATE_REPORTING + search, {
          showLatestColumnValue: true
        })
      }
    })
  }
  const onSaveClick = () => {
    let _item

    const _sortOrder =
      reportColumnsData?.length > 0
        ? reportColumnsData?.reduce(
            (a: any, b: { SortOrder: number }) => Math.max(b.SortOrder) || a,
            0
          )
        : 0

    // build the currency value
    let currencySymbol = ""
    let OriginalCurrencySymbol = ""
    if (form.CurrencySymbol !== "" && form.CurrencySymbol !== currencies[0]) {
      currencySymbol = cDisplayMap.get(form.CurrencySymbol)
      OriginalCurrencySymbol = cMap.get(form.CurrencySymbol)
    }
    // Sort Order
    let SortOrder = location.state?.isEdit
      ? editReportingColumnData.SortOrder
      : _sortOrder + 1

    // add the sort order
    _item = {
      ...form,
      SortOrder: location.state?.isEdit
        ? editReportingColumnData.SortOrder
        : _sortOrder + 1
    }

    // format the decimal & weight
    _item = {
      ...form,
      Weight: form.Weight !== "" ? parseInt(form.Weight, DEFAULT_RADIX) : 0,
      SortOrder: SortOrder,
      CurrencySymbol: currencySymbol,
      OriginalCurrencySymbol: OriginalCurrencySymbol,
      NumberOfDecimals: form.NumberOfDecimals
        ? parseInt(form.NumberOfDecimals, DEFAULT_RADIX)
        : 0
    }

    if (form.DataType !== dataTypeValues[1]) {
      delete _item?.CurrencySymbol
      delete _item?.OriginalCurrencySymbol
      delete _item?.NumberOfDecimals
    }
    const _promptItem = { ..._item }

    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      if (isEdit) {
        let newArr = [...reportColumnsData]
        newArr[editReportingColumnData.SortOrder - 1] = _promptItem
        dispatch(setNewReportColumns([...newArr]))
        if (reportId) {
          history.push(
            PATH.EDIT_REPORTING.replace(":storeId", `${reportId}`) + search,
            {
              showLatestColumnValue: true
            }
          )
        } else {
          history.push(
            PATH.CREATE_REPORTING + search.replace(":storeId", `${storeId}`),
            {
              showLatestColumnValue: true
            }
          )
        }
      } else {
        dispatch(
          setNewReportColumns([...configData?.newReportColumns, _promptItem])
        )
        if (reportId) {
          history.push(
            PATH.EDIT_REPORTING.replace(":storeId", `${reportId}`) + search,
            {
              showLatestColumnValue: true
            }
          )
        } else {
          history.push(
            PATH.CREATE_REPORTING + search.replace(":storeId", `${storeId}`),
            {
              showLatestColumnValue: true
            }
          )
        }
      }
    })
  }

  useEffect(() => {
    setEditColumnData()
  }, [])

  const setEditColumnData = () => {
    if (isEdit) {
      let cSymbol = currencies[0]

      if (editReportingColumnData?.CurrencySymbol) {
        if (
          editReportingColumnData.OriginalCurrencySymbol &&
          cFullMap.has(editReportingColumnData.OriginalCurrencySymbol)
        ) {
          cSymbol = cFullMap.get(editReportingColumnData.OriginalCurrencySymbol)
        } else if (cFullMap.has(editReportingColumnData.CurrencySymbol)) {
          cSymbol = cFullMap.get(editReportingColumnData.CurrencySymbol)
        }
      }

      const editObj = {
        Label: editReportingColumnData?.Label,
        VariableName: editReportingColumnData?.VariableName,
        Type: editReportingColumnData?.Type,
        DefaultValue: editReportingColumnData?.DefaultValue,
        DataType: editReportingColumnData?.DataType,
        FilterType: editReportingColumnData?.FilterType,
        ColumnName: editReportingColumnData?.ColumnName,
        SortOrder: editReportingColumnData?.SortOrder,
        SortType: editReportingColumnData?.SortType,
        DataSource: editReportingColumnData?.DataSource,
        Suppress: editReportingColumnData?.Suppress,
        Weight: editReportingColumnData?.Weight,
        NumberOfDecimals: editReportingColumnData?.NumberOfDecimals,
        CurrencySymbol: cSymbol
      }
      setForm(editObj)
    }
  }

  const [form, setForm] = React.useState<INewColumn>({
    DataType: "",
    FilterType: "",
    ColumnName: "",
    SortOrder: "",
    SortType: "",
    DataSource: "",
    Suppress: false,
    Weight: "100",
    NumberOfDecimals: "",
    CurrencySymbol: ""
  } as INewColumn)

  const onFormChange = e => {
    updateWarnOnTabChangeToStore(true)
    const val = e.target.value || ""
    if (e.target?.name) {
      if (e.target.name === "Suppress") {
        setForm({ ...form, [e.target.name]: e.target.checked })
      } else {
        setForm({ ...form, [e.target.name]: val })
      }
      if (e.target?.name === "DataSource") {
        setForm({ ...form, [e.target.name]: val })
      }
      if (e.target?.name === "DataType") {
        setForm({
          ...form,
          [e.target.name]: val,
          NumberOfDecimals: "",
          CurrencySymbol: ""
        })
      }
      if (e.target?.name === "NumberOfDecimals") {
        if (!validateDecimal(val)) {
          setForm({
            ...form,
            [e.target.name]: val.substring(0, val?.length - 1)
          })
        }
      }

      if (e.target?.name === "ColumnName") {
        setWeekFieldError({ setWeekIVFields, val, t, field: e.target.name })
      }
    }
  }

  const validationCheck = () => {
    if (
      !form.DataSource ||
      !form.ColumnName ||
      configTypeFromStore === "view"
    ) {
      return true
    }
    return false
  }

  return (
    <div>
      <OutputFilesBreadCrumb
        customPath="Output Prompt Details"
        bConfig={getBreadcrumbConfig()}
      />
      <Grid container className="main-container">
        <Grid item xs={12} sm={3} className="mb-8">
          <label className="form-control__label mb-3">
            {getTranslations(t, Translates.DETAILS)}
          </label>
          <div className="text-box">
            <FormControl
              variant="filled"
              className="custom-form-control select"
            >
              <InputLabel id="demo-multiple-checkbox-label" variant="filled">
                {getTranslations(t, Translates.Data_Source)} *
              </InputLabel>

              <Select
                id="demo-multiple-checkbox"
                value={form.DataSource || ""}
                name="DataSource"
                onChange={e => onFormChange(e)}
              >
                {sqlQueryColumns?.map((item: any) => (
                  <MenuItem
                    key={item.ColumnName}
                    value={item.ColumnName}
                    className="dropdown-list"
                  >
                    <small>{item.ColumnName}</small>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label={`${getTranslations(t, Translates.Name)} *`}
              variant="filled"
              autoSave="false"
              autoComplete="off"
              className="custom-form-control"
              name="ColumnName"
              data-testid="name"
              value={form?.ColumnName}
              onChange={e => onFormChange(e)}
              error={!!weekIVFields.ColumnName}
              helperText={weekIVFields.ColumnName}
            />

            <FormControl
              variant="filled"
              className="custom-form-control select"
            >
              <InputLabel id="demo-multiple-checkbox-label" variant="filled">
                {getTranslations(t, Translates.Data_Type)}
              </InputLabel>
              <Select
                id="demo-multiple-checkbox"
                value={form?.DataType}
                name="DataType"
                onChange={e => onFormChange(e)}
              >
                {dataTypeValues?.map((name: any) => (
                  <MenuItem key={name} value={name} className="dropdown-list">
                    <small>{name}</small>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {form.DataType === "DECIMAL" ? (
              <TextField
                fullWidth
                label={getTranslations(t, Translates.Enter_Decimal_Places)}
                variant="filled"
                autoSave="false"
                autoComplete="off"
                className="custom-form-control"
                name="NumberOfDecimals"
                value={form.NumberOfDecimals}
                onChange={e => onFormChange(e)}
              />
            ) : null}
            {getCurrencySymbolField(form, t, onFormChange, currencies)}

            <TextField
              fullWidth
              label={getTranslations(t, Translates.Weight)}
              variant="filled"
              autoSave="false"
              autoComplete="off"
              data-testid="weight"
              className="custom-form-control"
              name="Weight"
              value={form.Weight}
              onChange={e => onFormChange(e)}
            />

            <FormControl
              variant="filled"
              className="custom-form-control select"
            >
              <InputLabel id="demo-multiple-checkbox-label" variant="filled">
                {getTranslations(t, Translates.Sort_Type)}
              </InputLabel>
              <Select
                id="demo-multiple-checkbox"
                value={form?.SortType}
                name="SortType"
                onChange={e => onFormChange(e)}
              >
                {dataTypeValues?.map((name: any) => (
                  <MenuItem key={name} value={name} className="dropdown-list">
                    <small>{name}</small>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              variant="filled"
              className="custom-form-control select"
            >
              <InputLabel id="demo-multiple-checkbox-label" variant="filled">
                {getTranslations(t, Translates.Filter_Type)}
              </InputLabel>
              <Select
                id="demo-multiple-checkbox"
                value={form?.FilterType}
                name="FilterType"
                onChange={e => onFormChange(e)}
              >
                {filterTypeValues?.map((name: any) => (
                  <MenuItem key={name} value={name} className="dropdown-list">
                    <small>{name}</small>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  name="Suppress"
                  checked={form?.Suppress}
                  defaultChecked={false}
                  className="custom-check light"
                  data-testid="suppress"
                  onChange={e => onFormChange(e)}
                  checkedIcon={<CheckIcon />}
                />
              }
              label={getTranslations(t, Translates.Suppress)}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="inner-view__footer">
            <Button
              variant="text"
              className="primary-btn cancel-btn mr-5"
              onClick={() => onCancelClick()}
              data-testid="cancel"
            >
              {getTranslations(t, Translates.Cancel)}
            </Button>
            <Button
              variant="contained"
              className="primary-btn"
              onClick={() => onSaveClick()}
              disabled={validationCheck()}
              data-testid="save"
            >
              {getTranslations(t, Translates.Save)}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default CreateEditColumn
