import { Translates } from "src/i18n/i18n"
import {
  NoFullVarianceFieldsText,
  NoGroupVarianceFieldsText,
  RadioButtonLists,
  ReportGridModalSource,
  VarianceTypes,
  defaultIniValues,
  defaultReportFieldIniValues,
  fieldNames,
  singleFieldNames
} from "./Constants"
import {
  ConfigVarianceCommonType,
  ConfigVarianceType,
  VarianceCommonType,
  VarianceType
} from "./Type"
import { IconButton, Tooltip } from "@mui/material"
import editIcon from "src/assets/images/Edit.png"
import TrashIcon from "src/assets/images/TrashIcon.svg"
import { TableComponentNames } from "src/constants/globalTableDragDrop"
import { getTranslations } from "src/utils/helper"
import { displaySpan } from "../../FunctionHelper"
import AlertConfirmationContent from "../../Components/AlertConfirmationContent"

export const a11Props = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  }
}

export const getDefaultValues = (varianceRedux: VarianceType) => {
  if (varianceRedux && Object.keys(varianceRedux)?.length) return varianceRedux
  return defaultIniValues
}

export const getReportFieldDefaultValues = (
  varianceRedux: VarianceType,
  storeId: number
) => {
  if (varianceRedux && Object.keys(varianceRedux).length) {
    if (storeId > -1)
      return (
        varianceRedux[varianceRedux.CurrentTabVarianceType].ReportFields[
          storeId - 1
        ] || defaultReportFieldIniValues
      )
  }
  return defaultReportFieldIniValues
}

export const getVarianceFormVariable = (selectedTab: string) => {
  let varianceForm = fieldNames.GroupVariance
  let gridComponent = TableComponentNames.groupVariances
  let gridNoDataText = NoGroupVarianceFieldsText
  if (selectedTab === VarianceTypes.FullVariance.name) {
    varianceForm = fieldNames.FullVariance
    gridComponent = TableComponentNames.fullVariances
    gridNoDataText = NoFullVarianceFieldsText
  }
  return { varianceForm, gridComponent, gridNoDataText }
}

export const setFocusWhileChange = async (
  fieldName: string,
  setFocus: Function
) => {
  await new Promise(resolve => setTimeout(resolve, 100))
  setFocus(fieldName)
}

export const getRadioArr = (
  varianceForm,
  Threshold,
  CurrentTabVarianceType,
  IsBasedOnControlFile
) => {
  let ThresholdSelects = JSON.parse(
    JSON.stringify(RadioButtonLists.ThresholdSelects)
  )
  if (
    CurrentTabVarianceType === VarianceTypes.FullVariance.name &&
    !IsBasedOnControlFile
  ) {
    delete ThresholdSelects?.VarianceControlFile
  }
  return [
    {
      key: "Threshold",
      name: varianceForm.Threshold.name,
      label: "Threshold_Settings",
      formControlLabels: RadioButtonLists.Thresholds,
      isShow: true,
      required: true
    },
    {
      key: "QtyThreshold",
      name: varianceForm.QtyThreshold.name,
      label: "Qty_Threshold",
      formControlLabels: ThresholdSelects,
      isShow: !["", RadioButtonLists.Thresholds.Ext.value].includes(Threshold),
      isConstantShow: true,
      constantName: varianceForm.QtyConstant.name,
      constantShowValue: ThresholdSelects.Constant.value,
      constant: {
        name: varianceForm.QtyConstant.name,
        label: "Field",
        originalName: singleFieldNames.QtyConstant
      },
      required: true
    },
    {
      key: "ExtThreshold",
      name: varianceForm.ExtThreshold.name,
      label: "Ext_Threshold",
      formControlLabels: ThresholdSelects,
      isShow: !["", RadioButtonLists.Thresholds.Qty.value].includes(Threshold),
      constantShowValue: ThresholdSelects.Constant.value,
      constant: {
        name: varianceForm.ExtConstant.name,
        label: "Field",
        originalName: singleFieldNames.ExtConstant
      },
      required: true
    }
  ]
}

const getActionSpan = (
  props,
  t,
  IsNotEnableFeature,
  editField,
  deleteField,
  configTypeFromStore
) => {
  return (
    <span className="action-btns d-block">
      <Tooltip arrow title={getTranslations(t, Translates.Edit)}>
        <IconButton
          data-testid="edit"
          disabled={IsNotEnableFeature}
          onClick={() => editField(props.row)}
        >
          <img
            alt={"edit-img"}
            src={editIcon}
            className={IsNotEnableFeature ? `disable-img` : ``}
          />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title={getTranslations(t, Translates.Delete)}>
        <span>
          <IconButton
            data-testid="Delete"
            disabled={configTypeFromStore === "view" || IsNotEnableFeature}
            onClick={() => deleteField(props.row)}
          >
            <img
              alt={"delete-img"}
              src={TrashIcon}
              className={
                `${
                  configTypeFromStore === "view"
                    ? "disable-img"
                    : "mx-1 pointer"
                }` || (IsNotEnableFeature ? `disable-img` : ``)
              }
            />
          </IconButton>
        </span>
      </Tooltip>
    </span>
  )
}
export const getReportColumnData = (
  t,
  IsNotEnableFeature,
  editField,
  deleteField,
  configTypeFromStore
) => {
  const commonColumnsData = {
    disableFilters: true,
    disableSortBy: true,
    headerClassName: "temp_class",
    Cell: props => {
      return displaySpan(props, "-")
    }
  }

  return [
    {
      ...commonColumnsData,
      Header: "",
      accessor: "SortOrder",
      setWidth: 2
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Field),
      accessor: "Field", // accessor is the "key" in the data
      setWidth: 300
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.Name),
      accessor: "Name", // accessor is the "key" in the data
      setWidth: 600
    },
    {
      ...commonColumnsData,
      Header: getTranslations(t, Translates.ACTIONS),
      accessor: "action", // accessor is the "key" in the data
      setWidth: 150,
      Cell: props => {
        return getActionSpan(
          props,
          t,
          IsNotEnableFeature,
          editField,
          deleteField,
          configTypeFromStore
        )
      }
    }
  ]
}

export const setModalContent = ({
  preDeletedRow,
  modalOpenSource,
  ReportRemovedFields,
  t
}) => {
  let content = ""
  if (modalOpenSource === ReportGridModalSource.delete) {
    content = `${getTranslations(
      t,
      Translates.Are_you_sure_you_want_to_delete
    )} ${preDeletedRow.Field}?`
  } else if (modalOpenSource === ReportGridModalSource.blindVariance) {
    content = `${getTranslations(
      t,
      Translates.Are_you_sure_you_want_to_delete
    )} "${ReportRemovedFields.join(", ")}"?`
  }
  return <AlertConfirmationContent warningMsg={content} />
}

export const getFormToConfigConversion = (
  data: VarianceType
): { configData: ConfigVarianceType; vErrors: string[] } => {
  let vErrors: string[] = []
  const configData = {
    GroupVariance: getGroupAndFullVarianceConfig(data.GroupVariance),
    FullVariance: {
      ...getGroupAndFullVarianceConfig(data.FullVariance),
      ...{
        BasedOnControlFile:
          data[fieldNames.FullVariance.IsBasedOnControlFile.name]
      }
    }
  }
  return { configData, vErrors }
}

const getGroupAndFullVarianceConfig = (
  data: VarianceCommonType
): ConfigVarianceCommonType => {
  if (!data) {
    return {} as ConfigVarianceCommonType
  }
  return {
    Enabled: data.IsEnable,
    Name: data.VarianceName,
    Sort: data.Sort,
    BatchSizeEnabled: data.IsBatchSize,
    BatchSize: `${data.BatchSize}` || "0",
    BlindVariance: data.IsBlindVariance,
    RequiredForCloseout: data.IsRequiredForCloseout,
    ThresholdType: data.Threshold,
    ...(!["", RadioButtonLists.Thresholds.Ext.value].includes(
      data.Threshold
    ) && {
      QtyThreshold: data.QtyThreshold
    }),
    ...(!["", RadioButtonLists.Thresholds.Ext.value].includes(data.Threshold) &&
      RadioButtonLists.ThresholdSelects.Constant.value ===
        data.QtyThreshold && {
        QtyThresholdConstant: data.QtyConstant || 0
      }),
    ...(!["", RadioButtonLists.Thresholds.Qty.value].includes(
      data.Threshold
    ) && {
      ExtThreshold: data.ExtThreshold
    }),
    ...(!["", RadioButtonLists.Thresholds.Qty.value].includes(data.Threshold) &&
      RadioButtonLists.ThresholdSelects.Constant.value ===
        data.ExtThreshold && {
        ExtThresholdConstant: data.ExtConstant || 0
      }),
    Fields: data.ReportFields
  }
}

export const getConfigToFormConversion = (
  data: ConfigVarianceType
): { reduxData: VarianceType } => {
  let reduxData = {
    ...defaultIniValues,
    GroupVariance: {
      ...defaultIniValues.GroupVariance,
      ...getGroupAndFullVarianceForm(data.GroupVariance)
    },
    FullVariance: {
      ...defaultIniValues.FullVariance,
      ...getGroupAndFullVarianceForm(data.FullVariance),
      ...{
        ...(data.FullVariance.BasedOnControlFile !== undefined && {
          IsBasedOnControlFile: data.FullVariance.BasedOnControlFile
        })
      }
    }
  }
  return {
    reduxData
  }
}

const getGroupAndFullVarianceForm = (
  data: ConfigVarianceCommonType
): VarianceCommonType => {
  return {
    ...(data.Enabled !== undefined && {
      IsEnable: data.Enabled
    }),
    ...(data.Name !== undefined && {
      VarianceName: data.Name
    }),
    ...(data.Sort !== undefined && {
      Sort: data.Sort
    }),
    ...(data.BatchSizeEnabled !== undefined && {
      IsBatchSize: data.BatchSizeEnabled
    }),
    ...(data.BatchSize !== undefined && {
      BatchSize: Number(data.BatchSize || 0)
    }),
    ...(data.BlindVariance !== undefined && {
      IsBlindVariance: data.BlindVariance
    }),
    ...(data.RequiredForCloseout !== undefined && {
      IsRequiredForCloseout: data.RequiredForCloseout
    }),
    ...(data.ThresholdType !== undefined && {
      Threshold: data.ThresholdType
    }),
    ...(data.QtyThreshold !== undefined && {
      QtyThreshold: data.QtyThreshold
    }),
    ...(data.QtyThresholdConstant !== undefined && {
      QtyConstant: data.QtyThresholdConstant
    }),
    ...(data.ExtThreshold !== undefined && {
      ExtThreshold: data.ExtThreshold
    }),
    ...(data.ExtThresholdConstant !== undefined && {
      ExtConstant: data.ExtThresholdConstant
    }),
    ...(data.Fields !== undefined && {
      ReportFields: data.Fields
    })
  } as VarianceCommonType
}

export const getReportFieldBreadcrumbConfig = (varianceRedux, t, storeId) => {
  let variances = getTranslations(t, Translates.variances)
  let custLinks: any = {}
  custLinks["@variances"] = "configuration/variances"
  let varianceType =
    varianceRedux.CurrentTabVarianceType === VarianceTypes.GroupVariance.name
      ? getTranslations(t, Translates.Group_Variance)
      : getTranslations(t, Translates.Full_Variance)
  let addField =
    storeId <= -1
      ? getTranslations(t, Translates.Add_Field)
      : getTranslations(t, Translates.Edit_Field)
  let currentPath: string = `##configuration##/@${variances}/${varianceType}/${addField}`
  let variables: any = {}
  variables[`@${variances}`] = getTranslations(t, Translates.Variances)
  variables[`${varianceType}`] =
    varianceRedux.CurrentTabVarianceType === VarianceTypes.GroupVariance.name
      ? getTranslations(t, Translates.Group_Variance)
      : getTranslations(t, Translates.Full_Variance)
  variables[`${addField}`] =
    storeId <= -1
      ? getTranslations(t, Translates.Add_Field)
      : getTranslations(t, Translates.Edit_Field)
  let bConfig: any = {
    custLinks,
    showHomeIcon: false,
    currentPath,
    mapObj: variables
  }
  return bConfig
}
// To show Regions in location and schedule grid
const showRegions = (regionalGroupLabel, region) => {
  return (
    <p>
      <span className="f-600 mr-1">{regionalGroupLabel}:</span>
      {region ? region : "-"}
    </p>
  )
}
// To get the regional groups for location and schedule grid
export const getRegionalGroups = (
  regionalGroup1Label,
  regionalGroup2Label,
  regionalGroup3Label,
  regionalGroup4Label,
  originalRow
) => {
  return (
    <>
      {regionalGroup1Label
        ? showRegions(regionalGroup1Label, originalRow?.region1)
        : null}
      {regionalGroup2Label
        ? showRegions(regionalGroup2Label, originalRow?.region2)
        : null}
      {regionalGroup3Label
        ? showRegions(regionalGroup3Label, originalRow?.region3)
        : null}
      {regionalGroup4Label
        ? showRegions(regionalGroup4Label, originalRow?.region4)
        : null}
    </>
  )
}
