import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from "@mui/material"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import LinearProgress from "@mui/material/LinearProgress"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import { PATH } from "src/constants/paths"
import { useEffect, useState } from "react"
import { SettingsService } from "src/service/settings.service"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import shortid from "shortid"
import { Translates } from "src/i18n/i18n"
import BulkUploadButtons from "../Component/BulkUploadButtons"
import { getTranslations } from "src/utils/helper"
import { BULK_ACTIONS } from "src/utils/constants"

const LocationBulkValidation = ({ setPage }) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const settingsService = new SettingsService()
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [responseData, setResponseData] = useState({} as any)
  const [ignoreState, setIgnoreState] = useState("")
  const [disable, setDisable] = useState(true)
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const mappingPayloadData = useSelector(
    (state: RootState) => state.settings?.mappingPayloadData
  )

  useEffect(() => {
    LocationValidationPost(false, null)
  }, [])

  let config = {
    onUploadProgress: progressEvent => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      setProgress(percentCompleted)
    }
  }
  const LocationValidationPost = async (
    isConfirmed: boolean,
    confirmationAction: any
  ) => {
    const _payload = {
      idCustomer: mappingPayloadData.idCustomer,
      isConfirmed: isConfirmed,
      confirmationAction: confirmationAction,
      columns: mappingPayloadData.columns,
      fileData: mappingPayloadData.fileData
    }
    if (!isConfirmed) {
      setLoading(true)
    }
    settingsService
      .bulkUpdateLocation(_payload, isConfirmed ? {} : config)
      .then(res => {
        setResponseData(res.data)
        setDisable(false)
        setLoading(false)
        if (isConfirmed) {
          history.push(PATH.LOCATIONS)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }
  const handleStatusChange = e => {
    setIgnoreState(e.target.value)
  }
  const handleNoCustomerId = () => {
    if (!customerId) {
      setTimeout(() => {
        history.push(PATH.LOCATIONS)
      }, 3000)
    }
  }
  const handlePreviousClick = () => {
    setPage("page2")
  }
  const handleCancelClick = () => {
    history.push(PATH.LOCATIONS)
  }
  const saveDisable = () => {
    if (disable) {
      return true
    } else if (
      responseData?.duplicateRowsSiteId?.length > 0 &&
      ignoreState === ""
    ) {
      return true
    } else if (responseData && responseData.locationErrors?.length > 0) {
      return true
    } else {
      return progress < 100
    }
  }
  const handleImportClick = () => {
    let continueStatus: string | null = null
    const UPDATE_STRING: string = BULK_ACTIONS?.UPDATE
    if (responseData.duplicateRowsSiteId?.length > 0) {
      if (ignoreState === UPDATE_STRING) {
        continueStatus = UPDATE_STRING
      } else {
        continueStatus = BULK_ACTIONS?.IGNORE
      }
    }
    LocationValidationPost(true, continueStatus)
  }
  return (
    <>
      <Grid container>
        <MiniHeader
          headerTitle={getTranslations(t, Translates.UPLOAD_LOCATIONS)}
          multiCustomer={handleNoCustomerId}
        />
        <Grid container className="inner-view">
          <Grid item xs={12} className="main-container">
            <Grid container>
              <Grid item xs={12}>
                <h4 className="inner-view__title">
                  {getTranslations(t, Translates.Validation)}
                </h4>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <p className="font-16 f-700 mb-0">
                {getTranslations(t, Translates.Validation)}{" "}
                {progress == 100
                  ? getTranslations(t, Translates.Completed)
                  : getTranslations(t, Translates.In_Progress)}
              </p>
              <Grid container spacing={2} className="justify-content-end mb-5">
                <span className="justify-content-end font-15">
                  {progress}% {getTranslations(t, Translates.Completed)}
                </span>
                <Grid item sm={12}>
                  <div className={`custom-progressbar`}>
                    <LinearProgress
                      color="success"
                      value={progress}
                      className={`custom-progressbar__bar}`}
                      variant="determinate"
                    />
                  </div>
                </Grid>
              </Grid>
              {!loading ? (
                <Grid container>
                  <Grid item xs={12} sm={10}>
                    <Divider className="dashed mb-3" />
                    <p className="font-18 f-700">
                      {getTranslations(t, Translates.Validation_Outcome)}
                    </p>
                    <Divider className="mb-3" />
                    <p className="font-15 f-700">
                      {getTranslations(t, Translates.File_Statistics)}
                    </p>
                    {responseData?.recordsToInsert > 0 ? (
                      <Accordion className="custom-accordion dark validates success pointer-events-none">
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <h5>{`${responseData?.recordsToInsert} ${
                            responseData?.recordsToInsert > 1
                              ? getTranslations(
                                  t,
                                  Translates.Records_will_be_inserted
                                )
                              : getTranslations(
                                  t,
                                  Translates.Record_will_be_inserted
                                )
                          }
                          `}</h5>
                        </AccordionSummary>
                      </Accordion>
                    ) : (
                      <Accordion className="custom-accordion dark validates success pointer-events-none">
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <h5>
                            {" "}
                            {getTranslations(
                              t,
                              Translates.No_Records_will_be_inserted
                            )}{" "}
                          </h5>
                        </AccordionSummary>
                      </Accordion>
                    )}
                    <Grid item xs={12} sm={12} className="mt-4">
                      {responseData?.duplicateRowsSiteId?.length ? (
                        <Accordion className="custom-accordion dark validates info">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <h5>
                              {`${responseData?.duplicateRowsSiteId?.length} ${
                                responseData?.duplicateRowsSiteId?.length > 1
                                  ? ` ${getTranslations(t, Translates.Sites)}`
                                  : ` ${getTranslations(t, Translates.Site)}`
                              }`}
                              {` ${getTranslations(
                                t,
                                Translates.already_exist_in_FlexCount
                              )}`}
                            </h5>

                            <Grid item xs={12} sm={6} className="mt-1">
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="controlled-radio-buttons-group"
                                  value={ignoreState}
                                  onChange={handleStatusChange}
                                >
                                  <FormControlLabel
                                    control={<Radio />}
                                    label={`${getTranslations(
                                      t,
                                      Translates.Update
                                    )}`}
                                    value={BULK_ACTIONS?.UPDATE}
                                  />
                                  <FormControlLabel
                                    control={<Radio />}
                                    label={`${getTranslations(
                                      t,
                                      Translates.Ignore
                                    )}`}
                                    value={BULK_ACTIONS?.IGNORE}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container xs={12}>
                              <Grid item xs={12} sm={12}>
                                <ul>
                                  {responseData?.duplicateRowsSiteId?.map(w => {
                                    return <li key={shortid.generate()}>{w}</li>
                                  })}
                                </ul>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                      {responseData?.locationErrors?.length ? (
                        <Accordion className="custom-accordion dark validates error">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <h5>{`${responseData?.errorRecordsCount} ${
                              responseData?.errorRecordsCount > 1
                                ? getTranslations(
                                    t,
                                    Translates.Records_have_errors
                                  )
                                : getTranslations(
                                    t,
                                    Translates.Record_has_error
                                  )
                            }`}</h5>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul>
                              {responseData?.locationErrors?.map(w => {
                                return <li key={shortid.generate()}>{w}</li>
                              })}
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                      {responseData?.locationWarnings?.length ||
                      responseData?.regionalGroupLevelWarnings?.length ? (
                        <Accordion className="custom-accordion dark validates warning">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <h5>{`${responseData?.warningRecordsCount} ${
                              responseData?.warningRecordsCount > 1
                                ? getTranslations(
                                    t,
                                    Translates.Records_have_warnings
                                  )
                                : getTranslations(
                                    t,
                                    Translates.Record_has_warning
                                  )
                            }`}</h5>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul>
                              {responseData?.locationWarnings?.map(w => {
                                return <li key={shortid.generate()}>{w}</li>
                              })}
                              {responseData?.regionalGroupLevelWarnings?.map(
                                w => {
                                  return <li key={shortid.generate()}>{w}</li>
                                }
                              )}
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <BulkUploadButtons
                previousBLabel={getTranslations(t, Translates.Previous)}
                handlePreviousClick={handlePreviousClick}
                cancelBLabel={getTranslations(t, Translates.Cancel)}
                handleCancelClick={handleCancelClick}
                nextBLabel={getTranslations(t, Translates.Next_Begin_Import)}
                handleNextClick={handleImportClick}
                saveDisable={saveDisable}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
export default LocationBulkValidation
