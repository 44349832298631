import { useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { getTranslations, replaceAllMultiple } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { ConfigTabNames } from "../../helper"
import FormFieldConditionRender from "../FormFieldConditionRender"
import CBreadCrumb from "../CBreadCrumb"
import {
  getBreadcrumbConfig,
  getDefaultValues,
  getTotalBasicFields
} from "./Utils/Helpers/CreateEditTotalHelper"
import { validationSchema } from "./Utils/CreateEditTotalValidation"
import Values from "./Components/CreateEditTotal/Values"
import { placementField } from "./Utils/Constants/CreateEditTotalConstant"
import FormSubmitCancel from "./Components/CreateEditTotal/FormSubmitCancel"
import { getVariableBasedOnComponent } from "./Utils/Helpers/TotalsGridHelper"
import FormHeading from "src/components/FormHeading"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { setValueForVariable } from "../../FunctionHelper"
import { conditionCheckOnLoading } from "../Columns/Utils/Helpers/CreateEditColumnHelper"

const CreateEditTotal = () => {
  const { t } = useTranslation<any>()
  let location = useLocation() as any
  const history = useHistory()
  const {
    config: { configDataHookCalled },
    columnReduxData: { columnsRedux }
  } = ConfigDataHook()

  const {
    id,
    tab,
    tabId,
    type
  }: { id: string; tab: string; tabId: string; type: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const storeTabId: number = tabId !== undefined ? Number(tabId) : -1 //want to get index of array means subtract 1
  let search = window.location.search || ""

  const isSubtotal =
    type.toLocaleLowerCase() === ConfigTabNames.SubTotals.toLocaleLowerCase()

  const stateVariableRedux = location.state?.variableRedux
  const stateColumnRedux = location.state?.columnRedux
  const isDataGroupField = isSubtotal

  let {
    variableRedux,
    variablesRedux,
    variableSetRedux,
    isPlacementField,
    redirectUrl
  } = getVariableBasedOnComponent(tab, type, storeTabId, storeId)
  redirectUrl =
    replaceAllMultiple(redirectUrl, [":tab", ":id"], [tab, tabId]) + search

  const locVariableRedux = setValueForVariable({
    values: [stateVariableRedux, variableRedux, variablesRedux[storeTabId - 1]],
    defaultValue: {}
  })

  const locColumnsRedux = setValueForVariable({
    values: [stateColumnRedux, columnsRedux],
    defaultValue: {}
  })

  const basicFields = getTotalBasicFields(locColumnsRedux, isDataGroupField)

  const locGetDefaultValues = () => {
    return getDefaultValues(locVariableRedux, storeId, tab, type)
  }

  const formHookMethods = useForm<any>({
    defaultValues: locGetDefaultValues(),
    resolver: yupResolver(
      validationSchema(locColumnsRedux, locVariableRedux, tab, type)
    ),
    criteriaMode: "all",
    mode: "onChange"
  })

  const { reset } = formHookMethods

  useEffect(() => {
    let isMounted = true
    if (isMounted && configDataHookCalled) {
      let isRedirect = false
      const totals = isSubtotal
        ? locVariableRedux.SubTotals
        : locVariableRedux.GrandTotals
      if (
        tab.toLocaleLowerCase() !==
          ConfigTabNames.MultiEventOutputs.toLocaleLowerCase() &&
        ((storeTabId !== -1 &&
          (storeTabId > variablesRedux.length || storeTabId <= 0)) ||
          (storeId !== -1 && (storeId > totals.length || storeId <= 0)))
      ) {
        isRedirect = true
      }
      if (isRedirect) {
        history.push(redirectUrl)
      } else {
        conditionCheckOnLoading({
          locVariableRedux,
          variableSetRedux,
          variableRedux,
          columnsRedux,
          storeTabId,
          locColumnsRedux,
          storeId,
          reset,
          locGetDefaultValues
        })
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [configDataHookCalled])

  const bConfig = getBreadcrumbConfig(t, tab, type, storeId)
  return (
    <>
      <CBreadCrumb bConfig={bConfig} customPath="Column Details" />
      <FormProvider {...formHookMethods}>
        <Grid container className="main-container">
          <FormHeading
            heading={`${getTranslations(t, Translates.Basic_Details)} *`}
          />
          <FormFieldConditionRender FieldDetails={basicFields} />
          <Values />
          {isPlacementField ? (
            <>
              <FormHeading
                heading={`${getTranslations(t, Translates.Placement)} *`}
                isDivider={true}
              />
              <FormFieldConditionRender FieldDetails={{ placementField }} />
            </>
          ) : null}
          <FormSubmitCancel />
        </Grid>
      </FormProvider>
    </>
  )
}

export default CreateEditTotal
