import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid } from "@mui/material"
import {
  getBreadcrumbPath,
  getDefaultValues
} from "./Utils/Helpers/CreateEditOutputBHelper"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { validationSchema } from "./Utils/Validations/CreateEditOutputBValidation"
import OutputBBreadCrumb from "src/configurationPortal/pages/Components/CBreadCrumb"
import FilesToIncludes from "./Components/CreateEditOutputBundle/FilesToIncludes"
import Destination from "../Components/Destination"
import FormSubmitCancel from "./Components/CreateEditOutputBundle/FormSubmitCancel"
import { PATH } from "src/constants/paths"
import { useTranslation } from "react-i18next"
import { ConfigTabNames } from "../helper"
import { getTranslations } from "src/utils/helper"
import FormFieldConditionRender from "../Components/FormFieldConditionRender"
import { Translates } from "src/i18n/i18n"
import { FilesDetailsFields } from "./Utils/Constants/CreateEditOutputBundleConstant"
import FormHeading from "src/components/FormHeading"

const CreateEditOutputBundle = () => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1

  const {
    outputBundleData: { outputBundlesRedux, isSetOutputBundlesRedux }
  } = ConfigDataHook()
  let search = window.location.search || ""

  const formHookMethods = useForm<any>({
    defaultValues: getDefaultValues(outputBundlesRedux, storeId),
    resolver: yupResolver(
      validationSchema(outputBundlesRedux, storeId, t, Translates)
    ),
    criteriaMode: "all",
    mode: "onChange"
  })
  const { reset } = formHookMethods

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      let isRedirect = false
      if (isSetOutputBundlesRedux) {
        if (!isRedirect && storeId > -1) {
          if (storeId > outputBundlesRedux.length || storeId <= 0) {
            isRedirect = true
          }
        }

        if (isRedirect) {
          history.push(PATH.OUTPUT_FILES + search)
        } else {
          window.onbeforeunload = () => true
          if (storeId > 0) {
            const defValues = getDefaultValues(outputBundlesRedux, storeId)
            reset(defValues)
          }
        }
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [isSetOutputBundlesRedux])

  const { componentName, bConfig } = getBreadcrumbPath(storeId, t)
  return (
    <>
      <OutputBBreadCrumb customPath={componentName} bConfig={bConfig} />
      <FormProvider {...formHookMethods}>
        <Grid container className="main-container">
          <FormHeading
            heading={`${getTranslations(t, Translates.Files_Details)}`}
          />
          <FormFieldConditionRender FieldDetails={FilesDetailsFields} />
          <FilesToIncludes />
          <Destination
            componentName={ConfigTabNames.OutputBundles}
            isFinalReport={true}
            isFTPMultiple={true}
          />
          <FormSubmitCancel />
        </Grid>
      </FormProvider>
    </>
  )
}

export default CreateEditOutputBundle
