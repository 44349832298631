import { Grid, Tooltip } from "@mui/material"
import moment from "moment"
import ProgressBar from "src/components/ProgressBar/ProgressBar"
import { renderAccuracySmiley } from "./LiveEventsHelper"
import ImageIcon from "@mui/icons-material/Image"
import { getTranslations } from "src/utils/helper"
import {
  DEFAULT_DATE_FORMAT,
  RED_COLOR_VALUE,
  SCHEDULE_STATUS_CONSTANTS,
  YELLOW_COLOR_VALUE
} from "src/utils/constants"
import DateFormat from "src/components/DateFormat"
const EventItem = ({ event, handleRedirect, t, Translates }) => {
  const handleClick = () => {
    if (event.status !== "Cancelled") {
      handleRedirect(event)
    }
  }
  const getEventDateTime = (eventDate: any, isLastUpdated: boolean) => {
    if (eventDate?.includes(DEFAULT_DATE_FORMAT) || eventDate === null) {
      return "-"
    } else if (isLastUpdated) {
      return moment.utc(eventDate)?.local()?.fromNow()
    } else {
      return moment(eventDate)?.format("hh:mm A")
    }
  }
  // To calculate APH value and show it in specific color
  const getColor = () => {
    const _finalAphValue = Math.round((event?.aph / event?.goal) * 100)
    if (_finalAphValue < RED_COLOR_VALUE) {
      return "red-text font-14"
    } else if (_finalAphValue < YELLOW_COLOR_VALUE) {
      return "yellow-text font-14"
    } else {
      return "success-text font-14"
    }
  }
  return (
    <Grid item className="events-list__col" onClick={handleClick}>
      <Grid container className="events-list__col--cont cursor-pointer">
        <Grid item xs={12}>
          <Grid container className="event-info flex-nowrap">
            <Grid item>
              {event?.logo ? (
                <img alt="Event Logo" src={event?.logo} />
              ) : (
                <ImageIcon className="event-info__logo"> </ImageIcon>
              )}
            </Grid>
            <Grid item className="event-info--head">
              <h6 className="event-info--head">
                {event?.customerName ? event?.customerName : ""}
              </h6>
              <Tooltip
                arrow
                title={event?.storeName + " " + event?.siteId}
                id="event-address"
              >
                <h6 className="ellipsis">
                  {event?.storeName
                    ? `${event?.storeName} `
                    : `${event?.city}   ${event?.state} `}
                  #{event?.siteId}
                </h6>
              </Tooltip>
              <Tooltip arrow title={event?.address} id="event-address">
                <strong className="ellipsis">{event?.address}</strong>
              </Tooltip>
              <span>
                {getTranslations(t, Translates.Last_Updated)}:{" "}
                {getEventDateTime(event?.eventLastUpdatedtime, true)}
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="event-desc">
          <Grid container className="mb-1">
            <Grid item xs={6}>
              <label className="f-700 font-15">
                {getTranslations(t, Translates.Count_Progress)}
              </label>
            </Grid>
            <Grid item xs={6} className="text-right">
              <ProgressBar value={event?.countProgress} type="count" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div className="event-desc--row">
                <label>
                  {getTranslations(t, Translates.Start_Date)} /{" "}
                  {getTranslations(t, Translates.Time)}
                </label>
                <span>
                  <DateFormat
                    value={event?.eventStartDate}
                    breakDandT={false}
                    isLocal={true}
                  />
                </span>
              </div>
              <div className="event-desc--row">
                <label>
                  {event?.status.toLowerCase() ===
                  SCHEDULE_STATUS_CONSTANTS.InProgress.toLowerCase()
                    ? getTranslations(t, Translates.Current_Team_Members)
                    : getTranslations(t, Translates.Team_Members)}
                </label>
                <span>{event?.teamCount}</span>
              </div>
              <div className="event-desc--row">
                <label>{getTranslations(t, Translates.Pieces_Counted)}</label>
                <span>{event?.piecesCounted}</span>
              </div>
              <div className="event-desc--row">
                <label>APH / {getTranslations(t, Translates.Goal)}</label>
                <span>
                  <small className={getColor()}>
                    {" "}
                    {Math.round(event?.aph)}
                  </small>
                  <small className="font-14" style={{ marginLeft: "3.5px" }}>
                    {event?.goal}
                  </small>
                </span>
              </div>
              <div className="event-desc--row">
                <label>
                  {getTranslations(t, Translates.Est_Completion_Time)}
                </label>
                <span>
                  {getEventDateTime(event?.estimatedCompletionTime, false)}
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="audit-progress">
          <Grid container className="mb-1">
            <Grid item xs={6}>
              <label className="f-700 font-15">
                {getTranslations(t, Translates.Audit_Progress)}
              </label>
            </Grid>
            <Grid item xs={6} className="text-right">
              <ProgressBar value={event?.auditProgress} type="audit" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <label className="f-600 font-14">
                {getTranslations(t, Translates.Accuracy)}
              </label>
            </Grid>
            <Grid item xs={6} className="text-right">
              <span className="accuracy-text">
                {renderAccuracySmiley(
                  event?.accuracy,
                  event?.aGoal,
                  event?.aSecondaryGoal
                )}
              </span>
            </Grid>
          </Grid>
          {event?.varianceConfiguredFlag && (
            <Grid item xs={12} className="variance-progress">
              <Grid container className="mb-1">
                <Grid item xs={6}>
                  <label className="f-700 font-15">
                    {getTranslations(t, Translates.Variance_Progress)}
                  </label>
                </Grid>
                <Grid item xs={6} className="text-right">
                  <ProgressBar
                    value={event?.varianceProgress}
                    type="variance"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EventItem
