import { useParams } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid } from "@mui/material"
import FormFieldConditionRender from "src/configurationPortal/pages/Components/FormFieldConditionRender"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
import { useTranslation } from "react-i18next"
import MultiEventOutputBreadCrumb from "src/configurationPortal/pages/Components/CBreadCrumb"
import ReadOnlySQLEditor from "src/configurationPortal/pages/Components/SQLEditor/ReadOnlySQLEditor"
import { ConfigTabNames } from "src/configurationPortal/pages/helper"
import { createEditReportFieldNames } from "src/configurationPortal/pages/Reports/Utils/Constants/CreateEditReportConstant"
import Columns from "src/configurationPortal/pages/Components/Columns"
import Totals from "src/configurationPortal/pages/Components/Totals"
import Destination from "src/configurationPortal/pages/Components/Destination"
import FormHeading from "src/components/FormHeading"
import {
  formDataPreparation,
  getBreadcrumbPath,
  getDefaultValues
} from "./Utils/Helpers/CreateEditMultiEventOutput"
import CustomerSection from "./Components/CreateEditMultiEventOutput/CustomerSection"
import ConfigurationIncludes from "./Components/CreateEditMultiEventOutput/ConfigurationIncludes"
import { GeneralFieldDetails as OutputGeneralFieldDetails } from "src/configurationPortal/pages/Outputs/Utils/Constants/CreateEditOutputConstant"
import FcsOptions from "./Components/CreateEditMultiEventOutput/FileCreationSchedule/FcsOptions"
import { validationSchema } from "./Utils/Validation"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import FormSubmitCancel from "./Components/CreateEditMultiEventOutput/FormSubmitCancel"
import { useEffect } from "react"
import { getColumnsFromAPI } from "src/configurationPortal/pages/FunctionHelper"
import { MultiEventOutputFormType } from "./Utils/Types"
import { MultiEventOutputsService } from "src/service/multiEventOutputs.service"
import {
  setMultiEventOutputRedux,
  setMultiEventOutputsRedux
} from "src/redux/slices/MultiEventOutputSlice"
import { useAppThunkDispatch } from "src/redux/store"
import Loading from "src/components/Loading/Loading"
import { GeneralFieldType } from "src/configurationPortal/pages/Outputs/Utils/Types"
import { createEditFieldNames } from "./Utils/Constants/CreateEditMultiEventOutput"
import { ConfigService } from "src/service/config.service"

const CreateEditMultiEventOutput = () => {
  const dispatch = useAppThunkDispatch()
  const multiEventOutputsService = new MultiEventOutputsService()
  const configService = new ConfigService()
  const { t } = useTranslation<any>()

  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //here don't want to subtract 1 because this one db id
  const {
    multiEventOutputData: {
      multiEventOutputRedux,
      multiEventOutputsRedux,
      isSetMultiEventOutputsRedux
    },
    columnReduxData: { columnsRedux },
    settings: { customers }
  } = ConfigDataHook()
  const locGetDefaultValues = (
    formObject: MultiEventOutputFormType = {} as MultiEventOutputFormType
  ) => {
    return getDefaultValues(
      Object.keys(formObject).length ? formObject : multiEventOutputRedux
    )
  }

  const formHookMethods = useForm<any>({
    defaultValues: locGetDefaultValues(),
    resolver: yupResolver(
      validationSchema(storeId, multiEventOutputsRedux, t, Translates)
    ),
    criteriaMode: "all",
    mode: "onChange"
  })
  const { reset, watch, setValue } = formHookMethods

  const loadings = watch(createEditFieldNames.Loadings.name)

  const getRecordById = async () => {
    await multiEventOutputsService
      .GetMultiEventFileDetailsByEventId(storeId)
      .then(async res => {
        const { formData } = await formDataPreparation(res.data[0])
        dispatch(setMultiEventOutputRedux(formData))
        reset(locGetDefaultValues(formData))
      })
      .catch(err => err)
      .finally(() => {
        setValue(createEditFieldNames.Loadings.name, {
          ...loadings,
          isGetAPICall: false
        })
      })
  }

  const getAllRecord = async () => {
    if (!isSetMultiEventOutputsRedux && !multiEventOutputsRedux.length) {
      try {
        const selectedCustomerList = customers?.filter((c: any) => c.selected)
        const payload = selectedCustomerList.map((c: any) => c.idCustomer)
        const temp = await configService.getMultiEventsOutputs(payload)
        dispatch(setMultiEventOutputsRedux(temp.data))
      } catch (_error) {}
    }
  }
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      window.onbeforeunload = () => true

      getAllRecord()

      if (
        storeId > -1 &&
        (!Object.keys(multiEventOutputRedux).length ||
          (Object.keys(multiEventOutputRedux).length &&
            multiEventOutputRedux.RecordId !== storeId))
      ) {
        getRecordById()
      } else {
        setValue(createEditFieldNames.Loadings.name, {
          ...loadings,
          isGetAPICall: false
        })
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (storeId > -1 && Object.keys(multiEventOutputRedux).length) {
        if (!columnsRedux.SQL) {
          getColumnsFromAPI(multiEventOutputRedux)
        }
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [multiEventOutputRedux])

  const { componentName, bConfig } = getBreadcrumbPath(storeId, t)
  const OutputGeneralFieldDetails1: GeneralFieldType = JSON.parse(
    JSON.stringify(OutputGeneralFieldDetails)
  )

  OutputGeneralFieldDetails1.fieldDelimiter.props.options =
    OutputGeneralFieldDetails1.fieldDelimiter.props.options.map(i => ({
      label: i.label,
      value: i.label
    }))
  OutputGeneralFieldDetails1.recordDelimiter.props.options =
    OutputGeneralFieldDetails1.recordDelimiter.props.options.map(i => ({
      label: i.label,
      value: i.label
    }))

  const loading = Object.keys(loadings)
    .filter(i => ["isGetAPICall"].includes(i))
    .some(i => loadings[i])
  return (
    <>
      <MultiEventOutputBreadCrumb
        customPath={componentName}
        bConfig={bConfig}
      />
      {loading ? (
        <Loading />
      ) : (
        <FormProvider {...formHookMethods}>
          <Grid container style={{ position: "absolute", top: "80px" }}>
            <Grid container className="inner-view">
              <Grid item xs={12} sm={12} className="main-container">
                <CustomerSection />
                <ConfigurationIncludes />
                <FormHeading
                  heading={`${getTranslations(t, Translates.GENERAL)} *`}
                  isDivider={true}
                />
                <FormFieldConditionRender
                  FieldDetails={OutputGeneralFieldDetails1}
                />
                <ReadOnlySQLEditor
                  componentName={ConfigTabNames.MultiEventOutputs}
                  fieldName={createEditReportFieldNames.RawSQL.name}
                />
                <Columns
                  componentName={ConfigTabNames.MultiEventOutputs}
                  fieldName={createEditReportFieldNames.Columns.name}
                />
                <Totals
                  componentName={ConfigTabNames.MultiEventOutputs}
                  totalType={ConfigTabNames.SubTotals}
                  fieldName={createEditReportFieldNames.SubTotals.name}
                />
                <Totals
                  componentName={ConfigTabNames.MultiEventOutputs}
                  totalType={ConfigTabNames.GrandTotals}
                  fieldName={createEditReportFieldNames.GrandTotals.name}
                />
                <FcsOptions />
                <Destination
                  componentName={ConfigTabNames.MultiEventOutputs}
                  isFTPMultiple={true}
                />
                <FormSubmitCancel />
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </>
  )
}

export default CreateEditMultiEventOutput
