import { useEffect, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"
import { Grid } from "@mui/material"
import BreadCrumb from "src/components/BreadCrumb/BreadCrumb"
import MUIText from "src/components/MUIFormFields/MUIText"
import { Translates } from "src/i18n/i18n"
import {
  BlindVFieldOptions,
  ReportFieldName,
  ReportFieldOptions
} from "./Utils/Constants"
import FormSubmitCancel from "./Components/ReportFields/FormSubmitCancel"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import {
  getReportFieldBreadcrumbConfig,
  getReportFieldDefaultValues
} from "./Utils/FunctionsHelper"
import { PATH } from "src/constants/paths"
import { setVarianceRedux } from "src/redux/slices/ConfigPortal/VarianceSlice"
import { useAppThunkDispatch } from "src/redux/store"
import { getTranslations } from "src/utils/helper"
import { MUIAutoCompleteField } from "src/utils/types/types"
import MUIAutoCompleteText from "src/components/MUIFormFields/MUIAutoCompleteText"
import { reportFieldValidationSchema } from "./Utils/Validations"
import FormHeading from "src/components/FormHeading"

const CreateEditReportField = () => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  let location = useLocation() as any
  const dispatch = useAppThunkDispatch()
  const {
    varianceData: { varianceRedux }
  } = ConfigDataHook()
  let stateVarianceRedux = location.state?.varianceRedux

  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  let search = window.location.search
  const [fieldOptions, setFieldOptions] = useState<
    MUIAutoCompleteField["props"]["options"]
  >([])
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      let isRedirect = false
      if (
        !stateVarianceRedux ||
        (stateVarianceRedux && !Object.keys(stateVarianceRedux).length)
      ) {
        isRedirect = true
      }
      if (!isRedirect && storeId > -1) {
        if (
          storeId >
            stateVarianceRedux[stateVarianceRedux.CurrentTabVarianceType]
              .ReportFields.length ||
          storeId <= 0
        ) {
          isRedirect = true
        }
      }

      if (isRedirect) {
        history.push(PATH.VARIANCES + search)
      } else {
        window.onbeforeunload = () => true
        if (
          stateVarianceRedux &&
          JSON.stringify(stateVarianceRedux) !== JSON.stringify(varianceRedux)
        ) {
          dispatch(setVarianceRedux(stateVarianceRedux))
        }
        let locFieldOptions = ReportFieldOptions
        if (
          stateVarianceRedux[stateVarianceRedux.CurrentTabVarianceType]
            .IsBlindVariance
        ) {
          locFieldOptions = BlindVFieldOptions
        }
        if (storeId <= -1) {
          locFieldOptions = locFieldOptions.filter(i => {
            return ![
              ...stateVarianceRedux[
                stateVarianceRedux.CurrentTabVarianceType
              ].ReportFields.map(obj => obj.Field)
            ]?.includes(i.value)
          })
        }
        setFieldOptions(locFieldOptions)
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [])

  const formHookMethods = useForm<any>({
    defaultValues: getReportFieldDefaultValues(stateVarianceRedux, storeId),
    resolver: yupResolver(
      reportFieldValidationSchema(fieldOptions, t, Translates)
    ),
    criteriaMode: "all",
    mode: "onChange"
  })

  const { setValue, watch } = formHookMethods

  const handleFieldOnChange = () => {
    setValue(ReportFieldName.Name.name, watch(ReportFieldName.Field.name))
  }
  return (
    <>
      <div className="main-toolbar">
        <div className="main-container">
          <Grid container>
            <Grid item sm={12}>
              <div className="custom-breadcrumb CP">
                <BreadCrumb
                  customPath={"New Report Field"}
                  breadCrumbInfo={getReportFieldBreadcrumbConfig(
                    varianceRedux,
                    t,
                    storeId
                  )}
                  renderedPage={""}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <FormProvider {...formHookMethods}>
        <Grid container className="main-container">
          <FormHeading
            heading={`${getTranslations(t, Translates.DETAILS)} *`}
          />
          <Grid item xs={12} sm={3} className="mb-8">
            <div className="text-box" data-testid="VCERFDiv">
              <MUIAutoCompleteText
                inputAttributes={{
                  name: ReportFieldName.Field.name,
                  label: `${getTranslations(t, ReportFieldName.Field.label)} *`,
                  options: fieldOptions,
                  onChange: handleFieldOnChange,
                  disabled: storeId > -1
                }}
              />
              <MUIText
                inputAttributes={{
                  name: ReportFieldName.Name.name,
                  label: `${getTranslations(t, ReportFieldName.Name.label)} *`
                }}
              />
            </div>
          </Grid>
          <FormSubmitCancel />
        </Grid>
      </FormProvider>
    </>
  )
}

export default CreateEditReportField
