import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import React, { useCallback, useEffect, useState } from "react"
import GlobalTable from "src/components/GlobalTable/GlobalTable"
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import { Loader } from "src/utils/reusables/reusable"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import shortid from "shortid"
import { encryptUrlParams, getTranslations } from "src/utils/helper"
import editIcon from "../../assets/images/Edit.png"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import DateFormat from "src/components/DateFormat"
import { KEY_CODES, FEATURES, PERMISSIONS } from "src/utils/constants"
import PermissionAccessGate from "src/components/UserAccessMngt/PermissionAccessGate"
import { getUploadFiles } from "src/redux/slices/uploadFileSlice"

const UploadFileDetails = ({  }) => {
  const { t } = useTranslation<any>()
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = React.useState([] as string[])
  const uploadFilesData = useSelector(
    (state: RootState) => state.uploadFiles?.uploadFilesData?.data
  )
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const dispatch = useAppThunkDispatch()


  useEffect(() => {
    if (customerId) {
      setLoading(true)
      dispatch(getUploadFiles(customerId)).then(() => setLoading(false))
    }
  }, [customerId])

  const history = useHistory()
  // istanbul ignore next
  const handleChange =
    (panel: string) =>
    (_event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (newExpanded) {
        setExpanded([...expanded, panel])
      } else {
        setExpanded(expanded.filter(f => f !== panel))
      }
    }

  const displaySpan = (props: any) => {
    return (
      <span className="d-block text-left">
        {props.value ? props.value : "-"}
      </span>
    )
  }

  const handleEdit = (row: any) => {
    let encryptedId: any = encryptUrlParams(row.idConfig)
    history.push(`/uploadFiles/editFile/${encryptedId}`, {
      Data: row
    })
  }
  const handleEditKeyUp = useCallback((e: any, record: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      handleEdit(record)
    }
  }, [])
  const handleLoadFile = (row: any) => {
    const rowQueryParam = encodeURIComponent(JSON.stringify(row))
    history.push(
      `${PATH.UPLOADFILE_BULK_UPLOAD}?Type=${row.selectedFileType}&row=${rowQueryParam}`
    )
  }

  const columnsData = React.useMemo(
    () => [
      {
        Header: getTranslations(t, Translates.Name),
        accessor: "name", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 250,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: getTranslations(t, Translates.DESCRIPTION),
        accessor: "description", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 250,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: getTranslations(t, Translates.Last_uploaded_Updated),
        accessor: "lastUpdatedDate", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 250,
        Cell: props => {
          let propsToDate: any = { ...props, isLocal: true }
          return <DateFormat {...propsToDate} />
        }
      },
      {
        Header: getTranslations(t, Translates.ACTIONS),
        accessor: "action", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 100,
        Cell: props => {
          return (
            <span className="action-btns">
              {props.row.original.isMaintenanceFile ? (
                <Tooltip
                  arrow
                  title={`${getTranslations(t, Translates.Upload)}`}
                >
                  <FileUploadOutlinedIcon
                    data-testid="Upload"
                    className="pointer font-30"
                    onClick={() => handleLoadFile(props.row.original)}
                  />
                </Tooltip>
              ) : null}
              {props.row.original.isMaintenanceFile ? (
                <PermissionAccessGate
                  feature={FEATURES.UPLOADFILES}
                  permissionKey={PERMISSIONS.UPDATE}
                >
                  <Tooltip
                    arrow
                    title={`${getTranslations(t, Translates.Edit)}`}
                  >
                    <img
                      data-testid="edit"
                      className="pointer mr-2"
                      src={editIcon}
                      onClick={() => handleEdit(props.row.original)}
                      onKeyUp={e => handleEditKeyUp(e, props.row.original)}
                    />
                  </Tooltip>
                </PermissionAccessGate>
              ) : null}
              {!props.row.original.isMaintenanceFile ? (
                <Tooltip
                  arrow
                  title={`${getTranslations(t, Translates.Upload)}`}
                >
                  <FileUploadOutlinedIcon
                    data-testid="uploadIcon"
                    className="pointer font-30"
                    onClick={() => handleClickButton(props.row.original)}
                  />
                </Tooltip>
              ) : null}
            </span>
          )
        }
      }
    ],
    [t]
  )
  const handleClickButton = record => {
    history.push(
      PATH.UPLOADFILES_DATA +
        `?idConfig=${encryptUrlParams(record.idConfig)}&name=${encryptUrlParams(
          record.name
        )}&description=${encryptUrlParams(record.description)}`
    )
  }

  const displayGrid = data => {
    return (
      <Grid container className="custom-table-responsive">
        <Grid xs={12} item>
          <GlobalTable
            columns={columnsData}
            data={data.uploadFileData}
            noDataText={`${getTranslations(t, Translates.No_Files_Found)}`}
          ></GlobalTable>
        </Grid>
      </Grid>
    )
  }

  function renderNoFilesFound() {
    if (loading) {
      return Loader()
    } else if (!loading && uploadFilesData?.length === 0) {
      return <div className="text-center mt-4 font-18">No Files Found</div>
    }
    return null
  }

  return (
    <div>
      {uploadFilesData?.length
        ? uploadFilesData.map((fileData: any, index) => {
            return fileData.configName ? (
              <div>
                {fileData.uploadFileData.length ? (
                  <Accordion
                    onChange={handleChange(`${fileData.configName}:${index}`)}
                    expanded={expanded.includes(
                      `${fileData.configName}:${index}`
                    )}
                    key={shortid.generate()}
                    className="custom-accordion dark"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h6>{fileData.configName}</h6>
                    </AccordionSummary>

                    <AccordionDetails>
                      {loading ? Loader() : displayGrid(fileData)}
                    </AccordionDetails>
                  </Accordion>
                ) : null}
              </div>
            ) : null
          })
        : renderNoFilesFound()}
    </div>
  )
}

export default UploadFileDetails

