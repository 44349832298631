import {
  Grid,
  Button,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Autocomplete
} from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import BreadCrumb from "src/components/BreadCrumb/BreadCrumb"
import { useHistory, useLocation } from "react-router-dom"
import { PATH } from "src/constants/paths"
import React, { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import {
  setNewRandomAuditRule,
  setNewTargetLocationAuditRule,
  setNewTargetRecordAuditRule,
  setNewFollowUpAuditRule
} from "src/redux/slices/configSlice"
import {
  Audit_Types,
  Audit_TypesObj,
  BreadCrumb_Fields
} from "src/constants/audits"
import ReadOnlyLayout from "../../pages/LookupRoutines/ReadOnlyLayout/ReadOnlyLayout"
import { CONFIG_MODULES, DEFAULT_RADIX } from "src/utils/constants"
import {
  LookupEditorToConfig,
  LookupConfigToEditor,
  makeLayoutAllVariableEmpty
} from "../LookupRoutines/helpers"
import { setLayout } from "../LookupRoutines/layout.service"
import {
  getBreadcrumbPath,
  setAuditFormData,
  generatePercentageValues,
  formatInteger,
  percentageValueConversion,
  onAutoCompleteFormChange,
  aRFieldNames
} from "./AuditHelper"
import { updateWarnOnTabChangeToStore } from "../helper"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"

interface INewAuditsRule {
  reason: string
  auditType: string
  tag: number
  thresholdValue: number
  targettedLocationPercentageThreshold: any
  targettedLocationRequiredUser: any
  targetedRecordPercentageThreshold: any
  targetedRecordRequiredUser: any
  randomDetailPercentageThreshold: any
  randomDetailRequiredUser: any
  randomDetail1stAt: any
  randomDetail1stNext: any
  randomDetailStopAt: any
  randomDetailsRepeat: any
  IsPerPerson: boolean
  followupPercentageThreshold: any
  followupRequiredUser: any
  followupStartTag: any
  followupAccuracy: any
  followupNumberOfFollowups: any
  followupOriginalReasons: any[]
  Instructions: any
  AccuracyCalculations: any
}
const NewAuditRule = () => {
  const { t } = useTranslation<any>()
  const inputRef = useRef<any>(null)
  const configData = useSelector((state: RootState) => state.config)
  let targetRecordAuditRuleData = useSelector(
    (state: RootState) => state.config.newTargetRecordAuditRule
  )
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )
  let targetLocationAuditRuleData = useSelector(
    (state: RootState) => state.config.newTargetLocationAuditRule
  )
  let randomAuditRuleData = useSelector(
    (state: RootState) => state.config.newRandomAuditRule
  )
  let followUpAuditRuleData = useSelector(
    (state: RootState) => state.config.newFollowUpAuditRule
  )
  const [errorMsg, setErrorMsg] = useState(false)
  const [followupOriginalReasons, setFollowupOriginalReasons] = React.useState(
    [] as any
  )
  // read the Reason from all the Audit Types to bind to Original Reasons Field in FollowUp Audits
  let origianlReasons = [
    ...targetRecordAuditRuleData.map(({ Reason }) => Reason),
    ...targetLocationAuditRuleData.map(({ Reason }) => Reason),
    ...randomAuditRuleData.map(({ Reason }) => Reason)
  ]
  const [overallpercentageErrorMsg, setOverallPercentageErrorMsg] =
    useState(false)
  const dispatch = useAppThunkDispatch()
  const history = useHistory()
  const [followupAuditTypeFlag, setFollowupAuditTypeFlag] = useState(false)
  const [randomAuditTypeFlag, setRandomAuditTypeFlag] = useState(false)
  const [targetedLocationAuditTypeFlag, setTargetedLocationAuditTypeFlag] =
    useState(false)
  const [targetedRecordAuditTypeFlag, setTargetedRecordAuditTypeFlag] =
    useState(false)
  let search = window.location.search || ""
  const location: any = useLocation()
  let locationState: any = { ...location.state }
  let ruledata = locationState?.ruledata
  let isEdit = locationState?.isEdit
  let editIndex = locationState?.index
  let data = locationState?.data
    ? JSON.parse(locationState?.data)
    : locationState?.data

  const { bConfig } = getBreadcrumbPath(
    getTranslations(t, BreadCrumb_Fields.AUDITS_COMPONENT),
    getTranslations(t, BreadCrumb_Fields.AUDITRULE_TITLE),
    PATH.CREATE_AUDIT.slice(1)
  )
  const emptyAuditRuleObj = {
    reason: "",
    auditType: "",
    tag: 0,
    thresholdValue: 0,
    targettedLocationPercentageThreshold: "",
    targettedLocationRequiredUser: "",
    targetedRecordPercentageThreshold: "",
    targetedRecordRequiredUser: "",
    randomDetailPercentageThreshold: "",
    randomDetailRequiredUser: "",
    randomDetail1stAt: "",
    randomDetail1stNext: "",
    randomDetailStopAt: "",
    randomDetailsRepeat: false,
    IsPerPerson: true,
    followupPercentageThreshold: "",
    followupRequiredUser: "",
    followupStartTag: "",
    followupAccuracy: "",
    followupNumberOfFollowups: "",
    Instructions: [],
    followupOriginalReasons: [],
    AccuracyCalculations: ""
  }

  const [form, setForm] = React.useState<INewAuditsRule>(
    emptyAuditRuleObj as INewAuditsRule
  )

  const getBreadcrumbToolBar = () => {
    let currentPath: string = getTranslations(t, Translates.Audits)
    let variables: any = {
      Audits: getTranslations(t, Translates.New_Audit_Rule)
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }

  useEffect(() => {
    if (isEdit || data?.Instructions || editIndex) {
      setRuleData()
      makeLayoutAllVariableEmpty()
      setLayout(
        data?.Instructions
          ? data?.Instructions
          : LookupConfigToEditor(ruledata?.Instructions, true)
      )
    } else {
      setForm(emptyAuditRuleObj)
      makeLayoutAllVariableEmpty()
      setLayout([])
    }
  }, [])

  const setRuleData = () => {
    let isPerPerson = true
    if (
      (data && data?.IsPerPerson === false) ||
      (ruledata && ruledata?.IsPerPerson === false)
    ) {
      isPerPerson = false
    }
    const _ruleItem = {
      reason: data?.reason ? data.reason : ruledata?.Reason,
      targettedLocationRequiredUser: data?.targettedLocationRequiredUser
        ? data.targettedLocationRequiredUser
        : ruledata?.AuditUserType,
      targettedLocationPercentageThreshold:
        data?.targettedLocationPercentageThreshold ||
        data?.targettedLocationPercentageThreshold === ""
          ? data.targettedLocationPercentageThreshold
          : percentageValueConversion(ruledata?.PercentThreshold),
      auditType: data?.auditType ? data.auditType : ruledata?.AuditType,
      tag: 0,
      thresholdValue: 0,
      targetedRecordPercentageThreshold:
        data?.targetedRecordPercentageThreshold ||
        data?.targettedLocationPercentageThreshold === ""
          ? data.targetedRecordPercentageThreshold
          : percentageValueConversion(ruledata?.PercentThreshold),
      targetedRecordRequiredUser: data?.targetedRecordRequiredUser
        ? data.targetedRecordRequiredUser
        : ruledata?.AuditUserType,
      randomDetailPercentageThreshold:
        data?.randomDetailPercentageThreshold ||
        data?.randomDetailPercentageThreshold === ""
          ? data.randomDetailPercentageThreshold
          : percentageValueConversion(ruledata?.PercentThreshold),
      randomDetailRequiredUser: data?.randomDetailRequiredUser
        ? data.randomDetailRequiredUser
        : ruledata?.AuditUserType,
      randomDetail1stAt: data?.randomDetail1stAt
        ? data.randomDetail1stAt
        : ruledata?.OneOfFirst,
      randomDetail1stNext: data?.randomDetail1stNext
        ? data.randomDetail1stNext
        : ruledata?.OneOfNext,
      randomDetailStopAt: data?.randomDetailStopAt
        ? data.randomDetailStopAt || data?.randomDetailStopAt === ""
        : percentageValueConversion(ruledata?.StopAt),
      randomDetailsRepeat: data?.randomDetailsRepeat
        ? data?.randomDetailsRepeat
        : ruledata?.Repeat,
      IsPerPerson: isPerPerson,
      followupPercentageThreshold:
        data?.followupPercentageThreshold ||
        data?.followupPercentageThreshold === ""
          ? data.followupPercentageThreshold
          : percentageValueConversion(ruledata?.PercentThreshold),
      followupRequiredUser: data?.followupRequiredUser
        ? data.followupRequiredUser
        : ruledata?.AuditUserType,

      followupStartTag: data?.followupStartTag
        ? data.followupStartTag
        : ruledata?.NumberOfFollowups,
      followupAccuracy: data?.followupAccuracy
        ? data.followupAccuracy
        : percentageValueConversion(ruledata?.AccuracyThreshold),
      followupNumberOfFollowups: data?.followupNumberOfFollowups
        ? data.followupNumberOfFollowups
        : percentageValueConversion(ruledata?.StopAt),
      Instructions: data?.Instructions
        ? data?.Instructions
        : LookupConfigToEditor(ruledata?.Condition, true),
      AccuracyCalculations: data?.AccuracyCalculations
        ? data.AccuracyCalculations
        : ruledata?.AccuracyCalculations,
      followupOriginalReasons: data?.followupOriginalReasons
        ? data.followupOriginalReasons
        : ruledata?.OriginalReasons
    }

    setForm(_ruleItem)
    setRuleTypeControls(data?.auditType ? data.auditType : ruledata?.AuditType)
    setFollowupOriginalReasons(
      data?.followupOriginalReasons
        ? data.followupOriginalReasons
        : ruledata?.OriginalReasons
    )
  }

  const prepeareObjForSave = (newRuleObj, ruleObj, ruleType) => {
    if (!overallpercentageErrorMsg && !errorMsg) {
      if (ruleObj.length === 0) {
        ruleObj = [newRuleObj]
      } else {
        ruleObj = [...ruleObj, newRuleObj]
      }
      if (ruleType === Audit_TypesObj.TargetedLocation) {
        dispatch(setNewTargetLocationAuditRule(ruleObj))
      } else if (form.auditType === Audit_TypesObj.TargetedRecord) {
        dispatch(setNewTargetRecordAuditRule(ruleObj))
      } else if (form.auditType === Audit_TypesObj.Random) {
        dispatch(setNewRandomAuditRule(ruleObj))
      } else if (form.auditType === Audit_TypesObj.FollowUp) {
        dispatch(setNewFollowUpAuditRule(ruleObj))
      }

      setTimeout(() => {
        history.push(PATH.AUDITS + search)
      })
    } else {
      // show the error message
      inputRef?.current?.focus()
      window.scrollTo(0, 0)
    }
  }

  const checkDuplicate = (newRuleName, newAuditType) => {
    let auditTypeRuleObj
    switch (newAuditType) {
      case Audit_TypesObj.TargetedLocation: {
        auditTypeRuleObj = targetLocationAuditRuleData
        break
      }
      case Audit_TypesObj.TargetedRecord: {
        auditTypeRuleObj = targetRecordAuditRuleData
        break
      }
      case Audit_TypesObj.FollowUp: {
        auditTypeRuleObj = followUpAuditRuleData
        break
      }
      case Audit_TypesObj.Random: {
        auditTypeRuleObj = randomAuditRuleData
        break
      }
    }

    let duplicateObj = auditTypeRuleObj.filter(function (itm) {
      return newRuleName === itm.Reason && newAuditType === itm.AuditType
    })
    if (Object.keys(duplicateObj).length) {
      setErrorMsg(true)
      return true
    } else {
      return false
    }
  }

  const deleteDuplicateRultItem = (ruleName, ruleAuditType) => {
    if (ruleAuditType === Audit_TypesObj.FollowUp) {
      followUpAuditRuleData = followUpAuditRuleData.filter(
        f => f.Reason !== ruleName
      )
      dispatch(setNewFollowUpAuditRule([...followUpAuditRuleData]))
    } else if (ruleAuditType === Audit_TypesObj.Random) {
      randomAuditRuleData = randomAuditRuleData.filter(
        f => f.Reason !== ruleName
      )
      dispatch(setNewRandomAuditRule([...randomAuditRuleData]))
    } else if (ruleAuditType === Audit_TypesObj.TargetedLocation) {
      targetLocationAuditRuleData = targetLocationAuditRuleData.filter(
        f => f.Reason !== ruleName
      )
      dispatch(setNewTargetLocationAuditRule([...targetLocationAuditRuleData]))
    } else if (ruleAuditType === Audit_TypesObj.TargetedRecord) {
      targetRecordAuditRuleData = targetRecordAuditRuleData.filter(
        f => f.Reason !== ruleName
      )
      dispatch(setNewTargetRecordAuditRule([...targetRecordAuditRuleData]))
    }
  }
  const checkIfRuleExisting = (ruleObj, existingRuleName) => {
    let idx = ruleObj.findIndex(itm => itm.Reason === existingRuleName)
    return idx
  }

  const onSaveClick = () => {
    updateWarnOnTabChangeToStore(false)
    const Instructions = data?.Instructions?.length
      ? LookupEditorToConfig(data?.Instructions)
      : LookupEditorToConfig(form.Instructions)
    const auditType = data?.auditType ? data.auditType : ruledata?.AuditType
    const ruleReason = data?.reason ? data.reason : ruledata?.Reason
    const accuracyCalculations = data?.AccuracyCalculations
      ? data.AccuracyCalculations
      : ruledata?.AccuracyCalculations
    let duplicateStatus = false
    let checkDuplicateItem = false
    if (editIndex || isEdit) {
      // check if there is change in the audit type. If yes, delete the old audit type object
      if (auditType != form.auditType || ruleReason != form.reason) {
        deleteDuplicateRultItem(ruleReason, auditType)
        // set the flag to check if the new rule name  is exists in new rule
        checkDuplicateItem = true
      }

      // replace the object
      if (form.auditType === Audit_TypesObj.TargetedLocation) {
        if (checkDuplicateItem)
          duplicateStatus = checkDuplicate(form.reason, form.auditType)
        if (!duplicateStatus) {
          let newArr = [...targetLocationAuditRuleData]
          const _localRuleItem = {
            Reason: form.reason,
            AuditUserType: form.targettedLocationRequiredUser,
            PercentThreshold: formatInteger(
              form?.targettedLocationPercentageThreshold
            ),
            AuditType: form.auditType,
            Condition: Instructions.length ? Instructions[0].Condition : [],
            AccuracyCalculations: accuracyCalculations
          }
          // find if the existing rule is present in the array and return the index
          let foundIndex = checkIfRuleExisting(newArr, form.reason)
          if (foundIndex != -1)
            newArr[foundIndex] = { ...newArr[foundIndex], ..._localRuleItem }
          else {
            // check the length of the object and replace
            if (newArr.length === 0) {
              newArr[0] = _localRuleItem
            } else {
              newArr[targetLocationAuditRuleData?.length] = {
                ...newArr[targetLocationAuditRuleData?.length],
                ..._localRuleItem
              }
            }
          }
          dispatch(setNewTargetLocationAuditRule([...newArr]))
        }
      } else if (form.auditType === Audit_TypesObj.TargetedRecord) {
        if (checkDuplicateItem)
          duplicateStatus = checkDuplicate(form.reason, form.auditType)

        if (!duplicateStatus) {
          let newArr = [...targetRecordAuditRuleData]
          const _localRuleItem = {
            Reason: form.reason,
            AuditUserType: form.targetedRecordRequiredUser,
            PercentThreshold: formatInteger(
              form?.targetedRecordPercentageThreshold
            ),
            AuditType: form.auditType,
            Condition: Instructions.length ? Instructions[0].Condition : [],
            AccuracyCalculations: accuracyCalculations
          }
          // find if the existing rule is present in the array and return the index
          let foundIndex = checkIfRuleExisting(newArr, form.reason)
          if (foundIndex !== -1)
            newArr[foundIndex] = { ...newArr[foundIndex], ..._localRuleItem }
          else {
            // check the length of the object and replace
            if (newArr.length === 0) {
              newArr[0] = _localRuleItem
            } else {
              newArr[targetRecordAuditRuleData?.length] = {
                ...newArr[targetRecordAuditRuleData?.length],
                ..._localRuleItem
              }
            }
          }
          dispatch(setNewTargetRecordAuditRule([...newArr]))
        }
      } else if (form.auditType === Audit_TypesObj.Random) {
        if (checkDuplicateItem)
          duplicateStatus = checkDuplicate(form.reason, form.auditType)
        if (!duplicateStatus) {
          let newArr = [...randomAuditRuleData]
          const _localRuleItem = {
            Reason: form.reason,
            AuditUserType: form.randomDetailRequiredUser,
            PercentThreshold: formatInteger(
              form?.randomDetailPercentageThreshold
            ),
            OneOfFirst: form?.randomDetail1stAt
              ? parseInt(form?.randomDetail1stAt, DEFAULT_RADIX)
              : 0,
            OneOfNext: form?.randomDetail1stNext
              ? parseInt(form?.randomDetail1stNext, DEFAULT_RADIX)
              : 0,
            Repeat: form?.randomDetailsRepeat,
            StopAt: form?.randomDetailsRepeat
              ? formatInteger(form?.randomDetailStopAt)
              : null,
            AuditType: form.auditType,
            Condition: Instructions.length ? Instructions[0].Condition : [],
            AccuracyCalculations: accuracyCalculations,
            IsPerPerson: form.IsPerPerson
          }
          // find if the existing rule is present in the array and return the index
          let foundIndex = checkIfRuleExisting(newArr, form.reason)
          if (foundIndex !== -1)
            newArr[foundIndex] = { ...newArr[foundIndex], ..._localRuleItem }
          else {
            // check the length of the object and replace
            if (newArr.length === 0) {
              newArr[0] = _localRuleItem
            } else {
              newArr[randomAuditRuleData?.length] = {
                ...newArr[randomAuditRuleData?.length],
                ..._localRuleItem
              }
            }
          }
          dispatch(setNewRandomAuditRule([...newArr]))
        }
      } else if (form.auditType === Audit_TypesObj.FollowUp) {
        if (checkDuplicateItem)
          duplicateStatus = checkDuplicate(form.reason, form.auditType)

        if (!duplicateStatus) {
          let newArr = [...followUpAuditRuleData]
          const _localRuleItem = {
            Reason: form.reason,
            AuditUserType: form.followupRequiredUser,
            PercentThreshold: formatInteger(form?.followupPercentageThreshold),
            NumberOfFollowups: form?.followupStartTag
              ? parseInt(form?.followupStartTag, DEFAULT_RADIX)
              : 0,
            AccuracyThreshold: formatInteger(form?.followupAccuracy),
            StopAt: formatInteger(form?.followupNumberOfFollowups),
            AuditType: form.auditType,
            OriginalReasons: followupOriginalReasons,
            Condition: Instructions.length ? Instructions[0].Condition : [],
            AccuracyCalculations: accuracyCalculations
          }

          // find if the existing rule is present in the array and return the index
          let foundIndex = checkIfRuleExisting(newArr, form.reason)
          if (foundIndex !== -1)
            newArr[foundIndex] = { ...newArr[foundIndex], ..._localRuleItem }
          else {
            // check the length of the object and replace
            if (newArr.length === 0) {
              newArr[0] = _localRuleItem
            } else {
              newArr[followUpAuditRuleData?.length] = {
                ...newArr[followUpAuditRuleData?.length],
                ..._localRuleItem
              }
            }
          }
          dispatch(setNewFollowUpAuditRule([...newArr]))
        }
      }
      // redirect to audits page when there is no  error
      if (!errorMsg && !overallpercentageErrorMsg && !duplicateStatus) {
        setTimeout(() => {
          history.push(PATH.AUDITS + search)
        })
      } else {
        inputRef?.current?.focus()
        window.scrollTo(0, 0)
      }
    } else {
      if (
        form.auditType === Audit_TypesObj.TargetedLocation &&
        !checkDuplicate(form.reason, form.auditType)
      ) {
        const _auditTargettedLocationRuleItem = {
          Reason: form.reason,
          AuditUserType: form.targettedLocationRequiredUser,
          PercentThreshold: formatInteger(
            form?.targettedLocationPercentageThreshold
          ),
          AuditType: form.auditType,
          Condition: Instructions.length ? Instructions[0].Condition : [],
          AccuracyCalculations: false
        }
        // targeted loation rule items from config
        let _targetedLocationRuleItems = configData.newTargetLocationAuditRule
        prepeareObjForSave(
          _auditTargettedLocationRuleItem,
          _targetedLocationRuleItems,
          form.auditType
        )
      } else if (
        form.auditType === Audit_TypesObj.TargetedRecord &&
        !checkDuplicate(form.reason, form.auditType)
      ) {
        const _auditTargetedRecordRuleItem = {
          Reason: form.reason,
          AuditUserType: form.targetedRecordRequiredUser,
          PercentThreshold: formatInteger(
            form?.targetedRecordPercentageThreshold
          ),
          AuditType: form.auditType,
          Condition: Instructions.length ? Instructions[0].Condition : [],
          AccuracyCalculations: false
        }
        // targeted record rule items from config
        let _targetedRecordRuleItems = configData.newTargetRecordAuditRule
        prepeareObjForSave(
          _auditTargetedRecordRuleItem,
          _targetedRecordRuleItems,
          form.auditType
        )
      } else if (
        form.auditType === Audit_TypesObj.Random &&
        !checkDuplicate(form.reason, form.auditType)
      ) {
        const _randomDefaultRuleItem = {
          Reason: form.reason,
          AuditUserType: form.randomDetailRequiredUser,
          PercentThreshold: formatInteger(
            form?.randomDetailPercentageThreshold
          ),
          OneOfFirst: form?.randomDetail1stAt
            ? parseInt(form?.randomDetail1stAt, DEFAULT_RADIX)
            : 0,
          OneOfNext: form?.randomDetail1stNext
            ? parseInt(form?.randomDetail1stNext, DEFAULT_RADIX)
            : 0,
          Repeat: form?.randomDetailsRepeat,
          StopAt: form?.randomDetailsRepeat
            ? formatInteger(form?.randomDetailStopAt)
            : null,
          AuditType: form.auditType,
          Condition: Instructions.length ? Instructions[0].Condition : [],
          AccuracyCalculations: false,
          IsPerPerson: form.IsPerPerson
        }
        // random audit rule items from config
        let _randomAuditRuleItems = configData.newRandomAuditRule
        prepeareObjForSave(
          _randomDefaultRuleItem,
          _randomAuditRuleItems,
          form.auditType
        )
      } else if (
        form.auditType === Audit_TypesObj.FollowUp &&
        !checkDuplicate(form.reason, form.auditType)
      ) {
        const _auditFollowupRuleItem = {
          Reason: form.reason,
          AuditUserType: form.followupRequiredUser,
          PercentThreshold: formatInteger(form?.followupPercentageThreshold),
          NumberOfFollowups: form?.followupStartTag
            ? parseInt(form?.followupStartTag, DEFAULT_RADIX)
            : 0,
          AccuracyThreshold: formatInteger(form?.followupAccuracy),
          StopAt: formatInteger(form?.followupNumberOfFollowups),
          AuditType: form.auditType,
          OriginalReasons: followupOriginalReasons,
          Condition: Instructions.length ? Instructions[0].Condition : [],
          AccuracyCalculations: false
        }
        // followup audit rule items from config
        let _followUpAuditRuleItems = configData.newFollowUpAuditRule
        prepeareObjForSave(
          _auditFollowupRuleItem,
          _followUpAuditRuleItems,
          form.auditType
        )
      }
      if (!errorMsg) {
        inputRef?.current?.focus()
        window.scrollTo(0, 0)
      }
    }
  }
  const onCancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.AUDITS + search)
    })
  }

  const setRuleTypeControls = val => {
    if (val === Audit_TypesObj.Random) {
      setRandomAuditTypeFlag(true)
      setFollowupAuditTypeFlag(false)
      setTargetedLocationAuditTypeFlag(false)
      setTargetedRecordAuditTypeFlag(false)
    } else if (val === Audit_TypesObj.FollowUp) {
      setRandomAuditTypeFlag(false)
      setFollowupAuditTypeFlag(true)
      setTargetedLocationAuditTypeFlag(false)
      setTargetedRecordAuditTypeFlag(false)
    } else if (val === Audit_TypesObj.TargetedLocation) {
      setRandomAuditTypeFlag(false)
      setFollowupAuditTypeFlag(false)
      setTargetedLocationAuditTypeFlag(true)
      setTargetedRecordAuditTypeFlag(false)
    } else if (val === Audit_TypesObj.TargetedRecord) {
      setRandomAuditTypeFlag(false)
      setFollowupAuditTypeFlag(false)
      setTargetedLocationAuditTypeFlag(false)
      setTargetedRecordAuditTypeFlag(true)
    }
  }

  const getErrorMessage = () => {
    if (errorMsg) {
      return `${getTranslations(t, Translates.Rule_Reason)} ${
        form.reason
      } ${getTranslations(t, Translates.is_already_in_use_in)} ${
        form.auditType
      }`
    }
  }

  const onFormChange = e => {
    const val = e.target.value || ""
    updateWarnOnTabChangeToStore(true)
    setAuditFormData(
      e,
      setForm,
      form,
      setErrorMsg,
      "",
      setOverallPercentageErrorMsg,
      ""
    )
    // show hide different audit rule  sections
    if (e.target?.name === "auditType") {
      setRuleTypeControls(val)
      setErrorMsg(false)
    }
  }

  const handleFollowUpOriginalReasonsChange = (event, values) => {
    updateWarnOnTabChangeToStore(true)
    const value = values
    if (value[value.length - 1] === "All") {
      setFollowupOriginalReasons(
        followupOriginalReasons?.length === origianlReasons?.length
          ? []
          : origianlReasons
      )
      return
    }
    setFollowupOriginalReasons(value)
    setForm({
      ...form,
      ["followupOriginalReasons"]: value
    })
  }
  const onPercentageChange = (event, values) => {
    updateWarnOnTabChangeToStore(true)
    let targetID = event?.target?.id?.split("-")[0]
    onAutoCompleteFormChange(setForm, form, targetID, values)
  }
  return (
    <>
      <div className="main-toolbar">
        <div className="main-container">
          <Grid container>
            <Grid item xs={12} sm={6} className="d-flex align-items-center">
              <div className="custom-breadcrumb CP">
                <BreadCrumb
                  customPath={"New Audit Rule"}
                  breadCrumbInfo={getBreadcrumbToolBar()}
                  renderedPage={""}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="main-container">
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <label className="form-control__label mb-3">
                      {getTranslations(t, Translates.DETAILS)}
                    </label>
                    <TextField
                      data-testid="reason"
                      fullWidth
                      label={`${getTranslations(t, Translates.Reason)} *`}
                      variant="filled"
                      autoSave="false"
                      autoComplete="off"
                      name={aRFieldNames.reason}
                      value={form?.reason || ""}
                      inputProps={{ "data-testid": "name" }}
                      className="custom-form-control"
                      onChange={e => onFormChange(e)}
                      error={errorMsg ? true : false}
                      helperText={getErrorMessage()}
                      ref={inputRef}
                    />
                  </Grid>
                </Grid>
                <Divider className="dashed mt-3 mb-4" />
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <label className="form-control__label mb-3">
                      {getTranslations(t, Translates.Audit_type)}
                    </label>
                    <Grid container className="mb-1">
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="custom-form-control select"
                          variant="filled"
                        >
                          <InputLabel
                            variant="filled"
                            id="demo-multiple-checkbox-label"
                          >
                            {getTranslations(t, Translates.Select_Type)} *
                          </InputLabel>
                          <Select
                            data-testid="auditType"
                            id="demo-multiple-checkbox"
                            name={aRFieldNames.auditType}
                            onChange={e => onFormChange(e)}
                            value={form?.auditType}
                          >
                            {Audit_Types?.map((name: any) => (
                              <MenuItem
                                key={name}
                                value={name}
                                className="dropdown-list"
                              >
                                <small>{getTranslations(t, name)}</small>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {form.auditType === Audit_TypesObj.Random ? (
                      <Grid container className="mb-1">
                        <Grid item xs={12} sm={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                data-testid="chkIsPerPerson"
                                className="custom-check light"
                                checkedIcon={<CheckIcon />}
                                onChange={e => onFormChange(e)}
                                name={aRFieldNames.IsPerPerson}
                                checked={form?.IsPerPerson}
                              />
                            }
                            label={getTranslations(t, Translates.Per_Person)}
                          />
                        </Grid>{" "}
                      </Grid>
                    ) : null}

                    <Grid container>
                      {followupAuditTypeFlag && (
                        <Grid item xs={12} className="followup-section">
                          <Divider className="dashed mt-3 mb-4" />
                          <label className="form-control__label mb-3">
                            {getTranslations(
                              t,
                              Translates.Follow_Up_System_Audit
                            )}
                          </label>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className="custom-form-control select no-border multi-select"
                                variant="filled"
                              >
                                <Autocomplete
                                  data-testid="followupPercentageThreshold"
                                  id="followupPercentageThreshold"
                                  options={generatePercentageValues()}
                                  value={form?.followupPercentageThreshold}
                                  onChange={onPercentageChange}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      value={
                                        form?.followupPercentageThreshold || ""
                                      }
                                      name={
                                        aRFieldNames.followupPercentageThreshold
                                      }
                                      onChange={e => onFormChange(e)}
                                      label={getTranslations(
                                        t,
                                        Translates.Percentage_Threshold
                                      )}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container className="mt-2">
                            <Grid item xs={12}>
                              <strong className="f-600 mb-1">
                                {getTranslations(t, Translates.User_Type)}
                              </strong>
                              <div>
                                <FormControl>
                                  <RadioGroup
                                    data-testid="radioGroup"
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name={aRFieldNames.followupRequiredUser}
                                    onChange={e => onFormChange(e)}
                                    defaultValue={form?.followupRequiredUser}
                                  >
                                    <FormControlLabel
                                      value="Customer"
                                      control={<Radio />}
                                      label={getTranslations(
                                        t,
                                        Translates.Customer
                                      )}
                                    />
                                    <FormControlLabel
                                      value="WIS"
                                      control={<Radio />}
                                      label={getTranslations(t, Translates.WIS)}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </Grid>
                            <Grid xs={12} sm={6} className="mt-1">
                              <span className="d-block f-600 my-1">
                                {getTranslations(
                                  t,
                                  Translates.Audit_Types_that_trigger_Follow_Up
                                )}
                              </span>

                              <div className="text-box">
                                <FormControl
                                  className="custom-form-control select no-border multi-select"
                                  variant="filled"
                                >
                                  <Autocomplete
                                    data-testid="followupOriginalReasons"
                                    id="combo-box-demo"
                                    multiple
                                    disableCloseOnSelect
                                    options={origianlReasons}
                                    limitTags={2}
                                    value={form?.followupOriginalReasons || []}
                                    onChange={
                                      handleFollowUpOriginalReasonsChange
                                    }
                                    getOptionLabel={(option: any) => option}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        value={form?.followupOriginalReasons}
                                        name={
                                          aRFieldNames.followupOriginalReasons
                                        }
                                        label={getTranslations(
                                          t,
                                          Translates.Audit_Reasons
                                        )}
                                      />
                                    )}
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) => (
                                      <li {...props}>
                                        <Checkbox
                                          checkedIcon={<CheckIcon />}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option}
                                      </li>
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </Grid>
                            <Grid xs={12} className="mt-2">
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  className="d-flex align-items-center"
                                >
                                  <label style={{ minWidth: "70px" }}>
                                    {getTranslations(t, Translates.Select)}
                                  </label>
                                  <TextField
                                    data-testid="tags"
                                    style={{
                                      width: "100px",
                                      minWidth: "100px"
                                    }}
                                    label={getTranslations(t, Translates.Tags)}
                                    variant="filled"
                                    size="small"
                                    autoSave="false"
                                    autoComplete="off"
                                    name={aRFieldNames.followupStartTag}
                                    value={form?.followupStartTag || ""}
                                    onChange={e => onFormChange(e)}
                                    className="custom-form-control mr-1"
                                  />
                                  <label style={{ minWidth: "130px" }}>
                                    {getTranslations(
                                      t,
                                      Translates.tags_if_less_than
                                    )}
                                  </label>
                                  <FormControl
                                    className="custom-form-control select no-border multi-select"
                                    variant="filled"
                                    style={{ width: "110px" }}
                                  >
                                    <Autocomplete
                                      id="followupAccuracy"
                                      className="auto-complete-full"
                                      style={{ width: "100px" }}
                                      size="small"
                                      disablePortal={true}
                                      options={generatePercentageValues()}
                                      value={form?.followupAccuracy}
                                      onChange={onPercentageChange}
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          value={form?.followupAccuracy || ""}
                                          name={aRFieldNames.followupAccuracy}
                                          onChange={e => onFormChange(e)}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                  {getTranslations(t, Translates.accurate)}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  className="d-flex align-items-center"
                                >
                                  <label style={{ minWidth: "70px" }}>
                                    {getTranslations(t, Translates.stop_at)}
                                  </label>
                                  <FormControl
                                    className="custom-form-control select no-border multi-select"
                                    variant="filled"
                                    style={{ width: "110px" }}
                                  >
                                    <Autocomplete
                                      id="followupNumberOfFollowups"
                                      className="auto-complete-full"
                                      style={{ width: "100px" }}
                                      size="small"
                                      disablePortal={true}
                                      options={generatePercentageValues()}
                                      value={form?.followupNumberOfFollowups}
                                      onChange={onPercentageChange}
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          value={
                                            form?.followupNumberOfFollowups ||
                                            ""
                                          }
                                          name={
                                            aRFieldNames.followupNumberOfFollowups
                                          }
                                          onChange={e => onFormChange(e)}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {randomAuditTypeFlag && (
                        <Grid item xs={12} className="random-section">
                          <Divider className="dashed mt-3 mb-4" />
                          <label className="form-control__label mb-3">
                            {getTranslations(t, Translates.Random_System_Audit)}
                          </label>
                          <Grid container>
                            <Grid item xs={6}>
                              <FormControl
                                className="custom-form-control select"
                                variant="filled"
                              >
                                <FormControl
                                  className="custom-form-control select no-border multi-select"
                                  variant="filled"
                                >
                                  <Autocomplete
                                    id="randomDetailPercentageThreshold"
                                    options={generatePercentageValues()}
                                    value={
                                      form?.randomDetailPercentageThreshold
                                    }
                                    onChange={onPercentageChange}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        value={
                                          form?.randomDetailPercentageThreshold ||
                                          ""
                                        }
                                        name={
                                          aRFieldNames.randomDetailPercentageThreshold
                                        }
                                        onChange={e => onFormChange(e)}
                                        label="Percentage Threshold"
                                      />
                                    )}
                                  />
                                </FormControl>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12}>
                              <strong className="f-600 mb-1">
                                {getTranslations(t, Translates.User_Type)}
                              </strong>
                              <div>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name={aRFieldNames.randomDetailRequiredUser}
                                    onChange={e => onFormChange(e)}
                                    defaultValue={
                                      form?.randomDetailRequiredUser
                                    }
                                  >
                                    <FormControlLabel
                                      value="Customer"
                                      control={<Radio />}
                                      label={getTranslations(
                                        t,
                                        Translates.Customer
                                      )}
                                    />
                                    <FormControlLabel
                                      value="WIS"
                                      control={<Radio />}
                                      label={getTranslations(t, Translates.WIS)}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </Grid>

                            <Grid xs={12} className="mt-2">
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  className="d-flex align-items-center"
                                >
                                  <label style={{ minWidth: "80px" }}>
                                    {getTranslations(t, Translates.Intervals)}
                                  </label>
                                  <label style={{ minWidth: "75px" }}>
                                    {getTranslations(t, Translates._1_of_first)}
                                  </label>
                                  <TextField
                                    data-testid="randomDetail1stAt"
                                    style={{ width: "100px" }}
                                    label={getTranslations(t, Translates.Tags)}
                                    variant="filled"
                                    size="small"
                                    autoSave="false"
                                    autoComplete="off"
                                    className="custom-form-control mr-1"
                                    name={aRFieldNames.randomDetail1stAt}
                                    value={form?.randomDetail1stAt || ""}
                                    onChange={e => onFormChange(e)}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  className="d-flex align-items-center pl-10"
                                >
                                  <label style={{ minWidth: "75px" }}>
                                    1_of_next
                                  </label>

                                  <TextField
                                    data-testid="randomDetailNext"
                                    style={{
                                      width: "100px",
                                      minWidth: "100px"
                                    }}
                                    size="small"
                                    label=""
                                    variant="filled"
                                    autoSave="false"
                                    autoComplete="off"
                                    name={aRFieldNames.randomDetail1stNext}
                                    value={form?.randomDetail1stNext || ""}
                                    onChange={e => onFormChange(e)}
                                    className="custom-form-control mr-2"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        data-testid="chkRandomDetailsRepeat"
                                        className="custom-check light"
                                        checkedIcon={<CheckIcon />}
                                        onChange={e => onFormChange(e)}
                                        name={aRFieldNames.randomDetailsRepeat}
                                        checked={form?.randomDetailsRepeat}
                                      />
                                    }
                                    label={getTranslations(
                                      t,
                                      Translates.Repeat
                                    )}
                                  />
                                  {form?.randomDetailsRepeat ? (
                                    <>
                                      {" "}
                                      <label style={{ minWidth: "70px" }}>
                                        {getTranslations(t, Translates.stop_at)}
                                      </label>
                                      <FormControl
                                        className="custom-form-control select no-border multi-select"
                                        variant="filled"
                                      >
                                        <Autocomplete
                                          id="randomDetailStopAt"
                                          className="auto-complete-full"
                                          style={{ width: "100px" }}
                                          size="small"
                                          disablePortal={true}
                                          options={generatePercentageValues()}
                                          value={form?.randomDetailStopAt}
                                          onChange={onPercentageChange}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              value={
                                                form?.randomDetailStopAt || ""
                                              }
                                              name={
                                                aRFieldNames.randomDetailStopAt
                                              }
                                              onChange={e => onFormChange(e)}
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </>
                                  ) : null}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {targetedLocationAuditTypeFlag && (
                        <Grid item xs={12} className="target-location-section">
                          <Divider className="dashed mt-3 mb-4" />
                          <label className="form-control__label mb-3">
                            {getTranslations(
                              t,
                              Translates.Location_System_Audit
                            )}
                          </label>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className="custom-form-control select no-border multi-select"
                                variant="filled"
                              >
                                <Autocomplete
                                  data-testid="targettedLocationPercentageThreshold"
                                  id="targettedLocationPercentageThreshold"
                                  options={generatePercentageValues()}
                                  value={
                                    form?.targettedLocationPercentageThreshold
                                  }
                                  onChange={onPercentageChange}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      value={
                                        form?.targettedLocationPercentageThreshold ||
                                        ""
                                      }
                                      name={
                                        aRFieldNames.targettedLocationPercentageThreshold
                                      }
                                      onChange={e => onFormChange(e)}
                                      label={getTranslations(
                                        t,
                                        Translates.Percentage_Threshold
                                      )}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container className="mt-2">
                            <Grid item xs={12}>
                              <strong className="f-600 mb-1">
                                {getTranslations(t, Translates.User_Type)}
                              </strong>
                              <div>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name={
                                      aRFieldNames.targettedLocationRequiredUser
                                    }
                                    onChange={e => onFormChange(e)}
                                    defaultValue={
                                      form?.targettedLocationRequiredUser
                                    }
                                  >
                                    <FormControlLabel
                                      value="Customer"
                                      control={<Radio />}
                                      label={getTranslations(
                                        t,
                                        Translates.Customer
                                      )}
                                    />
                                    <FormControlLabel
                                      value="WIS"
                                      control={<Radio />}
                                      label={getTranslations(t, Translates.WIS)}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {targetedRecordAuditTypeFlag && (
                        <Grid item xs={12} className="target-record-section">
                          <Divider className="dashed mt-3 mb-4" />
                          <label className="form-control__label mb-3">
                            {getTranslations(t, Translates.Record_System_Audit)}
                          </label>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className="custom-form-control select no-border multi-select"
                                variant="filled"
                              >
                                <Autocomplete
                                  id="targetedRecordPercentageThreshold"
                                  options={generatePercentageValues()}
                                  value={
                                    form?.targetedRecordPercentageThreshold
                                  }
                                  onChange={onPercentageChange}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      value={
                                        form?.targetedRecordPercentageThreshold ||
                                        ""
                                      }
                                      name={
                                        aRFieldNames.targetedRecordPercentageThreshold
                                      }
                                      onChange={e => onFormChange(e)}
                                      label={getTranslations(
                                        t,
                                        Translates.Percentage_Threshold
                                      )}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container className="mt-2">
                            <Grid item xs={12}>
                              <strong className="f-600 mb-1">
                                {getTranslations(t, Translates.User_Type)}
                              </strong>
                              <div>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name={
                                      aRFieldNames.targetedRecordRequiredUser
                                    }
                                    onChange={e => onFormChange(e)}
                                    defaultValue={
                                      form?.targetedRecordRequiredUser
                                    }
                                  >
                                    <FormControlLabel
                                      value="Customer"
                                      control={<Radio />}
                                      label={getTranslations(
                                        t,
                                        Translates.Customer
                                      )}
                                    />
                                    <FormControlLabel
                                      value="WIS"
                                      control={<Radio />}
                                      label={getTranslations(t, Translates.WIS)}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider className="dashed mt-3 mb-4" />
                {(randomAuditTypeFlag ||
                  targetedLocationAuditTypeFlag ||
                  targetedRecordAuditTypeFlag ||
                  followupAuditTypeFlag) && (
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <label className="form-control__label mb-3">
                        {getTranslations(t, Translates.Conditions)}
                      </label>
                      <div className="gray-bg pa-2">
                        <ReadOnlyLayout
                          form={{
                            ...form,
                            Name: form?.reason,
                            isRecordLevelAudit:
                              form?.auditType === Audit_TypesObj.TargetedRecord
                          }}
                          index={isEdit || editIndex}
                          type={locationState.type ?? undefined}
                          component={CONFIG_MODULES.AuditRule}
                          bConfig={bConfig}
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="inner-view__footer">
              <Button
                variant="text"
                className="primary-btn cancel-btn mr-5"
                onClick={onCancelClick}
                data-testid="cancel"
              >
                {getTranslations(t, Translates.Cancel)}
              </Button>
              <Button
                variant="contained"
                className="primary-btn"
                onClick={() => onSaveClick()}
                disabled={
                  (!(form.auditType && form.reason) ? true : false) ||
                  configTypeFromStore === "view"
                }
                data-testid="save"
              >
                {getTranslations(t, Translates.Save)}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default NewAuditRule
