import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDispatch } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { Grid } from "@mui/material"
import { fieldNames } from "src/configurationPortal/pages/CountPrograms/Constant"
import CountProgramBreadCrumb from "../Components/CBreadCrumb"
import { IFormCountProgram } from "./../../../utils/types/types"
import MUIText from "src/components/MUIFormFields/MUIText"
import CPTabs from "./Component/CPTabs"
import SubmitAndCancelButton from "./Component/SubmitAndCancelButton"
import { setNewCountProgram } from "src/redux/slices/configSlice"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import {
  getBreadcrumbPath,
  getDefaultValues,
  reduxToFormConversion
} from "./helper"
import { validationSchema } from "./validation"
import { PATH } from "src/constants/paths"
import { updateWarnOnTabChangeToStore } from "../helper"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
const CreateEditCountProgram = () => {
  const { t } = useTranslation<any>()
  const {
    config: { newCountProgram, countPrograms }
  } = ConfigDataHook()

  const dispatch = useDispatch()
  const location = useLocation() as any
  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const history = useHistory()
  let search = window.location.search
  const formHookMethods = useForm<IFormCountProgram>({
    defaultValues: getDefaultValues(newCountProgram),
    resolver: yupResolver(validationSchema(countPrograms, storeId)),
    criteriaMode: "all",
    mode: "onChange"
  })
  const { setValue, reset } = formHookMethods

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      window.onbeforeunload = () => true

      if (!Object.keys(newCountProgram).length) {
        if (storeId !== -1) {
          if (countPrograms[storeId - 1]) {
            const formData: IFormCountProgram = reduxToFormConversion(
              countPrograms[storeId - 1]
            )
            dispatch(setNewCountProgram(formData))
            reset(formData)
          } else {
            history.push(PATH.COUNT_PROGRAMS + search)
          }
        }
      } else if (location.state?.data) {
        updateWarnOnTabChangeToStore(true)
        const data = JSON.parse(location.state.data)
        setValue(data.InstructionName, data.Instructions)
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [])
  const { componentName, bConfig } = getBreadcrumbPath(storeId, t)

  return (
    <>
      <CountProgramBreadCrumb customPath={componentName} bConfig={bConfig} />
      <FormProvider {...formHookMethods}>
        <Grid container className="main-container" data-testid="countProgramPD">
          <Grid item xs={12} sm={12} className="mb-8">
            <Grid container>
              <Grid item xs={12} sm={3}>
                <label className="form-control__label mb-3">
                  {getTranslations(t, Translates.COUNT_DETAILS)}
                </label>

                <MUIText
                  inputAttributes={{
                    name: fieldNames.name.name,
                    label: `${getTranslations(t, fieldNames.name.label)} *`
                  }}
                />
              </Grid>
            </Grid>
            <CPTabs />
          </Grid>
          <SubmitAndCancelButton />
        </Grid>
      </FormProvider>
    </>
  )
}

export default CreateEditCountProgram
