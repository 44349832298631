import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid
} from "@mui/material"
import ModalComponents from "./ModalComponents"

import CloseIcon from "@mui/icons-material/Close"
import { FormProvider, useForm } from "react-hook-form"
import { Schema } from "./Validation"
import { yupResolver } from "@hookform/resolvers/yup"
import { functionTypes, getDefaultValues } from "./LRConstants"
import { useEffect, useRef, useState } from "react"
import {
  AutoStoreRadioOptions,
  checkDataType,
  CheckDigitRadioOptions,
  currentFieldOptions,
  lookupDepartmentOptions,
  lookupProductOptions,
  LookupRoutineCopyOverRide,
  lookupTableOptions,
  TrueOrFalse
} from "./LRConstants/fieldOptions"
import fieldTypes from "src/constants/fieldTypes"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { firstLetterUpperCase } from "../helper"
import { ruleEditorFunctions } from "./LRConstants/functionNames"
import {
  booleanError,
  booleanErrorMessage,
  sourceField
} from "./LRConstants/booleanFunctions"
import { getTranslations } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { useTranslation } from "react-i18next"

const RenderModal = ({
  item,
  setOpen,
  open,
  setParameters,
  parameters,
  setModalFormSubmitCall,
  setItem,
  isIfElseState
}) => {
  let content = item?.content
  const submitButton = useRef<HTMLButtonElement>(null)
  const closeButton = useRef<HTMLButtonElement>(null)
  const {
    config: { lookupRoutines }
  } = ConfigDataHook()

  const [inputTypes, setInputTypes] = useState<any[]>(item?.inputType)
  const { t } = useTranslation<any>()

  const getInputFields = async (options, inx) => {
    if (
      item.function === functionTypes.Lookup ||
      item.function === functionTypes.AutoStore ||
      item.function === functionTypes.LookupRoutine
    ) {
      if (options.length) {
        const locInputTypesCopy = [...inputTypes]
        const locItemCopy = JSON.parse(JSON.stringify(item))
        locInputTypesCopy[inx] = {
          ...locInputTypesCopy[inx],
          ...{ options: options }
        }
        locInputTypesCopy[inx].validation = {
          ...locInputTypesCopy[inx].validation,
          ...{ oneOf: options.map(i => i.value) }
        }
        locItemCopy.inputType[inx] = {
          ...locItemCopy.inputType[inx],
          ...locInputTypesCopy[inx]
        }
        setInputTypes([...locInputTypesCopy])
        item = locItemCopy
      }
    }
    return item
  }

  const LookupRoutineNameOptions = inputInx => {
    const Options = lookupRoutines?.map(i => {
      return { label: firstLetterUpperCase(i?.Name), value: i?.Name }
    })
    getInputFields(Options, inputInx)
  }

  const formHookMethods = useForm<any>({
    defaultValues: getDefaultValues(item, inputTypes, parameters),
    resolver: yupResolver(Schema(inputTypes, item.function)),
    criteriaMode: "all",
    mode: "onChange"
  })
  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
    setFocus,
    reset
  } = formHookMethods
  const params1 = watch("parameter1")
  const params2 = watch("parameter2")

  const handleKeyDown = e => {
    if (open && !formErrorCheckFunction() && e.key === "Enter") {
      submitButton.current && submitButton.current.click()
    } else if (e.key === "Escape") {
      closeButton.current && closeButton.current.click()
    }
  }

  useEffect(() => {
    let isMounted: boolean = true
    let timer1: any
    if (isMounted) {
      reset()
      document.addEventListener("keyup", handleKeyDown, false)
      let inputTypeCopy: any = [...inputTypes]
      if (item?.functionType === ruleEditorFunctions.booleanFn) {
        if (isIfElseState) {
          setValue(
            sourceField.name,
            item?.functionSource
              ? item?.functionSource
              : currentFieldOptions[0].value
          )
        } else {
          if (parameters.length) {
            setValue(booleanError.name, item?.OnFail)
            setValue(
              booleanErrorMessage.name,
              item?.OnFailMessage[0]?.["en-us"]
            )
          }
        }
        setInputTypes([...inputTypeCopy])
      }

      if (item.function === functionTypes.LookupRoutine) {
        LookupRoutineNameOptions(0)
      }

      timer1 = setTimeout(() => {
        setFocus(`${inputTypes[0].name}`)
      }, 500)
    }
    return () => {
      document.removeEventListener("keyup", handleKeyDown, false)
      clearTimeout(timer1)
      isMounted = false
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted: boolean = true
    if (isMounted) {
      if (params1) {
        let inputTypeCopy: any = [...inputTypes]
        if (item.function === functionTypes.Lookup) {
          if (watch(inputTypes[0].name) === lookupTableOptions[0].label) {
            getInputFields(lookupProductOptions, 1)
          } else if (
            watch(inputTypes[0].name) === lookupTableOptions[1].label
          ) {
            getInputFields(lookupDepartmentOptions, 1)
          }
        } else if (item.function === functionTypes.AutoStore) {
          inputTypes.forEach((i, j) => {
            if (j !== 0) {
              let required: boolean = true
              if (
                (j === 1 && `${params1}` === AutoStoreRadioOptions[1].value) ||
                (j === 2 && `${params1}` === AutoStoreRadioOptions[0].value)
              ) {
                required = false
              }
              inputTypeCopy[j] = {
                ...inputTypes[j],
                ...{
                  validation: {
                    ...i.validation,
                    ...{
                      required: required
                    }
                  }
                }
              }
            }
          })
          setInputTypes([...inputTypeCopy])
        } else if (
          [
            functionTypes.CheckLengthRange,
            functionTypes.CheckValueRange
          ].includes(item.function)
        ) {
          if (watch(inputTypes[1].name)) {
            trigger(inputTypes[1].name)
          }
        } else if (
          [functionTypes.CheckDigit, functionTypes.AddCheckDigit].includes(
            item.function
          )
        ) {
          inputTypes.forEach((i, j) => {
            let locInx = j
            let barcodeInx = 0
            if ([sourceField.name].includes(inputTypes[0]?.name)) {
              locInx = j > 0 ? j - 1 : j
              barcodeInx = 1
            }
            if (locInx !== 0) {
              inputTypeCopy[j] = {
                ...inputTypes[j],
                ...{
                  validation: {
                    ...i.validation,
                    ...{
                      required:
                        watch(inputTypes[barcodeInx].name) ===
                        CheckDigitRadioOptions[2].value
                    }
                  }
                }
              }
            }
          })
          setInputTypes([...inputTypeCopy])
        } else if ([functionTypes.AllowKey].includes(item.function)) {
          inputTypes.forEach((i, j) => {
            if (j !== 0) {
              inputTypeCopy[j] = {
                ...inputTypes[j],
                ...{
                  validation: {
                    ...i.validation,
                    ...{
                      required:
                        watch(inputTypes[0].name) === checkDataType[1].value
                    }
                  }
                }
              }
            }
          })
          setInputTypes([...inputTypeCopy])
        }
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [params1])

  useEffect(() => {
    let isMounted: boolean = true
    if (isMounted) {
      if (item.function === functionTypes.LookupRoutine) {
        setInputTypes(oldVals =>
          oldVals.map((i, j) => {
            return j === 2
              ? {
                  ...i,
                  validation: {
                    ...i.validation,
                    required: params2 === TrueOrFalse[0].value
                  }
                }
              : i
          })
        )
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [params2])

  const formSubmitHandler = (data: any) => {
    let newParameterArray: any = []
    inputTypes.forEach((inputType, inx) => {
      let isPush: boolean = true
      let fieldVal: any = data[inputType.name]

      let locInx = inx
      let barcodeInx = 0
      if ([sourceField.name].includes(inputTypes[0]?.name)) {
        locInx = inx > 0 ? inx - 1 : inx
        barcodeInx = 1
      }

      if (
        inputType?.validation?.type === fieldTypes.number &&
        !inputType?.validation?.required
      ) {
        if (!fieldVal && fieldVal !== 0) {
          isPush = false
        }
        fieldVal = fieldVal ? Number(fieldVal) : fieldVal
      }
      if (
        [functionTypes.CheckDigit, functionTypes.AddCheckDigit].includes(
          item.function
        )
      ) {
        if (
          barcodeInx === inx &&
          data[inputTypes[barcodeInx].name] === CheckDigitRadioOptions[2].value
        ) {
          isPush = false
        }

        if (locInx !== 0) {
          isPush = false
          if (
            data[inputTypes[barcodeInx].name] ===
            CheckDigitRadioOptions[2].value
          ) {
            isPush = true
            if (locInx === 4) {
              fieldVal = fieldVal.split(",").map(i => Number(i))
            }
          }
        }
      } else if (item.function === functionTypes.CheckLengths) {
        if (locInx === 0) {
          fieldVal = fieldVal.split(",").map(i => Number(i))
        }
      } else if (item.function === functionTypes.IsIn) {
        if (locInx === 0) {
          fieldVal = fieldVal.split(",")
        }
      } else if (item.function === functionTypes.LookupRoutine) {
        if (inx !== 0) {
          if (inx === 2) {
            fieldVal = [
              {
                "en-us":
                  data[inputTypes[inx - 1].name] === TrueOrFalse[0].value
                    ? fieldVal
                    : ""
              }
            ]
          } else if (inx === 4) {
            fieldVal =
              data[inputTypes[inx - 1].name] === TrueOrFalse[0].value
                ? fieldVal
                : LookupRoutineCopyOverRide[0].value
          }
        }
      } else if (item.function === functionTypes.Error) {
        if (inx === 0) {
          fieldVal = [{ "en-us": fieldVal }]
        } else if (inx === 2) {
          if (data[inputTypes[1].name] === TrueOrFalse[1].value) {
            isPush = false
          }
        }
      } else if (item.function === functionTypes.AutoStore) {
        if (
          inx === 0 ||
          (inx === 1 &&
            data[inputTypes[0].name] === AutoStoreRadioOptions[1].value) ||
          (inx === 2 &&
            data[inputTypes[0].name] === AutoStoreRadioOptions[0].value)
        ) {
          isPush = false
        }
      } else if (item.function === functionTypes.AllowKey) {
        if (inx === 1 && data[inputTypes[0].name] !== checkDataType[1].value) {
          isPush = false
        }
      }

      if (item?.functionType === ruleEditorFunctions.booleanFn) {
        if (isIfElseState) {
          if (inputType.name === sourceField.name) {
            let locSourceVal = data[inputTypes[inx].name]
            if (
              [currentFieldOptions[0].value].includes(
                data[inputTypes[inx].name]
              )
            ) {
              locSourceVal = ""
            }
            isPush = false
            item.functionSource = locSourceVal
            item.functionStore = `${locSourceVal ? locSourceVal + "." : ""}${
              item.function
            }`
          }
        } else {
          if (inputType.name === booleanError.name) {
            isPush = false
            item.OnFail = data[inputTypes[inx].name]
          } else if (inputType.name === booleanErrorMessage.name) {
            isPush = false
            item.OnFailMessage = [{ "en-us": data[inputTypes[inx].name] }]
          }
        }
      }

      if (
        [
          TrueOrFalse[0].value,
          TrueOrFalse[0].value.toUpperCase(),
          TrueOrFalse[0].value.toLowerCase()
        ].includes(data[inputTypes[inx].name])
      ) {
        fieldVal = true
      } else if (
        [
          TrueOrFalse[1].value,
          TrueOrFalse[1].value.toUpperCase(),
          TrueOrFalse[1].value.toLowerCase()
        ].includes(data[inputTypes[inx].name])
      ) {
        fieldVal = false
      }

      if (isPush) {
        newParameterArray.push(fieldVal)
        if (
          [
            functionTypes.CheckLengths,
            functionTypes.CheckBarcodeType,
            functionTypes.AllowScan,
            functionTypes.IsIn
          ].includes(item.function)
        ) {
          newParameterArray = newParameterArray.flat(Infinity)
        }

        if (inx === 1 && data[inputTypes[0].name] === checkDataType[1].value) {
          newParameterArray.push(true)
        }
      }
    })

    setItem({ ...item })
    setParameters(newParameterArray)
    setModalFormSubmitCall(true)
  }

  const formErrorCheckFunction = () => {
    let error: boolean = false
    if (inputTypes && inputTypes.length > 0) {
      inputTypes.forEach((i, inx) => {
        if (!!errors[i.name]) {
          error = true
          return true
        } else if (i.validation) {
          if (
            i.validation.required &&
            !watch(i.name) &&
            ![0, "0"].includes(watch(i.name))
          ) {
            if (item.function === functionTypes.AutoStore && inx !== 0) {
              if (inx === 1) {
                if (
                  watch(inputTypes[0].name) === AutoStoreRadioOptions[0].value
                ) {
                  error = true
                  return true
                }
              } else if (inx === 2) {
                if (
                  watch(inputTypes[0].name) === AutoStoreRadioOptions[1].value
                ) {
                  error = true
                  return true
                }
              }
            } else if (
              [functionTypes.CheckDigit, functionTypes.AddCheckDigit].includes(
                item.function
              ) &&
              inx !== 0
            ) {
              if (
                watch(inputTypes[0].name) === CheckDigitRadioOptions[2].value
              ) {
                error = true
                return true
              }
            } else {
              error = true
              return true
            }
          } else if (i.validation.type === fieldTypes.array) {
            if (watch(inputTypes[inx].name).length <= 0) {
              error = true
              return true
            }
          }
        }
      })
    }
    return error
  }

  return (
    <FormProvider {...formHookMethods}>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        className="custom-dialog"
      >
        <div>
          <Grid container className="custom-dialog__header p-3">
            <Grid
              className="custom-dialog__header-close"
              onClick={() => {
                setOpen(false)
                setParameters([])
              }}
            >
              <Button
                variant="text"
                className="secondary-btn"
                startIcon={<CloseIcon />}
                data-testid="lookup-modal-close"
                ref={closeButton}
              >
                {getTranslations(t, Translates.Close)}
              </Button>
            </Grid>
            <h2>{content}</h2>
          </Grid>
          <DialogContent className="custom-dialog__cont p-3">
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Grid item xs={12} className="mb-5">
                      <>
                        <ModalComponents
                          item={item}
                          inputTypes={inputTypes}
                          isIfElseState={isIfElseState}
                        />
                      </>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="custom-dialog__actions p-3">
            <Button
              variant="contained"
              className="primary-btn"
              disabled={formErrorCheckFunction()}
              onClick={handleSubmit(formSubmitHandler)}
              ref={submitButton}
            >
              {getTranslations(t, Translates.Continue)}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </FormProvider>
  )
}

export default RenderModal
