import { useFormContext } from "react-hook-form"
import { FormGroup, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import {
  SortList,
  VarianceTypes,
  fieldNames,
  singleFieldNames
} from "../../../Utils/Constants"
import { Translates } from "src/i18n/i18n"
import {
  getVarianceFormVariable,
  setFocusWhileChange
} from "../../../Utils/FunctionsHelper"
import MUICheckbox from "src/components/MUIFormFields/MUICheckbox"
import MUIText from "src/components/MUIFormFields/MUIText"
import { getTranslations, handleFocus } from "src/utils/helper"
import MUIAutoCompleteText from "src/components/MUIFormFields/MUIAutoCompleteText"

const VarianceGeneralSettings = () => {
  const { t } = useTranslation<any>()
  const {
    watch,
    setFocus,
    setValue,
    formState: { errors }
  } = useFormContext()

  let { varianceForm } = getVarianceFormVariable(
    watch(fieldNames.CurrentTabVarianceType)
  )
  const IsNotEnableFeature = !watch(varianceForm.IsEnable.name)

  const handleIsBatchSizeOnChange = () => {
    if (
      ["", undefined, null].includes(watch(varianceForm.BatchSize.name)) ||
      errors[watch(fieldNames.CurrentTabVarianceType)]?.[
        singleFieldNames.BatchSize
      ]
    ) {
      setValue(varianceForm.BatchSize.name, 0, {
        shouldValidate: true
      })
    }
    setFocusWhileChange(varianceForm.BatchSize.name, setFocus)
  }
  return (
    <>
      <h4 className="mb-3">
        {getTranslations(t, Translates.General_Settings)}
      </h4>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <MUIText
            inputAttributes={{
              name: varianceForm.VarianceName.name,
              label: `${getTranslations(t, varianceForm.VarianceName.label)} *`,
              disabled: IsNotEnableFeature
            }}
          />
          <MUIAutoCompleteText
            inputAttributes={{
              name: varianceForm.Sort.name,
              label: `${getTranslations(t, varianceForm.Sort.label)} *`,
              options: SortList,
              isOptionTranslate: true,
              disabled: IsNotEnableFeature
            }}
          />
        </Grid>
      </Grid>

      <Grid container className="mt-2">
        <Grid item xs={12}>
          <div className="d-flex align-items-end mb-2">
            <MUICheckbox
              inputAttributes={{
                name: varianceForm.IsBatchSize.name,
                label: getTranslations(t, varianceForm.IsBatchSize.label),
                className: "light",
                disabled: IsNotEnableFeature,
                onChange: () => handleIsBatchSizeOnChange()
              }}
            />
            {watch(varianceForm.IsBatchSize.name) ? (
              <MUIText
                inputAttributes={{
                  name: varianceForm.BatchSize.name,
                  label: `${getTranslations(
                    t,
                    varianceForm.BatchSize.label
                  )} *`,
                  disabled: IsNotEnableFeature,
                  className: "variance-batch-size",
                  onFocus: handleFocus
                }}
              />
            ) : null}
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={8}>
          <FormGroup row>
            <MUICheckbox
              inputAttributes={{
                name: varianceForm.IsBlindVariance.name,
                label: getTranslations(t, varianceForm.IsBlindVariance.label),
                className: "light",
                disabled: IsNotEnableFeature
              }}
            />
            <MUICheckbox
              inputAttributes={{
                name: varianceForm.IsRequiredForCloseout.name,
                label: getTranslations(
                  t,
                  varianceForm.IsRequiredForCloseout.label
                ),
                className: "light",
                disabled: IsNotEnableFeature
              }}
            />
          </FormGroup>
        </Grid>
      </Grid>

      {watch(fieldNames.CurrentTabVarianceType) ===
      VarianceTypes.FullVariance.name ? (
        <Grid container className="mt-2">
          <Grid item xs={12} sm={8}>
            <MUICheckbox
              inputAttributes={{
                name: varianceForm.IsBasedOnControlFile.name,
                label: getTranslations(
                  t,
                  varianceForm.IsBasedOnControlFile.label
                ),
                className: "light",
                disabled: IsNotEnableFeature
              }}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  )
}

export default VarianceGeneralSettings
