import { useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid } from "@mui/material"
import { getTranslations, replaceAllMultiple } from "src/utils/helper"
import { Translates } from "src/i18n/i18n"
import { useTranslation } from "react-i18next"
import {
  conditionCheckOnLoading,
  getBreadcrumbConfig,
  getDefaultValues
} from "./Utils/Helpers/CreateEditColumnHelper"
import { validationSchema } from "./Utils/CreateEditColumnValidation"
import FormFieldConditionRender from "../FormFieldConditionRender"
import CBreadCrumb from "../CBreadCrumb"
import FormSubmitCancel from "./Components/CreateEditColumn/FormSubmitCancel"
import { OutputColumnFieldType, ReportColumnFieldType } from "./Utils/Types"
import { getVariableBasedOnComponent } from "./Utils/Helpers/ColumnsGridHelper"
import FormHeading from "src/components/FormHeading"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { setValueForVariable } from "../../FunctionHelper"
import { ConfigTabNames } from "../../helper"

const CreateEditColumn = () => {
  const { t } = useTranslation<any>()
  let location = useLocation() as any
  const history = useHistory()
  const {
    config: { configDataHookCalled },
    columnReduxData: { columnsRedux }
  } = ConfigDataHook()

  const { id, tab, tabId }: { id: string; tab: string; tabId: string } =
    useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const storeTabId: number = tabId !== undefined ? Number(tabId) : -1 //want to get index of array means subtract 1
  let search = window.location.search || ""

  let stateVariableRedux = location.state?.variableRedux
  let stateColumnRedux = location.state?.columnRedux

  let {
    variableSetRedux,
    getFieldFn,
    variableRedux,
    variablesRedux,
    redirectUrl
  } = getVariableBasedOnComponent(tab, storeTabId, storeId)

  const locColumnsRedux = setValueForVariable({
    values: [stateColumnRedux, columnsRedux],
    defaultValue: {}
  })

  const locVariableRedux = setValueForVariable({
    values: [stateVariableRedux, variableRedux, variablesRedux[storeTabId - 1]],
    defaultValue: {}
  })

  redirectUrl =
    replaceAllMultiple(redirectUrl, [":tab", ":id"], [tab, tabId]) + search
  let ColumnFieldDetails: ReportColumnFieldType | OutputColumnFieldType =
    getFieldFn(locColumnsRedux)

  const columns = locVariableRedux?.Columns || []

  const locGetDefaultValues = () => {
    return getDefaultValues(columns, storeId, tab)
  }

  const formHookMethods = useForm<any>({
    defaultValues: locGetDefaultValues(),
    resolver: yupResolver(
      validationSchema(locColumnsRedux, tab, t, Translates)
    ),
    criteriaMode: "all",
    mode: "onChange"
  })

  const { reset } = formHookMethods
  useEffect(() => {
    let isMounted = true
    if (isMounted && configDataHookCalled) {
      let isRedirect = false

      if (
        tab.toLocaleLowerCase() !==
          ConfigTabNames.MultiEventOutputs.toLocaleLowerCase() &&
        ((storeTabId !== -1 &&
          (storeTabId > variablesRedux.length || storeTabId <= 0)) ||
          (storeId !== -1 && (storeId > columns.length || storeId <= 0)))
      ) {
        isRedirect = true
      }

      if (isRedirect) {
        history.push(redirectUrl)
      } else {
        conditionCheckOnLoading({
          locVariableRedux,
          variableSetRedux,
          variableRedux,
          columnsRedux,
          storeTabId,
          locColumnsRedux,
          storeId,
          reset,
          locGetDefaultValues
        })
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [configDataHookCalled])

  const bConfig = getBreadcrumbConfig(t, tab, storeId)

  return (
    <>
      <CBreadCrumb bConfig={bConfig} customPath="Column Details" />
      <FormProvider {...formHookMethods}>
        <Grid container className="main-container">
          <FormHeading
            heading={`${getTranslations(t, Translates.DETAILS)} *`}
          />
          <FormFieldConditionRender FieldDetails={ColumnFieldDetails} />
          <FormSubmitCancel />
        </Grid>
      </FormProvider>
    </>
  )
}

export default CreateEditColumn
