import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material"
import React from "react"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import InfoIcon from "@mui/icons-material/Info"
import shortid from "shortid"

import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table"
import { useAppThunkDispatch } from "src/redux/store"
import {
  setScheduleColumn,
  setScheduleSort
} from "src/redux/slices/scheduleSlice"
import {
  setReservationColumn,
  setReservationSort
} from "src/redux/slices/reservationsSlice"
import { setUsersColumn, setUsersSort } from "src/redux/slices/usersSlice"
import {
  setConfigurationColumn,
  setConfigurationSort
} from "src/redux/slices/configurationSlice"
import { setReportColumn, setReportSort } from "src/redux/slices/reportsSlice"
import {
  setLocationColumn,
  setLocationSort
} from "src/redux/slices/settingsSlice"
import { DESCENDING } from "src/utils/constants"

const GlobalTable: React.FC<any> = ({
  columns,
  data,
  noDataText = "No Data Found",
  initialStateProp = {
    sortBy: []
  }
}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow
    // setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: {
        ...initialStateProp
      },
      columnType: ""
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!,
    useSortBy
  )

  const dispatch = useAppThunkDispatch()
  // istanbul ignore next
  const checkSorting = (column: any) => {
    if (column.isSorted) {
      return column.isSortedDesc ? (
        <Tooltip arrow title="Sort">
          <ArrowDropDownIcon />
        </Tooltip>
      ) : (
        <Tooltip arrow title="Sort">
          <ArrowDropUpIcon />
        </Tooltip>
      )
    } else {
      return ""
    }
  }
  // istanbul ignore next
  const handleSortChange = column => {
    let sortOrder
    if (column.isSorted) {
      if (column.isSortedDesc) {
        sortOrder = DESCENDING.Asc
      } else {
        sortOrder = DESCENDING.Desc
      }
    } else {
      sortOrder = DESCENDING.Asc
    }
    if (column.columnType === "schedule") {
      dispatch(setScheduleColumn(column.id))
      dispatch(setScheduleSort(sortOrder))
    } else if (column.columnType === "reservation") {
      dispatch(setReservationColumn(column.id))
      dispatch(setReservationSort(sortOrder))
    } else if (column.columnType === "users") {
      dispatch(setUsersColumn(column.id))
      dispatch(setUsersSort(sortOrder))
    } else if (column.columnType === "ftpConnection") {
      dispatch(setConfigurationColumn(column.id))
      dispatch(setConfigurationSort(sortOrder))
    } else if (column.columnType === "finalReports") {
      dispatch(setReportColumn(column.id))
      dispatch(setReportSort(sortOrder))
    } else {
      dispatch(setLocationColumn(column.id))
      dispatch(setLocationSort(sortOrder))
    }
  }
  return (
    <Table className="custom-table" {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup, hIndex) => (
          <TableRow
            {...headerGroup.getHeaderGroupProps()}
            key={`glob-h-group-${shortid.generate()}`}
          >
            {headerGroup.headers.map((column: any, colIndex) => (
              <TableCell
                style={{ minWidth: `${column.render("setWidth")}px` }}
                key={shortid.generate()}
                onClick={
                  //istanbul ignore next
                  () => handleSortChange(column)
                }
              >
                <div
                  className="custom-table__sort"
                  {...column.getSortByToggleProps(
                    column.getSortByToggleProps()
                  )}
                  title=""
                >
                  <span title={column.Header}>{column.render("Header")}</span>

                  <span
                    className={`custom-table__sort-icon ${
                      column.canFilter ? "with-filter" : ""
                    }`}
                  >
                    {checkSorting(column)}
                  </span>
                </div>
                <span className="custom-table__filter">
                  {column.canFilter ? column.render("Filter") : null}
                </span>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {(!rows || rows.length == 0) && (
          // istanbul ignore next
          <TableRow>
            <TableCell colSpan={columns.length}>
              <Typography align="center" variant="subtitle1">
                <h6>
                  {typeof noDataText === "string" ? (
                    <InfoIcon className="primary-text" />
                  ) : null}
                  <span style={{ paddingLeft: "12px" }}></span>
                  {noDataText}
                </h6>
              </Typography>
            </TableCell>
          </TableRow>
        )}

        {rows.map((row, _i) => {
          prepareRow(row)
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
export default GlobalTable
