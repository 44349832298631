import trashIcon from "../../../../src/assets/images/TrashIcon.svg"
import editIcon from "../../../../src/assets/images/Edit.png"
import Warning from "@mui/icons-material/Warning"
import {
  Alert,
  AlertTitle,
  Checkbox,
  Grid,
  IconButton,
  Tooltip
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import InputFilesToolBar from "src/components/ConfigurationPortal/InputFilesToolBar/InputFilesToolBar"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import GlobalTable from "src/components/GlobalTable/GlobalTable"
import {
  INPUT_FILE_ACTION_TYPE,
  INPUT_FILE_TYPE
} from "src/constants/inputFiles"
import { PATH } from "src/constants/paths"
import {
  setCustomerFormValues,
  setExcelInputFields,
  setDelimitedInputFields,
  setFixedLengthInputFields,
  setInputFiles,
  updateWarnOnTabChange
} from "src/redux/slices/configSlice"
import { Loader } from "src/utils/reusables/reusable"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { ConfigTabNames, updateWarnOnTabChangeToStore } from "../helper"
import { ConfigTabNavigateWarning } from "src/hooks/ConfigTabNavigateWarning"
import { getTranslations } from "src/utils/helper"

const InputFiles = ({ liftInputFilesSaveClick }) => {
  const { t } = useTranslation<any>()
  const {
    config: {
      inputFiles,
      configDetails,
      isGetConfigDecrypt,
      configType: configTypeFromStore
    }
  } = ConfigDataHook()
  const [isTabChangeCheck, setIsTabChangeCheck] = useState<boolean>(true)
  ConfigTabNavigateWarning({
    componentName: ConfigTabNames.InputFiles,
    isTabChangeCheck: isTabChangeCheck
  })

  useEffect(() => {
    if (configDetails?.configuration && isGetConfigDecrypt) {
      const configObject = JSON.parse(configDetails.configuration)
      if (!configObject.hasOwnProperty("FilesRequired")) {
        setCheckedProductFile(false)
        setCheckedDepartMentFile(false)
      } else {
        setCheckedProductFile(configObject.FilesRequired.ProductFileNotRequired)
        setCheckedDepartMentFile(
          configObject.FilesRequired.DepartmentFileNotRequired
        )
      }
    }
  }, [configDetails, isGetConfigDecrypt])
  const [checkedProductFile, setCheckedProductFile] = React.useState(false)
  const [checkedDepartMentFile, setCheckedDepartMentFile] =
    React.useState(false)
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [renderData, setRenderData] = useState([] as any)
  const [deleteRow, setDeleteRow] = useState({} as any)
  const history = useHistory()
  const dispatch = useDispatch()

  let search = window.location.search || ""
  useEffect(() => {
    dispatch(setCustomerFormValues({}))
    dispatch(setFixedLengthInputFields([]))
    dispatch(setDelimitedInputFields([]))
    dispatch(setExcelInputFields([]))
  }, [])

  useEffect(() => {
    window.onbeforeunload = () => true
    setRenderData([...(inputFiles || [])])
  }, [inputFiles])

  const deleteInputFile = (row: any) => {
    setIsConfirmDeleteOpen(true)
    setDeleteRow(row)
  }
  const setDeleteContent = () => {
    let content = `${getTranslations(
      t,
      Translates.Are_you_sure_you_want_to_delete
    )} ${deleteRow.Name}?`
    return (
      <Alert severity="error" icon={<Warning />} className="custom-alert">
        <AlertTitle className="alert__title">
          <h2>{getTranslations(t, Translates.Warning)}</h2>
        </AlertTitle>
        <p>
          {" "}
          <strong className="d-block">{content}</strong>
        </p>
      </Alert>
    )
  }
  const callbackDeleteConfirmation = (res: boolean) => {
    if (res) {
      let dt: any
      if (deleteRow.Type === INPUT_FILE_TYPE.Auxiliary) {
        dt = renderData.filter(f => f.SortOrder !== deleteRow.SortOrder)
      } else {
        dt = renderData.filter(f => f.Name !== deleteRow.Name)
      }

      dt = dt.map((m: any, i) => {
        return { ...m, SortOrder: i + 1 }
      })
      dispatch(setInputFiles(dt))
    }
    setIsConfirmDeleteOpen(false)
  }
  const inputFilesSaveClick = () => {
    window.onbeforeunload = null
    liftInputFilesSaveClick(
      [...inputFiles],
      "InputFilesTab",
      checkedProductFile,
      checkedDepartMentFile
    )
  }
  const handleChangeProductFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedProductFile(event.target.checked)
    dispatch(updateWarnOnTabChange(true))
    setIsTabChangeCheck(false)
  }
  const handleChangeDepartMentFile = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedDepartMentFile(event.target.checked)
    dispatch(updateWarnOnTabChange(true))
    setIsTabChangeCheck(false)
  }

  const editInputFile = (row: any) => {
    setIsTabChangeCheck(false)
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      if (row.original.Type === INPUT_FILE_TYPE.Auxiliary) {
        history.push(PATH.CREATE_EDIT_AUXILIARY_INPUT_FILE + search, {
          type: INPUT_FILE_ACTION_TYPE.EDIT,
          data: { ...row.original },
          index: row.index
        })
      } else if (row.original.Type === INPUT_FILE_TYPE.CustomerFile) {
        history.push(PATH.CREATE_EDIT_CUSTOMER_INPUT_FILE + search, {
          type: INPUT_FILE_ACTION_TYPE.EDIT,
          data: { ...row.original },
          index: row.index
        })
      }
    })
  }
  const columnsData = React.useMemo(
    () => [
      {
        Header: getTranslations(t, Translates.Name),
        accessor: "Name",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 250,
        Cell: props => {
          return props.value ? props.value : "-"
        }
      },
      {
        Header: getTranslations(t, Translates.Description),
        accessor: "Description",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 250,
        Cell: props => {
          return props.value ? props.value : "-"
        }
      },
      {
        Header: getTranslations(t, Translates.Type),
        accessor: "Type",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 250,
        Cell: props => {
          return props.value ? props.value : "-"
        }
      },
      {
        Header: getTranslations(t, Translates.ACTIONS),
        accessor: "action",
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: true,
        setWidth: 150,
        Cell: props => {
          return (
            <span className="action-btns">
              <Tooltip arrow title={getTranslations(t, Translates.Edit)}>
                <IconButton
                  data-testid="editBtn"
                  onClick={() => editInputFile(props.row)}
                >
                  <span className="action-btns text-center d-block ">
                    <img
                      className="pointer"
                      src={editIcon}
                      width="24"
                      alt={"edit icon"}
                    />
                  </span>
                </IconButton>
              </Tooltip>
              <Tooltip arrow title={getTranslations(t, Translates.Delete)}>
                <span>
                  <IconButton
                    data-testid="deleteBtn"
                    onClick={() => deleteInputFile(props.row.original)}
                    className={`${
                      configTypeFromStore === "view" ? "disable-img" : "pointer"
                    }`}
                  >
                    <span className="action-btns text-center d-block ">
                      <img
                        src={trashIcon}
                        width="24"
                        className={`${
                          configTypeFromStore === "view"
                            ? "disable-img"
                            : "pointer"
                        }`}
                        alt="delete"
                      />
                    </span>
                  </IconButton>
                </span>
              </Tooltip>
            </span>
          )
        }
      }
    ],
    [renderData, t]
  )
  const displayGrid = () => {
    return (
      <Grid container className="custom-table-responsive categories-table">
        <Grid xs={12} item>
          <Grid container>
            <Grid xs={4} item>
              <Checkbox
                checked={checkedProductFile}
                onChange={handleChangeProductFile}
                inputProps={{ "aria-label": "controlled" }}
              />{" "}
              {getTranslations(t, Translates.Product_File_Not_Required)}
            </Grid>
            <Grid xs={8} item>
              <Checkbox
                checked={checkedDepartMentFile}
                onChange={handleChangeDepartMentFile}
                inputProps={{ "aria-label": "controlled" }}
              />{" "}
              {getTranslations(t, Translates.DepartMent_File_Not_Required)}
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item data-testid="IFGlopTables">
          <GlobalTable
            component="inputFiles"
            columns={columnsData}
            data={renderData}
            noDataText={
              renderData.length && renderData.length !== 0
                ? Loader()
                : `${getTranslations(
                    t,
                    Translates.No_Input_Files_data_available
                  )}.`
            }
          ></GlobalTable>
        </Grid>
        {isConfirmDeleteOpen && (
          <ConfirmDialog
            open={isConfirmDeleteOpen}
            callBack={callbackDeleteConfirmation}
            title={getTranslations(t, Translates.DELETE)}
            content={setDeleteContent}
            custref={{}}
            confirmButtonText={getTranslations(t, Translates.CONFIRM)}
            cancelButtonText={getTranslations(t, Translates.Cancel)}
          ></ConfirmDialog>
        )}
      </Grid>
    )
  }

  return (
    <>
      <InputFilesToolBar
        inputFilesSaveClick={inputFilesSaveClick}
        setIsTabChangeCheck={setIsTabChangeCheck}
      />
      <div className="main-container">{displayGrid()}</div>
    </>
  )
}

export default InputFiles
