import { Controller, useFormContext } from "react-hook-form"
import { Autocomplete, FormControl, TextField } from "@mui/material"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import {
  getDefaultValue,
  getFieldError,
  getOptionLabelForAutoComplete
} from "./Helper"
import { getTranslations } from "src/utils/helper"
import { useTranslation } from "react-i18next"
import { useState } from "react"

interface Option {
  label: string
  value: any
  default?: boolean
}

export interface Props {
  inputAttributes: {
    name: string
    type?: "text" | "email" | "tel"
    label: string
    placeholder?: string
    className?: string
    id?: string
    required?: boolean
    size?: "medium" | "small"
    color?: "error" | "primary" | "secondary" | "info" | "success" | "warning"
    variant?: "standard" | "filled" | "outlined"
    fullWidth?: boolean
    InputProps?: any
    value?: string | number
    defaultValue?: string | number
    multiline?: boolean
    rows?: number
    maxRows?: number
    onChange?: Function
    disabled?: boolean
    options: Option[]
    isOptionTranslate?: boolean
    freeSolo?: boolean
    isNotSort?: boolean
  }
}

const MUIAutoCompleteText = ({ inputAttributes }: Props) => {
  const { t } = useTranslation<any>()
  const [inputValue, setInputValue] = useState<string>("")
  let {
    name,
    type,
    label,
    placeholder,
    className,
    id,
    size,
    color,
    variant,
    fullWidth,
    required,
    value,
    defaultValue,
    onChange,
    options,
    isOptionTranslate,
    disabled,
    freeSolo,
    isNotSort
  } = inputAttributes
  const {
    control,
    formState: { errors }
  } = useFormContext() // retrieve all hook methods

  let newOptions = !isNotSort
    ? [...options].sort((a, b) => a.label.localeCompare(b.label))
    : options
  if (isOptionTranslate) {
    newOptions = newOptions.map(i => ({
      ...i,
      label: getTranslations(t, i.label)
    }))
  }

  let defVal: any = getDefaultValue(value, defaultValue, newOptions)
  const { isError, ErrorText } = getFieldError(name, errors)

  const getInputValue = targetValue => {
    const result = newOptions.find(item => item.value === targetValue)
    const output = result && targetValue ? result.label : targetValue
    return `${output}`
  }
  return (
    <Controller
      name={`${name}`}
      control={control}
      defaultValue={defVal}
      render={({ field: { ref, ...field } }) => (
        <FormControl
          className="custom-form-control select no-border"
          variant="filled"
        >
          <Autocomplete
            ref={ref}
            data-testid="Test AutoComplete"
            className={`${className} auto-complete-full`}
            id={id ?? name}
            freeSolo={freeSolo}
            options={newOptions}
            value={field.value}
            {...(freeSolo && {
              inputValue: inputValue || getInputValue(field.value)
            })}
            onChange={(e, v, w, t) => {
              updateWarnOnTabChangeToStore(true)
              //istanbul ignore next
              const val = v ? v.value : ""
              field.onChange(val)
              if (freeSolo) {
                setInputValue(getInputValue(val))
              }
              if (onChange) onChange()
            }}
            onInputChange={(event, newInputValue, i) => {
              if (freeSolo && i === "input") {
                updateWarnOnTabChangeToStore(true)
                field.onChange(newInputValue)
                setInputValue(newInputValue)
              }
            }}
            disabled={disabled ?? false}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            getOptionLabel={option => {
              return getOptionLabelForAutoComplete(option, newOptions)
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={`${getTranslations(t, label)}`}
                fullWidth={fullWidth ?? true}
                required={required ?? false}
                size={size ?? "small"}
                placeholder={placeholder}
                disabled={disabled ?? false}
                type={type ?? "text"}
                variant={variant ?? "filled"}
                color={color ?? "primary"}
                error={isError}
                helperText={ErrorText}
                inputProps={{
                  ...params.inputProps
                }}
              />
            )}
          />
        </FormControl>
      )}
    />
  )
}

export default MUIAutoCompleteText
