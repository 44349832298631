import { useHistory, useParams } from "react-router-dom"
import { updateWarnOnTabChangeToStore } from "src/configurationPortal/pages/helper"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { useAppThunkDispatch } from "src/redux/store"
import { setReportsRedux } from "src/redux/slices/ConfigPortal/ReportSlice"
import { PATH } from "src/constants/paths"
import { createEditReportFieldNames } from "../../Utils/Constants/CreateEditReportConstant"
import FormSubmitCancelButtons from "src/configurationPortal/pages/Components/FormSubmitCancelButtons"

const FormSubmitCancel = () => {
  const history = useHistory()
  const { id }: { id: string; tab: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const {
    reportData: { reportsRedux }
  } = ConfigDataHook()

  const dispatch = useAppThunkDispatch()
  let search = window.location.search || ""
  const onCancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.REPORTS + search)
    })
  }

  const handleSaveClick = data => {
    window.onbeforeunload = () => null
    let locReportsRedux = JSON.parse(JSON.stringify(reportsRedux))

    if (!data[createEditReportFieldNames.IsInterim.name]) {
      data[createEditReportFieldNames.IsHasCheckMark.name] = false
    }

    if (!data[createEditReportFieldNames.IsFinal.name]) {
      data[createEditReportFieldNames.IsSignature.name] = false
    }
    if (storeId <= -1) {
      locReportsRedux = [...locReportsRedux, { ...data }]
    } else {
      locReportsRedux = locReportsRedux?.map((i, j) => {
        return j === storeId - 1 ? data : i
      })
    }
    dispatch(setReportsRedux(locReportsRedux))
    onCancelClick()
  }

  return (
    <FormSubmitCancelButtons
      handleSaveClick={handleSaveClick}
      onCancelClick={onCancelClick}
    />
  )
}

export default FormSubmitCancel
