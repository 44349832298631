import { useEffect, useReducer, useState } from "react"
import { Grid } from "@mui/material"
import ReportingsToolBar from "src/components/ConfigurationPortal/ReportingsToolBar/ReportingsToolBar"
import ReportsGrid from "./Components/LandingPage/ReportsGrid"
import { useAppThunkDispatch } from "src/redux/store"
import { setReportRedux } from "src/redux/slices/ConfigPortal/ReportSlice"
import { ConfigReportType } from "./Utils/Types"
import {
  SQLTestColumnSliceType,
  setColumnsRedux
} from "src/redux/slices/ConfigPortal/ColumnSlice"
import { ConfigTabNavigateWarning } from "src/hooks/ConfigTabNavigateWarning"
import { ConfigTabNames, reducer } from "../helper"
import {
  GetConfigPayload,
  alertMsgShow,
  emptyAlert,
  updateConfigurationData
} from "../FunctionHelper"
import { ConfigDataHook } from "src/hooks/configurationFileData"

let alertVisible: boolean = false
let alertMsg = emptyAlert

const Reports = () => {
  const {
    reportData: { reportsRedux },
    outputBundleData: { outputBundlesRedux }
  } = ConfigDataHook()

  const dispatch = useAppThunkDispatch()
  const [isTabChangeCheck, setIsTabChangeCheck] = useState<boolean>(true)
  const [, setReload] = useReducer(reducer, false)
  ConfigTabNavigateWarning({
    componentName: ConfigTabNames.Reports,
    isTabChangeCheck: isTabChangeCheck
  })

  const reportSaveClick = async () => {
    window.onbeforeunload = null
    let { payload } = GetConfigPayload()
    const allReportsNames = reportsRedux.map(i => i.Name)
    let locOutputBundles = (outputBundlesRedux || [])?.map(i1 => {
      return {
        ...i1,
        Reports: i1.Reports.filter(i => allReportsNames.includes(i))
      }
    })
    payload = {
      ...payload,
      configuration: JSON.stringify({
        ...payload.configuration,
        Reports: reportsRedux,
        OutputBundles: [...locOutputBundles]
      })
    }
    const { alertMsgUC } = await updateConfigurationData(payload)
    if (Object.keys(alertMsgUC).length) {
      alertVisible = true
      alertMsg = { ...emptyAlert, ...alertMsgUC }
      setReload(oldVal => !oldVal)
      hideAlert()
    }
  }

  const hideAlert = () => {
    setTimeout(() => {
      alertVisible = false
      alertMsg = emptyAlert
      setReload(oldVal => !oldVal)
    }, 3000)
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      window.onbeforeunload = () => true
      dispatch(setReportRedux({} as ConfigReportType))
      dispatch(setColumnsRedux({} as SQLTestColumnSliceType["columnsRedux"]))
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <ReportingsToolBar
        reportSaveClick={reportSaveClick}
        setIsTabChangeCheck={setIsTabChangeCheck}
      />
      <div className="main-container">{<ReportsGrid />}</div>
      <Grid container>
        {alertVisible ? <>{alertMsgShow(alertMsg)}</> : null}
      </Grid>
    </>
  )
}

export default Reports
