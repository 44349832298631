import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "../../redux/store"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import {
  setLiveEventsFilterForm,
  setLiveEventsFilters
} from "src/redux/slices/liveEventsSlice"
import {
  dateChangeHandler,
  filterAndSort,
  getRegionData,
  handleChangeCountryVal,
  handleChangeRegion,
  handleChangeStateVal,
  handleChangeStatusVal,
  handleResetAll
} from "src/utils/helper"
import FilterComponent from "src/utils/reusables/FilterComponent"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    },
    anchororigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformorigin: {
      vertical: "top",
      horizontal: "left"
    },
    getcontentanchorel: null
  }
}

const uniqStatus = ["In Progress", "Not Started", "Closed", "Cancelled"]

interface SReservation {
  scheduledDateTime: string
}

const LiveEventsFilter = ({
  storeData,
  userData,
  cancelClick,
  applyClick,
  countries,
  states,
  status,
  showFilterPage,
  currentDate,
  multipleCustomersAssigned
}) => {
  const dispatch = useAppThunkDispatch()
  const { t } = useTranslation<any>()
  const [date, setDate] = React.useState<SReservation>({} as SReservation)
  const [region1, setRegion1] = React.useState([] as any)
  const [region2, setRegion2] = React.useState([] as any)
  const [region3, setRegion3] = React.useState([] as any)
  const [region4, setRegion4] = React.useState([] as any)
  const liveEventsFilter = useSelector(
    (state: RootState) => state.liveEvents?.liveEventsFilters
  )
  const [filteredRegion1Values, setFilteredRegion1Values] = useState([] as any)
  const [filteredRegion2Values, setFilteredRegion2Values] = useState([] as any)
  const [filteredRegion3Values, setFilteredRegion3Values] = useState([] as any)
  const [filteredRegion4Values, setFilteredRegion4Values] = useState([] as any)
  const [countryName, setCountryName] = React.useState([] as any)
  const [statusName, setStatusName] = React.useState([] as any)
  const [stateName, setStateName] = React.useState([] as any)

  const handleChange = (event, id) => {
    handleChangeRegion(
      event,
      id,
      setRegion1,
      setRegion2,
      setRegion3,
      setRegion4
    )
  }
  const handleChangeCountry = event => {
    handleChangeCountryVal(event, setCountryName)
  }
  const handleChangeStatus = event => {
    handleChangeStatusVal(event, setStatusName, statusName, uniqStatus)
  }

  const handleChangeState = event => {
    handleChangeStateVal(event, setStateName)
  }
  useEffect(() => {
    getRegionData(
      userData,
      setFilteredRegion1Values,
      setFilteredRegion2Values,
      setFilteredRegion3Values,
      setFilteredRegion4Values
    )
    setRegion1(liveEventsFilter?.region1 || [])
    setRegion2(liveEventsFilter?.region2 || [])
    setRegion3(liveEventsFilter?.region3 || [])
    setRegion4(liveEventsFilter?.region4 || [])
    setStateName(liveEventsFilter?.state || [])
    setCountryName(liveEventsFilter?.country || [])
    setStatusName(liveEventsFilter?.eventStatus || [])
    setDate({
      scheduledDateTime: liveEventsFilter?.currentDate || ""
    })
  }, [liveEventsFilter])

  let uniqCountries = filterAndSort(countries)
  let uniqStates = filterAndSort(states)
  const sortedStatus = uniqStatus.slice().sort((a, b) => a.localeCompare(b))
  const isAllSelected = useMemo(
    () => uniqStatus.length > 0 && statusName.length === uniqStatus.length,
    [statusName, uniqStatus]
  )
  const applyFilterClick = () => {
    const filtersData = {
      region1: region1,
      region2: region2,
      region3: region3,
      region4: region4,
      country: countryName,
      state: stateName,
      currentDate: date.scheduledDateTime
        ? moment(date.scheduledDateTime).format("YYYY-MM-DD")
        : "",
      eventStatus: statusName
    }
    dispatch(setLiveEventsFilterForm(filtersData))
    applyClick(filtersData)
    dispatch(setLiveEventsFilters(filtersData))
    cancelClick()
  }

  const onDateChange = e => {
    moment.locale("us_en")
    dateChangeHandler(e, setDate, date)
  }

  const applyCancelClick = () => {
    cancelClick()
  }
  const handleResetAllClick = () => {
    handleResetAll({
      setRegion1,
      setRegion2,
      setRegion3,
      setRegion4,
      setCountryName,
      setStateName,
      setDate,
      setStatusName
    })
  }

  const handleKeyDown = useCallback(
    event => {
      event.preventDefault() // This does the trick
      if (event.keyCode === 8 || event.keyCode === 46) {
        const newDate = { scheduledDateTime: "" }
        setDate(newDate)
      }
    },
    [date, setDate]
  )

  return (
    <>
      {showFilterPage ? (
        <FilterComponent
          t={t}
          Translates={Translates}
          handleChange={handleChange}
          region1={region1}
          region2={region2}
          MenuProps={MenuProps}
          storeData={storeData}
          multipleCustomersAssigned={multipleCustomersAssigned}
          countryName={countryName}
          region3={region3}
          region4={region4}
          handleChangeCountry={handleChangeCountry}
          uniqCountries={uniqCountries}
          stateName={stateName}
          handleChangeState={handleChangeState}
          uniqStates={uniqStates}
          date={date}
          onDateChange={onDateChange}
          handleKeyDown={handleKeyDown}
          statusName={statusName}
          handleChangeStatus={handleChangeStatus}
          handleResetAllClick={handleResetAllClick}
          applyCancelClick={applyCancelClick}
          isAllSelected={isAllSelected}
          sortedStatus={sortedStatus}
          applyFilterClick={applyFilterClick}
          filteredRegion1Values={filteredRegion1Values}
          filteredRegion2Values={filteredRegion2Values}
          filteredRegion3Values={filteredRegion3Values}
          filteredRegion4Values={filteredRegion4Values}
        />
      ) : null}
    </>
  )
}

export default LiveEventsFilter
