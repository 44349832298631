import MainLayout from "src/layouts/MainLayout"
import LiveEventsToolBar from "src/components/LiveEventsToolBar/LiveEventsToolBar"
import { Box, Tab, Tabs, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import EventTiles from "./EventTiles"
import { Loader } from "src/utils/reusables/reusable"
import { LiveEventsService } from "src/service/liveEvents.service"
import { RootState } from "src/redux/store"
import { useSelector } from "react-redux"
import { Translates } from "src/i18n/i18n"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { getTranslations, searchLiveEvents } from "src/utils/helper"
import InfoIcon from "@mui/icons-material/Info"
import { PAGE_REFRESH_TIME_1MINS } from "src/utils/constants"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
let pollId
const LiveEvents = () => {
  const [value, setValue] = React.useState(1)
  const [eventsData, setEventsData] = useState({} as any)
  const [pollingCount, setPollingCount] = useState(0)
  const [eventsDataList, setEventsDataList] = useState([] as any)
  const [loading, setLoading] = useState(false)
  const liveEventsService = new LiveEventsService()
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const customerList = useSelector(
    (state: RootState) => state.settings?.customers
  )
  const selectedCustomerList = (customerList || []).filter(
    (c: any) => c.selected
  )
  let date = new Date()
  const { t } = useTranslation<any>()
  const [filterNum, setFilterNum] = useState<any>(0)
  const [showFilter, setShowFilter] = useState(false)
  const [searched, setSearched] = useState(false)
  // const timeStampSample = "YYYY-MM-DD[T]00:31:33.685[Z]"
  const hideFilter = () => {
    setShowFilter(false)
  }

  const pollApi = async () => {
    if (customerId) {
      pollId = setTimeout(async () => {
        const currentDate = getSelectedDate(value)
        await getDaysEvents(currentDate, false)
        setPollingCount(oldVal => oldVal + 1)
        pollApi()
      }, PAGE_REFRESH_TIME_1MINS)
    }
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted && (customerId || customerList?.length)) {
      const currentDate = getSelectedDate(value)
      getDaysEvents(currentDate)
      pollApi()
    }
    return () => {
      if (pollId) {
        clearInterval(pollId)
      }
      isMounted = false
    }
  }, [customerId, customerList?.length, value])

  const getDaysEvents = async (eventDate, isLoadingTrigger = true) => {
    const payloadToAdd = selectedCustomerList.map((c: any) => c.idCustomer)
    isLoadingTrigger && setLoading(true)
    await liveEventsService
      .getLiveEvents({ customerIds: payloadToAdd, date: eventDate })
      .then((temp: any) => {
        if (temp.data) {
          setEventsData(temp.data)
          setEventsDataList(temp.data.eventsDetails || [])
        }
      })
      .catch(_error => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const selectedDate: string = getSelectedDate(newValue)
    getDaysEvents(selectedDate)
    setValue(newValue)
  }

  const getSelectedDate = tabValue => {
    let selectedDate = ""
    if (tabValue === 0) {
      selectedDate = moment(date).utc().subtract(1, "day").format()
    } else if (tabValue === 1) {
      selectedDate = moment(date).utc().format()
    } else {
      selectedDate = moment(date).utc().add(1, "day").format()
    }
    return selectedDate
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    }
  }

  function TabPanel({ children, value, index, ...other }: TabPanelProps) {
    const panelClassName = value === index ? "tabpanel show" : "tabpanel"
    return (
      <div
        role="tabpanel"
        className={panelClassName}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  const handleSearch = searchVal => {
    let searchedEvents = searchLiveEvents(searchVal, eventsData?.eventsDetails)
    if (searchVal) {
      setSearched(true)
    } else {
      setSearched(false)
    }
    setLoading(true)
    setEventsDataList(searchedEvents || [])
    setLoading(false)
  }
  let eventsLength = Object.keys(eventsData)?.length
  let content = eventsLength ? (
    <div className="d-flex f-600 justify-content-center">
      <InfoIcon className="mr-1" />
      {getTranslations(t, Translates.No_Events_Found)}
    </div>
  ) : (
    Loader()
  )
  const getEventsTiles = () => {
    return eventsData?.eventsDetails?.length ? (
      <EventTiles
        response={eventsData || {}}
        eventsData={eventsDataList || []}
        tab={value}
        hideFilter={hideFilter}
        showFilterPage={showFilter}
        sendFilterDataToParent={sendFilterDataToParent}
        searched={searched}
        pollingCount={pollingCount}
      />
    ) : (
      content
    )
  }
  const sendFilterDataToParent = filters => {
    const region1Length = filters.region1?.length || 0
    const region2Length = filters.region2?.length || 0
    const region3Length = filters.region3?.length || 0
    const region4Length = filters.region4?.length || 0
    const countryLength = filters.country?.length || 0
    const stateLength = filters.state?.length || 0
    const statusLength =
      filters.eventStatus?.length === 4 ? 0 : filters.eventStatus?.length || 0
    const eventStartDateLength = filters.currentDate ? 1 : 0
    const num =
      region1Length +
      region2Length +
      region3Length +
      region4Length +
      countryLength +
      stateLength +
      statusLength +
      eventStartDateLength
    setFilterNum(num)
    return num
  }

  const handleFilter = () => {
    if (!showFilter) {
      setShowFilter(true)
    } else {
      setShowFilter(false)
    }
  }

  const tabLabels = [
    {
      label: `${getTranslations(t, Translates.Yesterday)}`,
      count: eventsData?.yesterdayEventsCount || 0
    },
    {
      label: `${getTranslations(t, Translates.Today)}`,
      count: eventsData?.todayEventsCount || 0
    },
    {
      label: `${getTranslations(t, Translates.Tomorrow)}`,
      count: eventsData?.tomorrowEventsCount || 0
    }
  ]

  return (
    <MainLayout>
      <LiveEventsToolBar
        handleSearch={handleSearch}
        handleFilter={handleFilter}
        liveEvents={eventsData?.eventsDetails}
        filterCount={eventsData?.eventsDetails?.length > 0 ? filterNum : 0}
        tabValue={value}
        pollingCount={pollingCount}
      />
      <div className="main-container">
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="custom-tabs"
            data-testid="mainBox"
          >
            <Tabs
              data-testid="tabs"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {tabLabels.map((tab, index) => (
                <Tab
                  data-testid="tabPanel"
                  key={tab.label}
                  label={`${tab.label} (${tab.count})`}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Box>
          {tabLabels.map((tab, index) => (
            <TabPanel
              data-testid="tabPanelInner"
              key={tab.label}
              value={value}
              index={index}
            >
              {loading ? Loader() : getEventsTiles()}
            </TabPanel>
          ))}
        </Box>
      </div>
    </MainLayout>
  )
}
export default LiveEvents
