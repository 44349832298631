import React from "react"
import { useFormContext } from "react-hook-form"
import { useParams } from "react-router"
import { Grid, Tab, Tabs } from "@mui/material"
import { Box } from "@mui/system"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import shortid from "shortid"
import {
  CountTypeList,
  fieldNames
} from "src/configurationPortal/pages/CountPrograms/Constant"
import {
  a11yProps,
  fetchInstructionLayout,
  getProductList,
  TabPanel
} from "./TabFunctionsAndConstant"
import MUICheckbox from "src/components/MUIFormFields/MUICheckbox"
import { getBreadcrumbPath } from "../helper"
import MUISelect from "src/components/MUIFormFields/MUISelect"
import { useTranslation } from "react-i18next"
import { getTranslations } from "src/utils/helper"

const ProductMode = () => {
  const { t } = useTranslation<any>()
  const { watch, setValue } = useFormContext()
  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1
  const productList = getProductList()
  const tabValue: number = watch(fieldNames.ChildTabIndex.name)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(fieldNames.ChildTabIndex.name, newValue)
    const keyName = fieldNames.productKeyName.name.replace(
      "{inx}",
      newValue.toString()
    )
    setValue(keyName, newValue)
  }
  const { bConfig } = getBreadcrumbPath(storeId, t, true)

  return (
    <Box sx={{ width: "100%" }}>
      <div className="p-2 my-2">
        <MUICheckbox
          inputAttributes={{
            name: fieldNames.ProductEnableMode.name,
            label: fieldNames.ProductEnableMode.label,
            className: "custom-check small"
          }}
        />
      </div>
      {watch(fieldNames.ProductEnableMode.name) ? (
        <>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <MUISelect
                inputAttributes={{
                  name: fieldNames.countType.name,
                  label: `${getTranslations(t,fieldNames.countType.label)} *`,
                  options: CountTypeList,
                  isOptionTranslate: true
                }}
              />
            </Grid>
          </Grid>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="inner-tabs"
          >
            <Tabs
              data-testid="CPPMTabs"
              value={tabValue}
              onChange={handleChange}
              aria-label="product mode tabs"
              variant="scrollable"
              scrollButtons
            >
              {productList.map((i, j) => {
                return (
                  <Tab
                    key={shortid.generate()}
                    icon={<LocationOnOutlinedIcon />}
                    label={`${
                      i.label?.toLowerCase() === i.originalValue?.toLowerCase()
                        ? i.label
                        : i.label + " (" + i.originalValue + ")"
                    }`}
                    {...a11yProps(j, "child")}
                    className="cp-child-tabs product-mode"
                  />
                )
              })}
            </Tabs>
          </Box>
          {productList.map((i, j) => {
            const isSeparateInsertRuleName =
              fieldNames.productIsSeparateInsertRuleName.name.replace(
                "{inx}",
                j.toString()
              )
            return (
              <TabPanel
                value={tabValue}
                index={j}
                type="child"
                key={shortid.generate()}
              >
                <div className="gray-bg pa-5">
                  <Grid container>
                    {fetchInstructionLayout(
                      fieldNames.productSetupInstructionArrayName.name,
                      fieldNames.productValidationInstructionArrayName.name,
                      fieldNames.productLookupInstructionArrayName.name,
                      j,
                      i.OjKey,
                      bConfig
                    )}
                  </Grid>
                  <div className="p-2 my-2">
                    <MUICheckbox
                      inputAttributes={{
                        name: isSeparateInsertRuleName,
                        label: getTranslations(
                          t,
                          fieldNames.productIsSeparateInsertRuleName.label
                        ),
                        className: "custom-check small"
                      }}
                    />
                  </div>
                  {watch(isSeparateInsertRuleName) ? (
                    <>
                      <h4 className="mt-5">Insert Rules</h4>
                      <Grid container>
                        {fetchInstructionLayout(
                          fieldNames.productInsertRuleSetupInstructionArrayName
                            .name,
                          fieldNames
                            .productInsertRuleValidationInstructionArrayName
                            .name,
                          fieldNames.productInsertRuleLookupInstructionArrayName
                            .name,
                          j,
                          i.OjKey,
                          bConfig
                        )}
                      </Grid>
                    </>
                  ) : null}
                </div>
              </TabPanel>
            )
          })}
        </>
      ) : null}
    </Box>
  )
}

export default ProductMode
