import {
  Grid,
  Button,
  Divider,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  Alert,
  AlertTitle,
  TextField,
  Autocomplete
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import React, { useCallback, useEffect, useState } from "react"
import { PATH } from "src/constants/paths"
import { useHistory, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import GlobalTableDragDrop from "src/components/GlobalTable/GlobalTableDragDrop"
import { useSelector } from "react-redux"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { Loader } from "src/utils/reusables/reusable"
import editIcon from "src/assets/images/Edit.png"
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined"
import trashIcon from "src/assets/images/TrashIcon.svg"
import AuditsToolBar from "src/components/ConfigurationPortal/AuditsToolBar/AuditsToolBar"
import ReadOnlyLayout from "../../pages/LookupRoutines/ReadOnlyLayout/ReadOnlyLayout"
import { CONFIG_MODULES } from "src/utils/constants"
import {
  LookupEditorToConfig,
  LookupConfigToEditor,
  makeLayoutAllVariableEmpty
} from "../LookupRoutines/helpers"
import { Audit_TypesObj, BreadCrumb_Fields } from "src/constants/audits"
import Warning from "@mui/icons-material/Warning"
import CheckIcon from "@mui/icons-material/Check"
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog"
import {
  setNewRandomAuditRule,
  setNewTargetLocationAuditRule,
  setNewTargetRecordAuditRule,
  setNewFollowUpAuditRule,
  setAudits
} from "src/redux/slices/configSlice"
import {
  getBreadcrumbPath,
  setAuditFormData,
  generateRuleName,
  generatePercentageValues,
  formatInteger,
  percentageValueConversion,
  onAutoCompleteFormChange,
  auditRuleTypes
} from "./AuditHelper"
import { updateWarnOnTabChangeToStore } from "../helper"
import { Translates } from "src/i18n/i18n"
import {
  getConfigurationDecryptAndSetRedux,
  getTranslations
} from "src/utils/helper"
import { TableComponentNames } from "src/constants/globalTableDragDrop"
import AlertConfirmationContent from "../Components/AlertConfirmationContent"
import { LocationsList } from "../CountFields/Constants"
interface IAudits {
  percentThreshold: any
  reqAuditsPercent: any
  reqAuditsPerCounter: any
  controlledradiobuttonsgroup: string
  Instructions: any
}
let isInitialDataFromLocationState = true
const Audits = ({ liftAuditSaveClick }) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const dispatch = useAppThunkDispatch()
  const [renderData, setRenderData] = useState([] as any)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [isCFConfirmOpen, setIsCFConfirmOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )

  const auditData = useSelector((state: RootState) => state.config.Audits)
  const [exclueAccuracychecked, setExcludeAccuracyChecked] = useState(false)
  const configSliceData = useSelector((state: RootState) => state.config)
  let targetLocationauditRuleData = useSelector(
    (state: RootState) => state.config.newTargetLocationAuditRule
  )
  let targetRecordauditRuleData = useSelector(
    (state: RootState) => state.config.newTargetRecordAuditRule
  )
  let followUpauditRuleData = useSelector(
    (state: RootState) => state.config.newFollowUpAuditRule
  )
  let randomauditRuleData = useSelector(
    (state: RootState) => state.config.newRandomAuditRule
  )

  const configDetails = useSelector(
    (state: RootState) => state.config.configDetails
  )

  const [preDeleteRow, setPreDeleteRow] = useState<any>()
  const location: any = useLocation()
  const { bConfig } = getBreadcrumbPath(
    getTranslations(t, BreadCrumb_Fields.AUDITS_COMPONENT),
    getTranslations(t, BreadCrumb_Fields.AUDIT_TITLE),
    PATH.AUDITS.slice(1)
  )
  let locationState: any = { ...location.state }
  let data = locationState?.data
    ? JSON.parse(locationState?.data)
    : locationState?.data
  const [form, setForm] = React.useState<IAudits>({
    percentThreshold: "",
    reqAuditsPercent: "",
    reqAuditsPerCounter: "",
    controlledradiobuttonsgroup: "DETAIL",
    Instructions: []
  } as IAudits)
  let search = window.location.search || ""

  // render the cell data
  const displaySpan = (props: any) => {
    return (
      <span className="d-block text-left">
        {props.value ? props.value : "-"}
      </span>
    )
  }
  useEffect(() => {
    if (auditData && Object.keys(auditData).length) {
      setLoading(true)
      setAuditData()
      setLoading(false)
      isInitialDataFromLocationState = false
    }
  }, [auditData])
  useEffect(() => {
    window.onbeforeunload = () => true
    makeLayoutAllVariableEmpty()
    isInitialDataFromLocationState = true
    if (data) {
      setAuditData()
      isInitialDataFromLocationState = false
    }
  }, [])

  useEffect(() => {
    setGridData()
  }, [
    targetLocationauditRuleData,
    targetRecordauditRuleData,
    randomauditRuleData,
    followUpauditRuleData
  ])

  const setGridData = () => {
    const overallArr = [
      ...targetLocationauditRuleData,
      ...targetRecordauditRuleData,
      ...randomauditRuleData,
      ...followUpauditRuleData
    ]
    let dt = overallArr
      .map(i => ({
        ...i,
        ...(!i.SortOrder && { SortOrder: overallArr.length + 1 })
      }))
      .sort((a, b) => a.SortOrder - b.SortOrder)
      .map((i1, j1) => ({
        ...i1,
        SortOrder: j1 + 1
      }))

    if (JSON.stringify(dt) !== JSON.stringify(renderData)) {
      const random = dt.filter(i => i.AuditType === Audit_TypesObj.Random)
      const followup = dt.filter(i => i.AuditType === Audit_TypesObj.FollowUp)

      const targetedRecord = dt.filter(
        i => i.AuditType === Audit_TypesObj.TargetedRecord
      )
      const targetedLocation = dt.filter(
        i => i.AuditType === Audit_TypesObj.TargetedLocation
      )

      dispatch(setNewRandomAuditRule([...random]))
      dispatch(setNewFollowUpAuditRule([...followup]))
      dispatch(setNewTargetRecordAuditRule([...targetedRecord]))
      dispatch(setNewTargetLocationAuditRule([...targetedLocation]))

      setRenderData(dt)
    }
  }

  // copy the Audit Rule row
  const copyAuditRule = (row: any) => {
    if (row.AuditType === Audit_TypesObj.FollowUp) {
      const _item = {
        ...row,
        ...{
          Reason: generateRuleName(followUpauditRuleData, row),
          SortOrder: undefined
        }
      }
      dispatch(setNewFollowUpAuditRule([...followUpauditRuleData, _item]))
    } else if (row.AuditType === Audit_TypesObj.Random) {
      const _item = {
        ...row,
        ...{
          Reason: generateRuleName(randomauditRuleData, row),
          SortOrder: undefined
        }
      }
      dispatch(setNewRandomAuditRule([...randomauditRuleData, _item]))
    } else if (row.AuditType === Audit_TypesObj.TargetedLocation) {
      const _item = {
        ...row,
        ...{
          Reason: generateRuleName(targetLocationauditRuleData, row),
          SortOrder: undefined
        }
      }
      dispatch(
        setNewTargetLocationAuditRule([...targetLocationauditRuleData, _item])
      )
    } else if (row.AuditType === Audit_TypesObj.TargetedRecord) {
      const _item = {
        ...row,
        ...{
          Reason: generateRuleName(targetRecordauditRuleData, row),
          SortOrder: undefined
        }
      }
      dispatch(
        setNewTargetRecordAuditRule([...targetRecordauditRuleData, _item])
      )
    }
  }

  // Edit the Audit Rule
  const EditAuditRule = (data: any) => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CREATE_AUDIT + search, {
        isEdit: true,
        ruledata: { ...data }
      })
    })
  }

  // Delete the Audit Rule
  const deleteAuditRule = (row: any) => {
    setIsConfirmOpen(true)
    setPreDeleteRow(row)
  }
  // set the data for the confirmation dialouge
  const setContent = () => {
    return (
      <Alert severity="error" icon={<Warning />} className="custom-alert">
        <AlertTitle className="alert__title">
          <h2>{getTranslations(t, Translates.Warning)}</h2>
        </AlertTitle>
        <p>
          {" "}
          <strong className="d-block">{`${getTranslations(
            t,
            Translates.Are_you_sure_you_want_to_delete_the
          )} ${preDeleteRow?.Reason}   ${getTranslations(
            t,
            Translates.Audits
          )}?`}</strong>
        </p>
      </Alert>
    )
  }

  // return the row which needs to be deleted
  const callbackConfirmation = (res: boolean) => {
    if (res) {
      let auditType = preDeleteRow?.AuditType

      if (auditType == Audit_TypesObj.FollowUp) {
        followUpauditRuleData = followUpauditRuleData.filter(
          f => f.Reason !== preDeleteRow?.Reason
        )
        dispatch(setNewFollowUpAuditRule([...followUpauditRuleData]))
      } else if (auditType == Audit_TypesObj.Random) {
        randomauditRuleData = randomauditRuleData.filter(
          f => f.Reason !== preDeleteRow?.Reason
        )
        dispatch(setNewRandomAuditRule([...randomauditRuleData]))
      } else if (auditType == Audit_TypesObj.TargetedLocation) {
        targetLocationauditRuleData = targetLocationauditRuleData.filter(
          f => f.Reason !== preDeleteRow?.Reason
        )
        dispatch(
          setNewTargetLocationAuditRule([...targetLocationauditRuleData])
        )
      } else if (auditType == Audit_TypesObj.TargetedRecord) {
        targetRecordauditRuleData = targetRecordauditRuleData.filter(
          f => f.Reason !== preDeleteRow?.Reason
        )
        dispatch(setNewTargetRecordAuditRule([...targetRecordauditRuleData]))
      }
    }
    setIsConfirmOpen(false)
  }

  // set the Audit data on page load
  const setAuditData = () => {
    if (Object.keys(auditData).length || data != undefined) {
      const Instructions =
        data?.Instructions && isInitialDataFromLocationState
          ? data?.Instructions
          : LookupConfigToEditor(auditData["AuditTypeRule"]?.Condition, true)

      const _editAuditItem = {
        percentThreshold:
          data?.percentThreshold && isInitialDataFromLocationState
            ? data?.percentThreshold
            : percentageValueConversion(auditData["PercentThreshold"]),
        reqAuditsPercent:
          data?.reqAuditsPercent && isInitialDataFromLocationState
            ? data?.reqAuditsPercent
            : percentageValueConversion(auditData["RequiredAuditPercentage"]),
        reqAuditsPerCounter:
          data?.reqAuditsPerCounter && isInitialDataFromLocationState
            ? data?.reqAuditsPerCounter
            : auditData["RequiredAuditsPerCounter"],
        checkDetailAudits:
          data?.checkDetailAudits && isInitialDataFromLocationState
            ? data?.checkDetailAudits
            : auditData["CheckDefaultAuditTypes"],
        checkRescanAudits:
          data?.checkRescanAudits && isInitialDataFromLocationState
            ? data?.checkRescanAudits
            : auditData["CheckRescanAuditTypes"],
        controlledradiobuttonsgroup:
          data?.controlledradiobuttonsgroup && isInitialDataFromLocationState
            ? data?.controlledradiobuttonsgroup
            : auditData["AuditTypeRule"]["Type"],
        Instructions: Instructions
      }

      setForm(_editAuditItem)
    }
  }

  // Save the Audit data
  // istanbul ignore next
  const AuditsSave = () => {
    if (
      form.controlledradiobuttonsgroup === auditRuleTypes.RESCAN &&
      configDetails.configuration
    ) {
      const dt = getConfigurationDecryptAndSetRedux(configSliceData)
      if (
        Object.keys(dt.Fields).some(key =>
          [
            LocationsList.SecondaryLocation1.OjKey,
            LocationsList.SecondaryLocation2.OjKey,
            LocationsList.SecondaryLocation3.OjKey
          ].includes(key)
        )
      ) {
        setIsCFConfirmOpen(true)
        return false
      }
    }
    window.onbeforeunload = null
    // collect the form data
    const locCondition = data?.Instructions?.length
      ? LookupEditorToConfig(data?.Instructions)
      : LookupEditorToConfig(form.Instructions)

    // // generate the Accuracy object update
    let excludedAuditRules = [
      ...generateExcludeAuditRules(targetLocationauditRuleData),
      ...generateExcludeAuditRules(targetRecordauditRuleData),
      ...generateExcludeAuditRules(followUpauditRuleData),
      ...generateExcludeAuditRules(randomauditRuleData)
    ]

    const _AuditItem = {
      PercentThreshold: form?.percentThreshold
        ? parseFloat(formatInteger(form?.percentThreshold).toString())
        : 0,
      RequiredAuditPercentage: form?.reqAuditsPercent
        ? parseFloat(formatInteger(form?.reqAuditsPercent).toString())
        : 0,
      RequiredAuditsPerCounter: form?.reqAuditsPerCounter
        ? form?.reqAuditsPerCounter
        : 0,
      EnableCondition: form.controlledradiobuttonsgroup,
      AuditTypeRule: {
        Type: form.controlledradiobuttonsgroup,
        Condition: locCondition.length ? locCondition[0].Condition : []
      },
      ExcludedAuditRules: excludedAuditRules,
      LocationSystemAudits: targetLocationauditRuleData,
      RecordSystemAudits: targetRecordauditRuleData,
      FollowupSystemAudits: followUpauditRuleData,
      RandomSystemAudits: randomauditRuleData
    }
    // dispatch the object to Redux
    dispatch(setAudits(_AuditItem))
    liftAuditSaveClick(_AuditItem, "AuditsTab")
    return true
  }

  const handleExcludeAuuracyCheckChange = (rowValue: any) => {
    // toggle the check
    setExcludeAccuracyChecked(!exclueAccuracychecked)

    if (rowValue.AuditType == Audit_TypesObj.FollowUp) {
      let locallArr = [...followUpauditRuleData]
      const objIndex = followUpauditRuleData.findIndex(
        f =>
          f.SortOrder === rowValue.SortOrder &&
          f.Reason === rowValue.Reason &&
          f.AuditType === rowValue.AuditType
      )
      const _item = {
        ...rowValue,
        ...{
          AccuracyCalculations: !rowValue.AccuracyCalculations
        }
      }
      locallArr[objIndex] = _item

      dispatch(setNewFollowUpAuditRule([...locallArr]))
    } else if (rowValue.AuditType == Audit_TypesObj.Random) {
      let locallArr = [...randomauditRuleData]
      const objIndex = randomauditRuleData.findIndex(
        f =>
          f.SortOrder === rowValue.SortOrder &&
          f.Reason === rowValue.Reason &&
          f.AuditType === rowValue.AuditType
      )
      const _item = {
        ...rowValue,
        ...{
          AccuracyCalculations: !rowValue.AccuracyCalculations
        }
      }
      locallArr[objIndex] = _item

      dispatch(setNewRandomAuditRule([...locallArr]))
    } else if (rowValue.AuditType == Audit_TypesObj.TargetedLocation) {
      let locallArr = [...targetLocationauditRuleData]
      const objIndex = targetLocationauditRuleData.findIndex(
        f =>
          f.SortOrder === rowValue.SortOrder &&
          f.Reason === rowValue.Reason &&
          f.AuditType === rowValue.AuditType
      )
      const _item = {
        ...rowValue,
        ...{
          AccuracyCalculations: !rowValue.AccuracyCalculations
        }
      }
      locallArr[objIndex] = _item
      dispatch(setNewTargetLocationAuditRule([...locallArr]))
    } else if (rowValue.AuditType == Audit_TypesObj.TargetedRecord) {
      let locallArr = [...targetRecordauditRuleData]
      const objIndex = targetRecordauditRuleData.findIndex(
        f =>
          f.SortOrder === rowValue.SortOrder &&
          f.Reason === rowValue.Reason &&
          f.AuditType === rowValue.AuditType
      )
      const _item = {
        ...rowValue,
        ...{
          AccuracyCalculations: !rowValue.AccuracyCalculations
        }
      }
      locallArr[objIndex] = _item

      dispatch(setNewTargetRecordAuditRule([...locallArr]))
    }
  }

  const generateExcludeAuditRules = rulesObj => {
    let filteredRulesObj = rulesObj
      .filter(f => !f.AccuracyCalculations)
      .map(({ Reason }) => Reason)

    return filteredRulesObj
  }

  // prepare the column data
  const columnsData = React.useMemo(
    () => [
      {
        Header: getTranslations(t, Translates.Sort),
        accessor: "id", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 60,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: getTranslations(t, Translates.Reason),
        accessor: "Reason", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 560,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: getTranslations(t, Translates.Impact_Accuracy),
        accessor: "AccuracyCalculations", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 180,
        Cell: props => {
          return (
            <Checkbox
              data-testid="excludeAccuracy"
              checked={props.value}
              name="english"
              className="custom-check light"
              onChange={e =>
                handleExcludeAuuracyCheckChange(props.row.original)
              }
              checkedIcon={<CheckIcon />}
            />
          )
        }
      },
      {
        Header: getTranslations(t, Translates.Audit_Type),
        accessor: "AuditType", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 220,
        Cell: props => {
          return displaySpan(props)
        }
      },
      {
        Header: getTranslations(t, Translates.ACTIONS),
        accessor: "action", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: props => {
          return (
            <>
              <Tooltip arrow title={getTranslations(t, Translates.Copy)}>
                <IconButton
                  data-testid="copyIcon"
                  onClick={() => copyAuditRule(props.row.original)}
                  disabled={configTypeFromStore === "view" ? true : false}
                >
                  <span className="action-btns text-center d-block ">
                    <ContentCopyOutlinedIcon
                      className={`copy-icon ${
                        configTypeFromStore === "view"
                          ? "disable-img"
                          : "pointer"
                      }`}
                    />
                  </span>
                </IconButton>
              </Tooltip>
              <Tooltip arrow title={getTranslations(t, Translates.Edit)}>
                <IconButton
                  data-testid="editIcon"
                  onClick={() => EditAuditRule(props.row.original)}
                >
                  <span className="action-btns text-center d-block ">
                    <img src={editIcon} alt={"edit icon"} />
                  </span>
                </IconButton>
              </Tooltip>
              <Tooltip
                arrow
                title={getTranslations(t, Translates.Delete)}
                className={`${
                  configTypeFromStore === "view" ? "disable-img" : "pointer"
                }`}
              >
                <span>
                  <IconButton
                    data-testid="deleteIcon"
                    onClick={() => deleteAuditRule(props.row.original)}
                    className={`${
                      configTypeFromStore === "view" ? "disable-img" : "pointer"
                    }`}
                    disabled={configTypeFromStore === "view" ? true : false}
                  >
                    <span className="action-btns text-center d-block ">
                      <img
                        src={trashIcon}
                        alt="delete"
                        className={`${
                          configTypeFromStore === "view"
                            ? "disable-img"
                            : "pointer"
                        }`}
                      />
                    </span>
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )
        }
      }
    ],
    [renderData, t]
  )

  // form change event
  // istanbul ignore next
  const onFormChange = e => {
    /**
     e --> Event
     setForm --> Set Form Method
     form --> Form object
     setErrorMessage --> Error message for duplicate rule name, applicable only to new audit rule. "" for Audit 
     setThresholdPercentageErrorMsg --> set the error message of Audit thershold percentage, applicable to Audit
     setAuditPercentageErrorMsg --> set the error message of Required Audit percentage, applicable to Audit
     setTargetLocationPercentageErrorMsg ---> set the error message of Target Tag percentage, applicable to Audit New Rule
     targetpRecordercentageErrorMsg ---> set the error message of Target Item percentage, applicable to Audit New Rule
     randomAuditpercentageErrorMsg ---> set the error message of Random Audit percentage, applicable to Audit New Rule
     followuppercentageErrorMsg ---> set the error message of followup Audit percentage, applicable to Audit New Rule
     followupAccuracypercentageErrorMsg ---> set the error message of followup Audit accuracy percentage, applicable to Audit New Rule
     */
    updateWarnOnTabChangeToStore(true)
    setAuditFormData(e, setForm, form, "", "", "", "")
  }

  // Redirect users to Audit Rule page
  const handleNewAuditRuleClick = () => {
    // collect the form data
    const locCondition = data?.Instructions?.length
      ? LookupEditorToConfig(data?.Instructions)
      : LookupEditorToConfig(form.Instructions)
    // // generate the Accuracy object update
    let excludedAuditRules = [
      ...generateExcludeAuditRules(targetLocationauditRuleData),
      ...generateExcludeAuditRules(targetRecordauditRuleData),
      ...generateExcludeAuditRules(followUpauditRuleData),
      ...generateExcludeAuditRules(randomauditRuleData)
    ]

    const _AuditItem = {
      PercentThreshold: form?.percentThreshold
        ? parseFloat(formatInteger(form?.percentThreshold).toString())
        : 0,
      RequiredAuditPercentage: form?.reqAuditsPercent
        ? parseFloat(formatInteger(form?.reqAuditsPercent).toString())
        : 0,
      RequiredAuditsPerCounter: form?.reqAuditsPerCounter
        ? form?.reqAuditsPerCounter
        : 0,
      EnableCondition: form.controlledradiobuttonsgroup,
      AuditTypeRule: {
        Type: form.controlledradiobuttonsgroup,
        Condition: locCondition.length ? locCondition[0].Condition : []
      },
      ExcludedAuditRules: excludedAuditRules,
      TargetLocationAudits: targetLocationauditRuleData,
      TargetRecordAudits: targetRecordauditRuleData,
      FollowUpAudits: followUpauditRuleData,
      RandomSystemAudits: randomauditRuleData
    }

    // dispatch the object to Redux
    dispatch(setAudits(_AuditItem))
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CREATE_AUDIT + search)
    })
  }

  const onPercentageChange = (event, values) => {
    let targetID = event?.target?.id?.split("-")[0]
    updateWarnOnTabChangeToStore(true)
    onAutoCompleteFormChange(setForm, form, targetID, values)
  }
  // Display the grid with the required columns
  const displayGrid = () => {
    return (
      <Grid container className="custom-table-responsive">
        <Grid xs={12} item>
          <GlobalTableDragDrop
            component={TableComponentNames.newauditrule}
            columns={columnsData}
            data={renderData || []}
            noDataText={
              renderData?.length && renderData.length !== 0
                ? Loader()
                : `${getTranslations(t, Translates.No_Audit_data_available)}.`
            }
          ></GlobalTableDragDrop>
        </Grid>
      </Grid>
    )
  }

  const setModalCFContent = useCallback(() => {
    const content = `${getTranslations(
      t,
      Translates.Secondary_location_added_warning
    )}.`
    return <AlertConfirmationContent warningMsg={content} />
  }, [])

  const callbackCFConfirmation = useCallback(() => {
    setIsCFConfirmOpen(false)
  }, [])
  return (
    <>
      <AuditsToolBar AuditsSave={AuditsSave} />
      <Grid container className="main-container">
        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <label className="form-control__label mb-3">
                {getTranslations(t, Translates.Default_Audit)}{" "}
              </label>
              <FormControl
                className="custom-form-control select no-border multi-select"
                variant="filled"
              >
                <Autocomplete
                  id="percentThreshold"
                  className="auto-complete-full"
                  fullWidth
                  disablePortal={true}
                  options={generatePercentageValues()}
                  value={form?.percentThreshold}
                  onChange={onPercentageChange}
                  getOptionLabel={(option: any) => option}
                  renderInput={params => (
                    <TextField
                      {...params}
                      value={form?.percentThreshold || ""}
                      onChange={e => onFormChange(e)}
                      name="percentThreshold"
                      label={getTranslations(
                        t,
                        Translates.Percentage_Threshold
                      )}
                    />
                  )}
                />
              </FormControl>

              <FormControl
                className="custom-form-control select no-border multi-select"
                variant="filled"
              >
                <Autocomplete
                  id="reqAuditsPercent"
                  className="auto-complete-full"
                  fullWidth
                  disablePortal={true}
                  options={generatePercentageValues()}
                  value={form?.reqAuditsPercent}
                  onChange={onPercentageChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      value={form?.reqAuditsPercent || ""}
                      onChange={e => onFormChange(e)}
                      name="reqAuditsPercent"
                      label={getTranslations(
                        t,
                        Translates.Required_Audit_Percentage
                      )}
                    />
                  )}
                />
              </FormControl>
              <TextField
                fullWidth
                label={getTranslations(
                  t,
                  Translates.Required_Audits_Per_Counter
                )}
                variant="filled"
                autoSave="false"
                name="reqAuditsPerCounter"
                value={form?.reqAuditsPerCounter || ""}
                autoComplete="off"
                className="custom-form-control"
                onChange={e => onFormChange(e)}
              />
            </Grid>
          </Grid>
          <Divider className="dashed mt-3 mb-4" />
          <Grid container>
            <Grid item xs={12} sm={6}>
              <label className="form-control__label mb-2 mt-4 d-block">
                {getTranslations(t, Translates.AUDIT_TYPE)}
              </label>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlledradiobuttonsgroup"
                  onChange={e => onFormChange(e)}
                  value={form?.controlledradiobuttonsgroup}
                >
                  <FormControlLabel
                    value={auditRuleTypes.DETAIL}
                    control={<Radio />}
                    label={getTranslations(t, Translates.Detail_Audit)}
                    className="mr-5"

                    //onChange={e => onFormChange(e)}
                  />
                  <FormControlLabel
                    value={auditRuleTypes.RESCAN}
                    control={<Radio />}
                    label={getTranslations(t, Translates.Rescan_Audit)}
                    className="mr-5"
                    //onChange={e => onFormChange(e)}
                  />
                </RadioGroup>
              </FormControl>{" "}
              <Grid item xs={12} className="mt-5 sqlEditor-edit">
                <h4 className="inner-view__title">
                  {getTranslations(t, Translates.Conditions)}
                </h4>
                <div className="gray-bg pa-2">
                  <ReadOnlyLayout
                    form={{
                      ...form,
                      Name: form?.percentThreshold
                    }}
                    index={-1}
                    type={locationState.type ?? undefined}
                    component={CONFIG_MODULES.Audits}
                    bConfig={bConfig}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className="dashed my-4" />
          <Grid container className="align-items-center mb-2">
            <Grid item xs={12} sm={8}>
              <label className="form-control__label mb-0">
                {getTranslations(t, Translates.Audit_rules)}
              </label>
            </Grid>
            <Grid item xs={12} sm={4} className="text-right">
              <Button
                variant="contained"
                onClick={handleNewAuditRuleClick}
                data-testid="New Audit Rules"
                disabled={configTypeFromStore === "view" ? true : false}
                className={`primary-btn ${
                  configTypeFromStore === "view" ? "disable-img" : "pointer"
                }`}
              >
                <AddIcon className="mr-1" />{" "}
                {getTranslations(t, Translates.New_Audit_Rules)}
              </Button>
            </Grid>
          </Grid>
          {loading ? Loader() : displayGrid()}
        </Grid>
      </Grid>
      {isConfirmOpen && (
        <ConfirmDialog
          open={isConfirmOpen}
          callBack={callbackConfirmation}
          title={getTranslations(t, Translates.DELETE)}
          content={setContent}
          custref={{}}
          confirmButtonText={getTranslations(t, Translates.CONFIRM)}
          cancelButtonText={getTranslations(t, Translates.Cancel)}
        />
      )}
      {isCFConfirmOpen && (
        <ConfirmDialog
          open={isCFConfirmOpen}
          callBack={callbackCFConfirmation}
          title={getTranslations(t, Translates.Condition)}
          content={setModalCFContent}
          custref={{}}
          confirmButtonText={getTranslations(t, Translates.Ok)}
          cancelButtonText={getTranslations(t, Translates.Cancel)}
        />
      )}
    </>
  )
}

export default Audits
