import React, { useEffect, useState } from "react"
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material"
import { useHistory, useLocation } from "react-router"
import BreadCrumb from "src/components/BreadCrumb/BreadCrumb"
import GlobalTable from "src/components/GlobalTable/GlobalTable"
import MainLayout from "src/layouts/MainLayout"
import {
  decryptUrlParams,
  encryptUrlParams,
  getTranslations
} from "src/utils/helper"
import { Loader } from "src/utils/reusables/reusable"
import editIcon from "../../assets/images/Edit.png"
import TrashIcon from "src/assets/images/TrashIcon.svg"
import AddIcon from "@mui/icons-material/Add"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import { UploadFileService } from "src/service/uploadfile.service"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import { AiFillWarning } from "react-icons/ai"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import WarningIcon from "@mui/icons-material/Warning"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import ExcelIcon from "../../assets/images/ExcelIcon.svg"
import { RootState } from "src/redux/store"
import {
  setDepartmentData,
  setVarianceData
} from "src/redux/slices/uploadFileSlice"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import TimeAgo from "src/components/TimeAgo/TimeAgo"

const EditUploadFile = () => {
  // @ts-ignore
  const { t } = useTranslation<any>()
  const location: any = useLocation()
  const [file, setFile] = useState("")
  const [fileId, setFileId] = React.useState(null)
  const [fileType, setFileType] = React.useState(null)
  const [fileData, setFileData] = useState([] as any)
  const [uploadData, setUploadData] = useState([] as any)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [deletedItem, setDeletedItem] = useState([] as any)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [alertType, setAlertType] = useState(false)
  const [alertContent, setAlertContent] = useState("")
  const [alertVisible, setAlertVisible] = useState(false)
  const [deptFile, setDeptFile] = useState(false)
  const [downloadingFile, setDownloadingFile] = useState([] as any)
  const [dataFromLocationState, setDataFromLocationState] = useState({} as any)
  const uploadService = new UploadFileService()
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )

  const dispatch = useDispatch()
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setLoading(true)
      init()
    }
    return () => {
      isMounted = false
    }
  }, [])
  const init = () => {
    let pathSplit: any = location?.pathname?.split("/")
    let fileName = location.state?.Data?.name
    let data = location.state?.Data
    let selectedFileType = location.state?.Data?.selectedFileType
    if (selectedFileType === "Department") {
      setDeptFile(true)
    }
    setDataFromLocationState(data)
    let fileIdVal = decryptUrlParams(pathSplit[pathSplit.length - 1])
    setFile(fileName)
    setFileId(fileIdVal)
    setFileType(data?.idFileType)
    getFileDetails(fileIdVal, data?.idFileType)
  }
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setLoading(true)
      init()
    }
    return () => {
      isMounted = false
    }
  }, [customerId])
  useEffect(() => {
    if (fileId && fileType) {
      downloadBulkFile()
    }
  }, [fileType])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getFileDetails = async (fileIdVal, fileType) => {
    try {
      const res = await uploadService?.getUploadSingleFileDetails(
        fileIdVal,
        fileType
      )
      if (res && res.status === 200) {
        setLoading(false)
        if (
          res?.data?.uploadFileData?.VarianceControlFile &&
          res?.data?.uploadFileData?.VarianceControlFile.length > 0
        ) {
          setDeptFile(false)
          setUploadData(res?.data)
          setFileData(res?.data?.uploadFileData?.VarianceControlFile)
          dispatch(
            setVarianceData(res?.data?.uploadFileData?.VarianceControlFile)
          )
          setLoading(false)
        } else if (
          res?.data?.uploadFileData?.DepartmentFile &&
          res?.data?.uploadFileData?.DepartmentFile?.length > 0
        ) {
          setDeptFile(true)
          setUploadData(res?.data)
          setFileData(res?.data?.uploadFileData?.DepartmentFile)
          dispatch(setDepartmentData(res?.data?.uploadFileData?.DepartmentFile))
          setLoading(false)
        }
      } else if (res && res?.status === 204) {
        setFileData([])
        setLoading(false)
      }
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  const downloadFile = async () => {
    let actualUrl = await downloadingFile
    window.location.href = actualUrl
  }
  const downloadBulkFile = () => {
    const configId = fileId
    const idFileType = fileType
    uploadService?.downloadAuxFile(configId, idFileType).then((temp: any) => {
      setDownloadingFile(temp.data)
    })
  }
  const getBreadcrumbConfig = () => {
    let fileName = location.state?.Data?.name
    let currentPath: any
    let variables: any = {}
    currentPath = `uploadFiles/${fileName}`
    variables["uploadFiles"] = getTranslations(t, Translates.Upload_Files)
    variables[`${fileName}`] = `${getTranslations(t, Translates.Edit)} ${fileName}`
    let bConfig: any = {
      currentPath,
      mapObj: variables
    }
    return bConfig
  }
  const displaySpan = (props: any) => {
    return (
      <span className="d-block text-left">
        {props.value ? props.value : "-"}
      </span>
    )
  }
  const handleEditClick = row => {
    let encryptedId: any = encryptUrlParams(fileId)
    history.push(`/uploadFiles/editFile/${encryptedId}/createEditData`, {
      Data: row,
      id: 10,
      fileType: deptFile,
      dataFromState: dataFromLocationState
    })
  }
  const handleDeleteClick = dt => {
    setDeletedItem(dt)
    setIsConfirmOpen(true)
  }
  const setContent = () => {
    return (
      <Alert severity="error" icon={<AiFillWarning />} className="custom-alert">
        <AlertTitle className="custom-alert__title">
          {getTranslations(t, Translates.Cancel)}
        </AlertTitle>
        <p>
          {getTranslations(t, Translates.Are_sure_you_want_to_delete_file_data)}{" "}
          ?
        </p>
      </Alert>
    )
  }
  const callbackConfirmation = (res: boolean) => {
    if (res) {
      DeleteData().then(() => {
        setIsConfirmOpen(false)
      })
    } else {
      setIsConfirmOpen(false)
    }
  }
  const DeleteData = async () => {
    setLoading(true)
    try {
      if (deptFile) {
        await uploadService
          ?.deleteDepartmentData(
            customerId,
            deletedItem.validationControlId,
            deletedItem.validationDepartmentId
          )
          .then((temp: any) => {
            if (temp && temp.status == 200) {
              setAlertType(true)
              setAlertContent(
                getTranslations(t, Translates.File_data_deleted_successfully)
              )
              setAlertVisible(true)
              init()
              setTimeout(() => {
                setAlertVisible(false)
              }, 1000)
            }
            setLoading(false)
          })
      } else {
        await uploadService
          ?.deleteVarianceControlData(
            customerId,
            deletedItem.validationControlId,
            deletedItem.varianceControlId
          )
          .then((temp: any) => {
            if (temp && temp.status == 200) {
              setAlertType(true)
              setAlertContent(
                getTranslations(t, Translates.File_data_deleted_successfully)
              )
              setAlertVisible(true)
              init()
              setTimeout(() => {
                setAlertVisible(false)
              }, 1000)
            }
            setLoading(false)
          })
      }
    } catch (_error) {
      console.error(_error)
      setLoading(false)
    }
  }
  const commonValues = {
    headerClassName: "temp_class",
    disableFilters: true,
    disableSortBy: false,
    setWidth: 150,
    Cell: props => {
      return displaySpan(props)
    }
  }
  const columnHeaders = [
    {
      Header: getTranslations(t, Translates.DEPARTMENT),
      accessor: "department",
      ...commonValues
    },
    {
      Header: getTranslations(t, Translates.Department_Name),
      accessor: "departmentName",
      ...commonValues
    },
    {
      Header: getTranslations(t, Translates.Department_Sort),
      accessor: "departmentSort",
      ...commonValues
    },
    {
      Header: getTranslations(t, Translates.Department_Parent_Name),
      accessor: "departmentParentName",
      ...commonValues
    },
    {
      Header: getTranslations(t, Translates.Department_Parent_Sort),
      accessor: "departmentParentSort",
      ...commonValues
    },
    {
      Header: getTranslations(t, Translates.Department_Parent),
      accessor: "departmentParent",
      ...commonValues
    },
    {
      Header: getTranslations(t, Translates.VARIANCE_GROUP),
      accessor: "varianceGroup",
      ...commonValues
    },
    {
      Header: getTranslations(t, Translates.VARIANCE_GROUP_NAME),
      accessor: "varianceGroupName",
      ...commonValues
    },
    {
      Header: getTranslations(t, Translates.SORT_ORDER),
      accessor: "sortOrder",
      ...commonValues
    },
    {
      Header: getTranslations(t, Translates.QTY_TOLERANCE),
      accessor: "qtyTolerance",
      ...commonValues
    },
    {
      Header: getTranslations(t, Translates.FULL_STORE_QTY_TOLERANCE),
      accessor: "fullStoreQtyTolerance",
      ...commonValues
    },
    {
      Header: getTranslations(t, Translates.EXT_TOLERANCE),
      accessor: "extTolerance",
      ...commonValues
    },
    {
      Header: getTranslations(t, Translates.FULL_STORE_EXT_TOLERANCE),
      accessor: "fullStoreExtTolerance",
      ...commonValues
    }
  ]
  const getColumnHeaders = () => {
    if (!deptFile) {
      return columnHeaders.splice(6, 7)
    } else {
      return columnHeaders.splice(0, 6)
    }
  }
  const columnsData: Array<{
    Header: any
    accessor: string
    headerClassName: string
    disableFilters: boolean
    disableSortBy: boolean
    setWidth: number
    Cell: (props: any) => JSX.Element
  }> = [
    ...(getColumnHeaders()?.map(id => {
      return {
        Header: id.Header,
        accessor: id.accessor,
        headerClassName: id.headerClassName,
        disableFilters: id.disableFilters,
        disableSortBy: id.disableSortBy,
        setWidth: id.setWidth,
        Cell: id.Cell
      }
    }) ?? []),
    {
      Header: getTranslations(t, Translates.ACTIONS),
      accessor: "action",
      headerClassName: "temp_class",
      disableFilters: true,
      disableSortBy: true,
      setWidth: 10,
      Cell: (props: any) => {
        return (
          <span className="action-btns d-block">
            <Tooltip arrow title={getTranslations(t, Translates.Edit)}>
              <IconButton
                data-testid="Edit-Icon"
                onClick={() => handleEditClick(props.row.original)}
              >
                <img src={editIcon} className="pointer" />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title={getTranslations(t, Translates.Delete)}>
              <IconButton
                data-testid="DeleteIcon"
                onClick={() => handleDeleteClick(props.row.original)}
              >
                <img src={TrashIcon} alt="delete" className="pointer" />
              </IconButton>
            </Tooltip>
          </span>
        )
      }
    }
  ].flat(Infinity)

  const displayGrid = () => {
    return (
      <Grid container className="table-responsive" data-testid="EUploadFGlob">
        <Grid xs={12} item>
          <GlobalTable
            columns={columnsData}
            data={fileData}
            noDataText={
              fileData && fileData?.length > 0
                ? Loader()
                : `No ${file} data available.`
            }
            initialStateProp={{
              sortBy: [
                {
                  id: columnsData?.length ? columnsData[0].Header : "",
                  desc: false
                }
              ]
            }}
          ></GlobalTable>
          {isConfirmOpen && (
            <ConfirmDialog
              open={isConfirmOpen}
              callBack={callbackConfirmation}
              title={`DELETE FILE DATA `}
              content={setContent}
              custref={{}}
              confirmButtonText={getTranslations(t, Translates.CONFIRM)}
              cancelButtonText={getTranslations(t, Translates.Cancel)}
              data-testid="confirmDialog"
            ></ConfirmDialog>
          )}
        </Grid>
      </Grid>
    )
  }
  const handleAddData = () => {
    let encryptedId: any = encryptUrlParams(fileId)
    history.push(`/uploadFiles/editFile/${encryptedId}/createEditData`, {
      fileType: deptFile,
      dataFromState: dataFromLocationState
    })
  }

  const getUpdatedDateTime = () => {
    let lastUpdated = dataFromLocationState?.lastUpdatedDate
    if (lastUpdated && lastUpdated != "-") {
      const date = moment(lastUpdated).format("DD-MMM-YYYY")
      const time = moment(lastUpdated).format("hh:mm A")
      return date.toUpperCase() + " " + time
    } else {
      return "-"
    }
  }
  const renderLastUpdated = () => {
    let lastUpdated = dataFromLocationState?.lastUpdatedDate
    return lastUpdated && lastUpdated != "-" ? (
      <TimeAgo value={lastUpdated} />
    ) : (
      "-"
    )
  }
  return (
    <div>
      <MainLayout>
        {alertVisible ? (
          <Alert
            severity={alertType ? "success" : "error"}
            icon={alertType ? <CheckCircleIcon /> : <WarningIcon />}
            className="custom-alert top"
          >
            <AlertTitle className="custom-alert__title f-600">
              {alertType
                ? getTranslations(t, Translates.Success)
                : getTranslations(t, Translates.Error)}
            </AlertTitle>
            {alertContent}
          </Alert>
        ) : null}
        <div className="main-toolbar">
          <div className="main-container">
            <Grid container>
              <Grid item xs={12} sm={9} className="d-flex align-items-center">
                <div className="custom-breadcrumb CP">
                  <BreadCrumb
                    customPath={"upload files"}
                    breadCrumbInfo={getBreadcrumbConfig()}
                    renderedPage={""}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="main-container">
          <Grid container className="tag-list blue-bg mb-4">
            <Grid item md={12} className="tag-list__cont">
              <div className="tag-list__col">
                <span className="tag-list__col-title">
                  {getTranslations(t, Translates.CONFIGURATION)}
                </span>
                <strong className="tag-list__col-value">
                  {uploadData.configName}
                </strong>
              </div>
              <div className="tag-list__col">
                <span className="tag-list__col-title">
                  {getTranslations(t, Translates.DESCRIPTION)}
                </span>
                <strong className="tag-list__col-value">
                  {uploadData.description}
                </strong>
              </div>
              <div className="tag-list__col">
                <span className="tag-list__col-title">
                  {getTranslations(t, Translates.LAST_UPDATED)}
                </span>
                <Tooltip arrow title={getUpdatedDateTime()}>
                  <strong className="tag-list__col-value ellipsis">
                    {renderLastUpdated()}{" "}
                    {uploadData.updatedBy
                      ? `${getTranslations(t, Translates.BY)} ${
                          uploadData.updatedBy
                        }`
                      : ""}
                  </strong>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
          {!loading ? (
            <>
              <Grid container className="align-items-center">
                <Grid item xs={12} sm={6}>
                  <h4>{file}</h4>
                </Grid>
                <Grid item xs={12} sm={6} className="text-right">
                  <Tooltip
                    arrow
                    title={`${getTranslations(t, Translates.Download_File)}`}
                  >
                    <Button
                      id="basic-button"
                      data-testid="downloadButton"
                      variant="text"
                      className="icon-btn mx-2"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleAnchorClick}
                    >
                      <FileDownloadOutlinedIcon />
                    </Button>
                  </Tooltip>

                  <Menu
                    id="basic-menu"
                    className="menu-dropdown"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button"
                    }}
                  >
                    <div
                      onClick={() => handleClose()}
                      onKeyDown={e => {
                        if (e.key === "Enter" || e.key === "Space") {
                          handleClose()
                        }
                      }}
                      role="button" // Indicate that this div is acting like a button
                      tabIndex={0} // Set tabIndex to a number
                    >
                      <MenuItem onClick={() => downloadFile()}>
                        <img src={ExcelIcon} />{" "}
                        {getTranslations(t, Translates.EXCEL)}{" "}
                      </MenuItem>
                    </div>
                  </Menu>
                  <Button
                    variant="contained"
                    className="primary-btn"
                    data-testid="newRecordBtn"
                    onClick={() => handleAddData()}
                    style={{ display: "inline-block", marginLeft: "8px" }}
                  >
                    <AddIcon data-testid="addNewData" className="mr-1" />{" "}
                    {getTranslations(t, Translates.New_Record)}
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} className="mt-3">
                {displayGrid()}
              </Grid>
            </>
          ) : (
            Loader()
          )}
        </div>
      </MainLayout>
    </div>
  )
}

export default EditUploadFile
