import React from "react"
import Routes from "src/routes/routes"
import { withRouter } from "react-router-dom"
import ScrollToTop from "../components/ScrollToTop/ScrollToTop"
import InactivityLogout from "src/components/InactivityLogout/InactivityLogout"
import { withAITracking } from "@microsoft/applicationinsights-react-js"
import { reactPlugin } from "../AppInsights/AppInsights"
import { updateMoment } from "src/i18n/i18n"
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const App = () => {
  //updating moment with defined locale options
  updateMoment()
  localStorage.setItem("isRefreshTokenInProgress", "false")
  const rumConfig = {
    applicationId: '30324e40-9c3f-4fd2-8218-4559b7ca0f22',
    clientToken: 'pubac4e16e2b48d1b31ec018828ae19aeb7',
    service: 'customerweb',
    site: 'us3.datadoghq.com',
    env: 'devtwo',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    allowedTracingUrls: [(url) => url.startsWith("https://api.flexcount-devtwo.com")],
    traceSampleRate: 100
  };

  datadogRum.init(rumConfig);
  datadogLogs.init({
    clientToken: 'pubac4e16e2b48d1b31ec018828ae19aeb7',
    site: 'us3.datadoghq.com',
    service: 'customerweb',
    env: 'devtwo',
    forwardErrorsToLogs: true,
  });
  const logWithRumContext = (message, context) => {
    const rumContext = datadogRum.getInternalContext();
    const combinedContext = {
      ...context,
      session_id: rumContext?.session_id,
      application_id: rumConfig.applicationId
    };
    datadogLogs.logger.log(message, combinedContext);
  };
  logWithRumContext('User action event', { custom_attribute: 'value' });

  /*datadogRum.setUser({
    id: '343',
    name: 'Rajesh Customerweb',
    email: 'customerweb@wisintl.com'
  })*/

  return (
    <>
      <ScrollToTop />
      {/* istanbul ignore next */}
      <InactivityLogout />
      <Routes />
    </>
  )
}

const AppWithRouter = withRouter(App)
// export default AppWithRouter
export default withAITracking(reactPlugin, AppWithRouter)
