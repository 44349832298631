import { useEffect } from "react"
import { useSelector } from "react-redux"
import {
  prepareDataForLocationList,
  prepareDataForProductList
} from "src/configurationPortal/pages/CountPrograms/Component/TabFunctionsAndConstant"
import { getConfigToFormConversion } from "src/configurationPortal/pages/Variances/Utils/FunctionsHelper"
import { multiEventOutputRouteReplaceVar } from "src/constants/paths"
import { setOutputBundlesRedux } from "src/redux/slices/ConfigPortal/OutputBundleSlice"
import { setReportsRedux } from "src/redux/slices/ConfigPortal/ReportSlice"
import { setVarianceRedux } from "src/redux/slices/ConfigPortal/VarianceSlice"
import {
  setConfigDataHookCalled,
  setCountFields,
  setCountPrograms,
  setDefaultCountFields,
  setGeneralSettings,
  setLookupRoutines,
  setAudits,
  setNewTargetLocationAuditRule,
  setNewTargetRecordAuditRule,
  setNewRandomAuditRule,
  setNewFollowUpAuditRule,
  getConfiguration,
  setLocationCategories,
  setInputFiles,
  setOutputFiles,
  getConfigDetails,
  setReduxCountScreenTotals
} from "src/redux/slices/configSlice"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import {
  decryptUrlParams,
  getConfigurationDecryptAndSetRedux
} from "src/utils/helper"

export const ConfigDataHook = () => {
  const location = window.location
  const urlParams: any = location.search
  let slicedUrlParams = urlParams?.slice(1)
  let decryptedParams: any = decryptUrlParams(slicedUrlParams)
  let replacedString: any = decryptedParams?.replace(/"./g, " ")
  let idString: any = replacedString?.split("&")[1]
  let idNum: any = idString?.split("=")[1]
  const customers = useSelector((state: RootState) => state.settings?.customers)

  const dispatch = useAppThunkDispatch()
  const {
    config,
    varianceData,
    outputBundleData,
    reportData,
    columnReduxData,
    ftpConnectionData,
    multiEventOutputData,
    settings,
    auth
  } = useSelector((state: RootState) => {
    return {
      config: state.config || {},
      customerId: state.settings?.selectedCustomerForOperation,
      varianceData: state.variance || {},
      outputBundleData: state.outputBundle || {},
      reportData: state.report || {},
      columnReduxData: state.column || {},
      ftpConnectionData: state.ftpConnection || {},
      multiEventOutputData: state.multiEventOutput || {},
      settings: state.settings || {},
      auth: state.auth || {}
    }
  })

  const {
    lookupRoutines,
    generalSettings,
    countFields,
    configDataHookCalled,
    countPrograms,
    Audits,
    defaultCountFields,
    newTargetLocationAuditRule,
    newTargetRecordAuditRule,
    newFollowUpAuditRule,
    newRandomAuditRule,
    locationCategories,
    configDetails,
    inputFiles,
    Outputs,
    customerConfigurationsStatus,
    reduxCountScreenTotals
  } = config || {}

  const { varianceRedux } = varianceData
  const { outputBundlesRedux } = outputBundleData
  const { reportsRedux } = reportData

  const selectedCustomerList = customers?.filter((c: any) => c.selected)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    if (
      isMounted &&
      customers?.length &&
      !["success", "loading", "failed"].includes(customerConfigurationsStatus)
    ) {
      const payload = selectedCustomerList?.map(i => i.idCustomer)
      dispatch(getConfigDetails(payload))
    }
    return () => {
      isMounted = false
      controller.abort()
    }
    //eslint-disable-next-line
  }, [customers])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    if (isMounted) {
      if (configDetails && !Object.keys(configDetails).length && idNum) {
        dispatch(getConfiguration(idNum))
      }
      if (
        location?.pathname?.includes(
          multiEventOutputRouteReplaceVar.CreateSchedule
        )
      ) {
        dispatch(setConfigDataHookCalled(true))
      } else {
        dispatch(setConfigDataHookCalled(false))
      }
    }
    return () => {
      isMounted = false
      controller.abort()
    }
    //eslint-disable-next-line
  }, [])
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    if (isMounted) {
      window.onbeforeunload = () => true
      //istanbul ignore next
      if (
        configDetails?.configuration &&
        auth?.keys?.length &&
        !configDataHookCalled
      ) {
        const dt = getConfigurationDecryptAndSetRedux(config)

        if (varianceRedux && !Object.keys(varianceRedux).length) {
          const { reduxData } = getConfigToFormConversion({
            GroupVariance: dt?.GroupedVariances,
            FullVariance: dt?.FullStoreVariances
          })
          dispatch(setVarianceRedux(reduxData))
        }
        if (
          !lookupRoutines ||
          (!lookupRoutines?.length && dt?.LookupRoutines?.length)
        ) {
          dispatch(setLookupRoutines(dt?.LookupRoutines))
        }
        if (
          generalSettings &&
          !Object.keys(generalSettings).length &&
          Object.keys(dt?.GeneralSettings).length
        ) {
          dispatch(setGeneralSettings(dt?.GeneralSettings))
        }
        if (countFields && !Object.keys(countFields).length) {
          let locField = JSON.parse(JSON.stringify(dt?.Fields || {}))
          delete locField?.countScreenTotals
          dispatch(setCountFields(locField))
          dispatch(
            setDefaultCountFields({ data: { ...locField }, type: "UPDATE" })
          )
        }
        if (!reduxCountScreenTotals) {
          const countScreenTotals = dt?.Fields?.countScreenTotals || ""
          dispatch(setReduxCountScreenTotals(countScreenTotals))
        }
        if (!locationCategories?.length) {
          dispatch(setLocationCategories(dt?.LocationCategories))
        }
        if (!inputFiles?.length) {
          dispatch(setInputFiles(dt?.InputFiles))
        }
        if (!reportsRedux?.length) {
          dispatch(setReportsRedux(dt?.Reports))
        }
        if (!Outputs?.length) {
          dispatch(setOutputFiles(dt?.Outputs))
        }
        if (!outputBundlesRedux?.length) {
          dispatch(setOutputBundlesRedux(dt?.OutputBundles))
        }
        if (!countPrograms?.length) {
          dt?.CountPrograms && dispatch(setCountPrograms(dt?.CountPrograms))
          if (dt?.Fields && Object?.keys(dt.Fields).length) {
            prepareDataForProductList(dt?.Fields)
            prepareDataForLocationList(dt?.Fields)
          } else {
            prepareDataForProductList(defaultCountFields)
            prepareDataForLocationList(defaultCountFields)
          }
        }

        if (Audits && !Object.keys(Audits)?.length) {
          dt?.Audits && dispatch(setAudits(dt?.Audits))

          if (!newRandomAuditRule?.length) {
            dt?.Audits?.RandomSystemAudits &&
              dispatch(setNewRandomAuditRule(dt?.Audits?.RandomSystemAudits))
          }
          if (!newTargetRecordAuditRule?.length) {
            dt?.Audits?.RecordSystemAudits &&
              dispatch(
                setNewTargetRecordAuditRule(dt?.Audits?.RecordSystemAudits)
              )
          }
          if (!newTargetLocationAuditRule?.length) {
            dt?.Audits?.LocationSystemAudits &&
              dispatch(
                setNewTargetLocationAuditRule(dt?.Audits?.LocationSystemAudits)
              )
          }
          if (!newFollowUpAuditRule?.length) {
            dt?.Audits?.FollowupSystemAudits &&
              dispatch(
                setNewFollowUpAuditRule(dt?.Audits?.FollowupSystemAudits)
              )
          }
        }
        dispatch(setConfigDataHookCalled(true))
      }
    }
    return () => {
      isMounted = false
      controller.abort()
    }
    //eslint-disable-next-line
  }, [configDetails, configDataHookCalled, auth?.keys])
  return {
    config,
    varianceData,
    reportData,
    columnReduxData,
    outputBundleData,
    ftpConnectionData,
    multiEventOutputData,
    settings
  }
}
