import { useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"
import { Grid } from "@mui/material"
import { Translates } from "src/i18n/i18n"
import { getTranslations, replaceAllMultiple } from "src/utils/helper"
import AceEditorField from "src/components/MUIFormFields/AceEditorField"
import { validationSchema } from "./Utils/Validation"
import {
  SQLPromptsPrepare,
  SQLQueryPrepare,
  displayPromptVariables,
  getDefaultValues,
  getVariableBasedOnComponent,
  headerTitle
} from "./Utils/Helper"
import { sqlEditorFieldNames } from "./Utils/Constant"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import FormSubmitCancel from "./Components/FormSubmitCancel"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { setValueForVariable } from "../../FunctionHelper"
import { ConfigTabNames } from "../../helper"

const EditSQLEditor = () => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  let location = useLocation() as any
  const {
    config: { configDataHookCalled }
  } = ConfigDataHook()

  const { tab, tabId }: { id: string; tab: string; tabId: string } = useParams()
  const storeTabId: number = tabId !== undefined ? Number(tabId) : -1 //want to get index of array means subtract 1
  let search = window.location.search || ""

  let { variableRedux, variablesRedux, redirectUrl } =
    getVariableBasedOnComponent(tab, storeTabId)
  redirectUrl =
    replaceAllMultiple(redirectUrl, [":tab", ":id"], [tab, tabId]) + search
  let stateVariableRedux = location.state?.variableRedux

  const locVariableRedux = setValueForVariable({
    values: [stateVariableRedux, variableRedux, variablesRedux[storeTabId - 1]],
    defaultValue: {}
  })

  const locGetDefaultValues = () => {
    return getDefaultValues(locVariableRedux)
  }

  const formHookMethods = useForm<any>({
    defaultValues: locGetDefaultValues(),
    resolver: yupResolver(validationSchema()),
    criteriaMode: "all",
    mode: "onChange"
  })

  useEffect(() => {
    let isMounted = true
    if (isMounted && configDataHookCalled) {
      let isRedirect = false
      if (
        tab.toLocaleLowerCase() !==
          ConfigTabNames.MultiEventOutputs.toLocaleLowerCase() &&
        ((storeTabId !== -1 &&
          (storeTabId > variablesRedux.length || storeTabId <= 0)) ||
          !locVariableRedux ||
          (locVariableRedux && !Object.keys(locVariableRedux).length))
      ) {
        isRedirect = true
      }

      if (isRedirect) {
        history.push(redirectUrl)
      } else {
        window.onbeforeunload = () => true
        handleQueryChange(locVariableRedux.RawSQL || "")
        if (!watch(sqlEditorFieldNames.RawSQL.name)) {
          reset(locGetDefaultValues())
        }
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [configDataHookCalled])

  const { watch, setValue, reset } = formHookMethods

  const handleQueryChange = sqlQuery => {
    let locSqlQuery = SQLQueryPrepare(sqlQuery)
    setSQLPromptsValue(locSqlQuery)
    setValue(sqlEditorFieldNames.IsValidSQL.name, false)
  }

  const setSQLPromptsValue = sqlQuery => {
    let Prompts = SQLPromptsPrepare(locVariableRedux.Prompts, sqlQuery)
    setValue(sqlEditorFieldNames.Prompts.name, Prompts)
  }

  return (
    <FormProvider {...formHookMethods}>
      <Grid container>
        <MiniHeader headerTitle={headerTitle(tab)} />{" "}
        <Grid container className="inner-view">
          <Grid item xs={12} sm={12} className="main-container">
            <Grid container>
              <Grid item xs={12}>
                <h4>{getTranslations(t, Translates.Variables)}</h4>
                <div className="ml-4">
                  {displayPromptVariables(
                    watch(sqlEditorFieldNames.Prompts.name)
                  )}
                </div>
              </Grid>
              <Grid item xs={12} className="mt-5 sqlEditor-edit">
                <h4 className="inner-view__title">
                  {getTranslations(t, Translates.Edit_SQL)}
                </h4>
                <AceEditorField
                  inputAttributes={{
                    name: sqlEditorFieldNames.RawSQL.name,
                    onChange: handleQueryChange
                  }}
                />
              </Grid>
              <FormSubmitCancel />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default EditSQLEditor
