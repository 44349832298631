import { useReducer } from "react"
import { useFormContext } from "react-hook-form"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { Grid } from "@mui/material"
import {
  makeClearQueryToExecute,
  reducer
} from "src/configurationPortal/pages/helper"
import { useAppThunkDispatch } from "src/redux/store"
import { replaceAllMultiple } from "src/utils/helper"
import { sqlEditorFieldNames } from "../Utils/Constant"
import {
  alertMsgShow,
  emptyAlert,
  onCancelClick,
  setValueForVariable
} from "src/configurationPortal/pages/FunctionHelper"
import { SqlTestEditorService } from "src/service/sqlTestEditor.service"
import { API_RESPONSE } from "src/utils/constants"
import {
  SQLTestColumnSliceType,
  setColumnsRedux
} from "src/redux/slices/ConfigPortal/ColumnSlice"
import { getVariableBasedOnComponent } from "../Utils/Helper"
import FormSubmitCancelButtons from "../../FormSubmitCancelButtons"
let alertVisible: boolean = false
let alertMsg = emptyAlert
let locColumnsRedux = {} as SQLTestColumnSliceType["columnsRedux"]
const FormSubmitCancel = () => {
  const history = useHistory()
  let location = useLocation() as any

  const { tab, tabId }: { id: string; tab: string; tabId: string } = useParams()
  const storeTabId: number = tabId !== undefined ? Number(tabId) : -1 //want to get index of array means subtract 1
  let search = window.location.search || ""

  const [, setReload] = useReducer(reducer, false)

  let { variableSetRedux, variableRedux, variablesRedux, redirectUrl } =
    getVariableBasedOnComponent(tab, storeTabId)
  redirectUrl =
    replaceAllMultiple(redirectUrl, [":tab", ":id"], [tab, tabId]) + search

  let stateVariableRedux = location.state?.variableRedux

  const locVariableRedux = setValueForVariable({
    values: [stateVariableRedux, variableRedux, variablesRedux[storeTabId - 1]],
    defaultValue: {}
  })

  const {
    watch,
    setValue,
    formState: { isValid }
  } = useFormContext()

  const dispatch = useAppThunkDispatch()
  const testSQLService = new SqlTestEditorService()

  const saveEnableCheck = () => {
    let error: boolean = false
    if (!watch(sqlEditorFieldNames.IsValidSQL.name) || !isValid) {
      error = true
    }
    return error
  }

  const handleSaveClick = data => {
    window.onbeforeunload = () => null
    dispatch(
      setColumnsRedux({
        SQL: locColumnsRedux.SQL,
        columns: locColumnsRedux.columns
      })
    )
    dispatch(
      variableSetRedux({
        ...locVariableRedux,
        SQL: locColumnsRedux.SQL,
        RawSQL: watch(sqlEditorFieldNames.RawSQL.name)
      })
    )
    onCancelClick(history, redirectUrl)
  }

  const handleTestClick = async () => {
    locColumnsRedux = {} as SQLTestColumnSliceType["columnsRedux"]
    const queryToBeTested = makeClearQueryToExecute(
      watch(sqlEditorFieldNames.RawSQL.name),
      locVariableRedux.Prompts
    )
    try {
      const res = await testSQLService.postSqlTestEditorValueForVerify(
        queryToBeTested
      )

      if (Number(res.status) === Number(API_RESPONSE.SUCCESS)) {
        alertVisible = true
        alertMsg = {
          ...emptyAlert,
          ...{
            severity: "success",
            title: "Success",
            content: "Query Validation Successful!"
          }
        }
        const queryToBeTested1 = makeClearQueryToExecute(
          watch(sqlEditorFieldNames.RawSQL.name),
          locVariableRedux.Prompts,
          false
        )
        locColumnsRedux = { SQL: queryToBeTested1, columns: res.data || [] }
        setValue(sqlEditorFieldNames.IsValidSQL.name, true)
        hideAlert()
      }
    } catch (err: any) {
      // istanbul ignore next
      return err
    }
    return true
  }

  const hideAlert = () => {
    setTimeout(() => {
      alertVisible = false
      alertMsg = emptyAlert
      setReload(oldVal => !oldVal)
    }, 3000)
  }

  return (
    <>
      <FormSubmitCancelButtons
        handleSaveClick={handleSaveClick}
        handleTestClick={handleTestClick}
        saveEnableCheck={saveEnableCheck}
        redirectUrl={redirectUrl}
      />
      <Grid container>
        {alertVisible ? <>{alertMsgShow(alertMsg)}</> : null}
      </Grid>
    </>
  )
}

export default FormSubmitCancel
