import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import { setNewReportPrompts } from "src/redux/slices/configSlice"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import {
  CONFIG_MODULES,
  DEFAULT_RADIX,
  PromptFieldNames,
  PromptTypes
} from "src/utils/constants"
import {
  getTranslations,
  setWeekFieldError,
  validateDecimal,
  validateInteger,
  validateOnlyInteger
} from "src/utils/helper"
import { updateWarnOnTabChangeToStore } from "../helper"
import CheckIcon from "@mui/icons-material/Check"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import FormHeading from "src/components/FormHeading"
interface INewPrompt {
  Label: string
  VariableName: string
  DefaultValue: string
  Type: string
  NumberOfDecimals: string
  CustomList: string[]
}
interface Props {
  component: string
  location: any
}
const CreateEditPrompts = ({ component, location }: Props) => {
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )
  const { t } = useTranslation<any>()
  const [errorMsg, setErrorMsg] = useState(false)
  const [invalidMsg, setInvalidMsg] = useState(false)
  const history = useHistory()
  let search = window.location.search || ""
  let locationState: any = { ...location.state }
  const dispatch = useAppThunkDispatch()
  const configData = useSelector((state: RootState) => state.config)
  const PromptData = useSelector(
    (state: RootState) => state.config.newReportPrompts
  )
  const isEdit = location.state?.isEdit
  const editPromptData = location.state?.editPromptObj
  const storeId = location.state?.storeId
  const customTypeList = ["SCANNED", "KEYED"]
  let newRedirection = PATH.CREATE_EDIT_OUTPUTFILES
  if (component === CONFIG_MODULES.Reports) {
    newRedirection = PATH.CREATE_REPORTING
  }

  let editRedirection = PATH.CREATE_EDIT_OUTPUTFILES
  if (component === CONFIG_MODULES.Reports) {
    editRedirection = PATH.EDIT_REPORTING
  }

  const onCancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      if (storeId) {
        history.push(
          editRedirection.replace(":storeId", `${storeId}`) + search,
          {
            data: { ...location.state?.form }
          }
        )
      } else {
        history.push(newRedirection + search, {
          data: { ...location.state?.form }
        })
      }
    })
  }

  const onSaveClick = () => {
    updateWarnOnTabChangeToStore(false)
    let NumberOfDecimals = ""
    let CustomList: string[] = []
    if (form.Type === PromptTypes.Decimal) {
      NumberOfDecimals = form.NumberOfDecimals
    } else if (form.Type === PromptTypes.CustomList) {
      CustomList = form.CustomList
    }
    const promptLabel = form.Label
    let _promptItem: any = {
      Label: [{ "en-us": promptLabel }],
      VariableName: form.VariableName,
      SortOrder: editPromptData
        ? editPromptData.SortOrder
        : (PromptData?.length ?? 0) + 1
    }
    if (form.DefaultValue !== "") {
      _promptItem = { ..._promptItem, DefaultValue: form.DefaultValue }
    }
    if (form.Type !== "") {
      _promptItem = { ..._promptItem, Type: form.Type }
    }
    if (NumberOfDecimals !== "" && form.Type === PromptTypes.Decimal) {
      _promptItem = {
        ..._promptItem,
        NumberOfDecimals: parseInt(NumberOfDecimals, DEFAULT_RADIX)
      }
    }
    if (CustomList?.length && form.Type === PromptTypes.CustomList) {
      _promptItem = { ..._promptItem, CustomList: CustomList }
    }

    let localPrompt = [...PromptData]
    let foundIndex: number = Number(locationState?.index)
    let duplicateField: any = configData?.newReportPrompts?.find(
      (duplicateField: any, idx) => {
        return (
          duplicateField?.VariableName === form?.VariableName &&
          foundIndex !== idx
        )
      }
    )
    if (duplicateField) {
      setErrorMsg(true)
      setInvalidMsg(false)
    }
    if (!invalidMsg && !errorMsg && !duplicateField) {
      if (isEdit) {
        let newArr = [...PromptData]
        newArr[editPromptData.SortOrder - 1] = _promptItem
        localPrompt = newArr
        dispatch(setNewReportPrompts([...localPrompt]))
      } else {
        localPrompt = [...configData?.newReportPrompts, _promptItem]
        dispatch(setNewReportPrompts([...localPrompt]))
      }
      updateWarnOnTabChangeToStore(false)
      setTimeout(() => {
        if (component === CONFIG_MODULES.Reports) {
          if (storeId) {
            history.push(
              PATH.EDIT_REPORTING.replace(":storeId", `${storeId}`) + search
            )
          } else {
            history.push(PATH.CREATE_REPORTING + search)
          }
        } else if (component === CONFIG_MODULES.OutputFiles) {
          if (location.state.isParentEdit) {
            history.push(PATH.CREATE_EDIT_OUTPUTFILES + search, {
              isEdit: location.state?.isParentEdit,
              data: { ...location.state?.form, ...{ Prompts: localPrompt } }
            })
          } else {
            history.push(PATH.CREATE_EDIT_OUTPUTFILES + search, {
              data: { ...location.state?.form, ...{ Prompts: localPrompt } }
            })
          }
        }
      })
    }
  }

  useEffect(() => {
    setEditPromptData()
  }, [])

  const setEditPromptData = () => {
    if (isEdit) {
      let editObj = {
        Label: editPromptData.Label[0]["en-us"],
        VariableName: editPromptData.VariableName,
        DefaultValue: editPromptData.DefaultValue,
        Type: editPromptData.Type,
        NumberOfDecimals: editPromptData.NumberOfDecimals,
        CustomList: editPromptData.CustomList
      }

      setForm(editObj)
    }
  }
  const [form, setForm] = React.useState<INewPrompt>({
    Label: "",
    VariableName: "",
    DefaultValue: "",
    Type: "",
    NumberOfDecimals: "",
    CustomList: []
  } as INewPrompt)

  const [weekIVFields, setWeekIVFields] = useState({
    Label: "",
    VariableName: ""
  })

  let promptTypeList = [
    PromptTypes.Decimal,
    PromptTypes.Text,
    PromptTypes.Numeric,
    PromptTypes.LocationCategory,
    PromptTypes.LocationRange,
    PromptTypes.Department,
    PromptTypes.ParentDepartment,
    PromptTypes.CustomList
  ]

  if (component === CONFIG_MODULES.Reports) {
    promptTypeList = [
      PromptTypes.Numeric,
      PromptTypes.Decimal,
      PromptTypes.Alpha,
      PromptTypes.LocationRange,
      PromptTypes.LocationCategory,
      PromptTypes.Department,
      PromptTypes.ParentDepartment,
      PromptTypes.CustomList
    ]
  }

  const onFormChange = e => {
    updateWarnOnTabChangeToStore(true)
    let val = e.target.value || ""
    if (e.target?.name) {
      setForm({ ...form, [e.target.name]: val })
    }
    // validate only integers for no of decimals
    if (e.target?.name === PromptFieldNames.NumberOfDecimals) {
      if (!validateOnlyInteger(val)) {
        setForm({ ...form, [e.target.name]: val.substring(0, val.length - 1) })
      }
    }
    if (e.target?.name === PromptFieldNames.VariableName) {
      {
        let regex = /-|\s/g
        if (val.match(regex)) {
          setInvalidMsg(true)
          setErrorMsg(false)
          setForm({ ...form, [e.target.name]: val })
        } else {
          setErrorMsg(false)
          setInvalidMsg(false)
          setForm({ ...form, [e.target.name]: val })
        }
      }
    }

    // validate decimals when decimals is selected and integer when integer is selected in type
    if (e.target?.name === PromptFieldNames.DefaultValue) {
      if (form.Type === PromptTypes.Numeric) {
        if (!validateInteger(val)) {
          setForm({
            ...form,
            [e.target.name]: val.substring(0, val.length - 1)
          })
        }
      }

      if (form.Type === PromptTypes.Decimal) {
        if (!validateDecimal(val)) {
          setForm({
            ...form,
            [e.target.name]: val.substring(0, val.length - 1)
          })
        }
      }
    }

    // show/hide based on type selection
    if (e.target?.name === "Type") {
      if (val === PromptTypes.Decimal) {
        setForm({
          ...form,
          ["DefaultValue"]: "",
          Type: PromptTypes.Decimal
        })
      } else if (val === PromptTypes.CustomList) {
        setForm({
          ...form,
          ["DefaultValue"]: customTypeList[0],
          Type: PromptTypes.CustomList
        })
      } else if (val === PromptTypes.Numeric) {
        setForm({
          ...form,
          ["DefaultValue"]: "",
          Type: PromptTypes.Numeric
        })
      } else if (val === PromptTypes.Text) {
        setForm({
          ...form,
          ["DefaultValue"]: "",
          Type: PromptTypes.Text
        })
      } else if (val === PromptTypes.LocationCategory) {
        setForm({
          ...form,
          ["DefaultValue"]: "",
          Type: PromptTypes.LocationCategory
        })
      } else if (val === PromptTypes.LocationRange) {
        setForm({
          ...form,
          ["DefaultValue"]: "",
          Type: PromptTypes.LocationRange
        })
      } else if (val === PromptTypes.Department) {
        setForm({
          ...form,
          ["DefaultValue"]: "",
          Type: PromptTypes.Department
        })
      } else if (val === PromptTypes.ParentDepartment) {
        setForm({
          ...form,
          ["DefaultValue"]: "",
          Type: PromptTypes.ParentDepartment
        })
      } else if (val === PromptTypes.Alpha) {
        setForm({
          ...form,
          ["DefaultValue"]: "",
          Type: PromptTypes.Alpha
        })
      }
    }

    if (
      e.target?.name === PromptFieldNames.Label ||
      e.target?.name === PromptFieldNames.VariableName
    ) {
      setWeekFieldError({ setWeekIVFields, val, t, field: e.target.name })
    }
  }
  const getErrorMessage = () => {
    if (errorMsg) {
      return `Variable ${form.VariableName} is already in use`
    } else if (invalidMsg) {
      return "Variable name cannot contain hyphen and space"
    } else if (weekIVFields.VariableName) {
      return weekIVFields.VariableName
    } else {
      return ""
    }
  }

  const handleCustomListChange = (event, values) => {
    setForm({ ...form, CustomList: values })
  }

  const validationCheck = () => {
    if (
      errorMsg ||
      !form.Label ||
      !form.VariableName ||
      configTypeFromStore === "view" ||
      Object.values(weekIVFields).some(s => s || s !== "")
    ) {
      return true
    }
    return false
  }
  return (
    <div>
      <Grid container className="main-container">
        <FormHeading heading={`${getTranslations(t, Translates.DETAILS)} *`} />
        <Grid item xs={12} sm={3} className="mb-8">
          <div className="text-box">
            <TextField
              data-testid="label"
              fullWidth
              label={getTranslations(t, Translates.Label)}
              variant="filled"
              autoSave="false"
              autoComplete="off"
              className="custom-form-control"
              required={true}
              name="Label"
              value={form.Label}
              onChange={e => onFormChange(e)}
              error={!!weekIVFields.Label}
              helperText={weekIVFields.Label}
            />
            <TextField
              fullWidth
              data-testid="vName"
              label={getTranslations(t, Translates.Variable_Name)}
              variant="filled"
              autoSave="false"
              autoComplete="off"
              className="custom-form-control"
              required={true}
              name="VariableName"
              value={form.VariableName}
              onChange={e => onFormChange(e)}
              error={
                errorMsg || invalidMsg || !!weekIVFields.VariableName
                  ? true
                  : false
              }
              helperText={getErrorMessage()}
            />

            <FormControl
              variant="filled"
              className="custom-form-control select"
            >
              <InputLabel id="demo-multiple-checkbox-label" variant="filled">
                {getTranslations(t, Translates.Type)}
              </InputLabel>
              <Select
                data-testid="type"
                id="demo-multiple-checkbox"
                value={form.Type}
                name="Type"
                onChange={e => onFormChange(e)}
              >
                {promptTypeList?.map((name: any) => (
                  <MenuItem key={name} value={name} className="dropdown-list">
                    <small>{getTranslations(t, name)}</small>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {form.Type === PromptTypes.CustomList ? (
              <div className="text-box">
                <FormControl
                  className="custom-form-control select no-border multi-select"
                  variant="filled"
                >
                  <Autocomplete
                    data-testid="cList"
                    id={PromptFieldNames.CustomList}
                    multiple
                    disableCloseOnSelect
                    options={customTypeList}
                    limitTags={2}
                    value={form.CustomList}
                    onChange={handleCustomListChange}
                    getOptionLabel={(option: any) => option}
                    renderInput={params => (
                      <TextField
                        {...params}
                        value={form.CustomList}
                        name={PromptFieldNames.CustomList}
                        label={getTranslations(t, Translates.Custom_List)}
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          checkedIcon={<CheckIcon />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                  />
                </FormControl>
              </div>
            ) : null}
            {form.Type === PromptTypes.Decimal ? (
              <TextField
                data-testid="numberOfDecimals"
                fullWidth
                label={getTranslations(t, Translates.Enter_Decimal_Places)}
                variant="filled"
                autoSave="false"
                autoComplete="off"
                className="custom-form-control"
                name="NumberOfDecimals"
                value={form.NumberOfDecimals}
                onChange={e => onFormChange(e)}
              />
            ) : null}
            <TextField
              data-testid="defaultValue"
              fullWidth
              label={getTranslations(t, Translates.Default_Value)}
              variant="filled"
              autoSave="false"
              autoComplete="off"
              className="custom-form-control"
              name="DefaultValue"
              value={form.DefaultValue}
              onChange={e => onFormChange(e)}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="inner-view__footer">
            <Button
              variant="text"
              className="primary-btn cancel-btn mr-5"
              onClick={() => onCancelClick()}
              data-testid="cancel"
            >
              {getTranslations(t, Translates.Cancel)}
            </Button>
            <Button
              variant="contained"
              className="primary-btn"
              onClick={() => onSaveClick()}
              disabled={validationCheck()}
              data-testid="save"
            >
              {getTranslations(t, Translates.Save)}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default CreateEditPrompts
