import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material"
import React, { useEffect, useState } from "react"
import BreadCrumb from "src/components/BreadCrumb/BreadCrumb"
import { Loader, getDataSourceAutoComplete } from "src/utils/reusables/reusable"
import AddIcon from "@mui/icons-material/Add"
import CloseIcon from "@mui/icons-material/Close"
import { useHistory } from "react-router-dom"
import {
  OUTPUT_FILE_TITLES,
  OUTPUT_FILE_TYPE,
  PlacementTypeList,
  SubTotalFunctionTypeList,
  Subtotal_Fields
} from "src/constants/outputFiles"
import {
  setCurrentOutputFile,
  setOutputGrandTotals,
  setOutputSubTotals
} from "src/redux/slices/configSlice"
import { PATH } from "src/constants/paths"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/redux/store"
import {
  handleAutoCompleteDataSourceChange,
  updateWarnOnTabChangeToStore
} from "src/configurationPortal/pages/helper"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
interface IForm {
  DataGroup: string
  Label: string
  Placement: string
}
const CreateEditTotals = ({ location }) => {
  const { t } = useTranslation<any>()
  const configTypeFromStore = useSelector(
    (state: RootState) =>state.config?.configType
  )
  let search = window.location.search || ""
  const configData = useSelector((state: RootState) =>state.config)
  const [dataSource, setDataSource] = React.useState([] as any)
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  let locationState: any = { ...location?.state }
  const [form, setForm] = React.useState<IForm>({
    DataGroup: "",
    Label: "",
    Placement: ""
  } as IForm)
  const [valuesList, setValuesList] = useState([
    { DataSource: "", Column: "", Function: "" }
  ] as any)
  const [path, setPath] = useState("")
  const newOutputFile = useSelector(
    (state: RootState) => state.config?.currentOutputFile
  )
  const sqlQueryColumns = useSelector(
    (state: RootState) => state.config?.newSQLQueryColumns
  )
  const outputColumns = useSelector(
    (state: RootState) => state.config?.outputColumns
  )

  let result = sqlQueryColumns?.map(({ ColumnName }) => ColumnName)

  let columnNames = outputColumns?.map(({ ColumnName }) => ColumnName)

  useEffect(() => {
    setLoading(true)
    setPath(location?.pathname && location?.pathname?.split("/")[2])

    // setDataSource(location?.subTotalData?.DataGroup)
    if (locationState?.isSubTotalEdit === true) {
      setDataSource(location.state?.subTotalData?.DataGroup)
      setForm({
        ...locationState?.subTotalData
      })
      if (!locationState?.subTotalData?.AggregateConfigs.length) {
        setValuesList([{ DataSource: "", Column: "", Function: "" }])
      } else {
        setValuesList(locationState?.subTotalData?.AggregateConfigs)
      }
    } else if (locationState?.isGrandTotalEdit === true) {
      setForm({
        ...locationState?.grandTotalData
      })
      if (!locationState?.grandTotalData?.AggregateConfigs.length) {
        setValuesList([{ DataSource: "", Column: "", Function: "" }])
      } else {
        setValuesList(locationState?.grandTotalData?.AggregateConfigs)
      }
    } else {
      setForm({
        DataGroup: "",
        Label: "",
        Placement: ""
      })
      setValuesList([{ DataSource: "", Column: "", Function: "" }])
    }
    setLoading(false)
  }, [])
  const getSubTotalBreadcrumb = () => {
    if (path == OUTPUT_FILE_TYPE.SUB_TOTAL) {
      if (locationState?.isSubTotalEdit === true) {
        return OUTPUT_FILE_TITLES.EditSubTotal
      } else {
        return OUTPUT_FILE_TITLES.NewSubTotal
      }
    } else {
      if (locationState?.isGrandTotalEdit === true) {
        return OUTPUT_FILE_TITLES.EditGrandTotal
      } else {
        return OUTPUT_FILE_TITLES.NewGrandTotal
      }
    }
  }
  const getBreadcrumbConfig = () => {
    let currentPath: string = `outputFiles`
    let variables: any = {
      outputFiles: getTranslations(t, getSubTotalBreadcrumb())
    }
    let bConfig: any = {
      currentPath,
      mapObj: variables,
      showHomeIcon: false
    }
    return bConfig
  }
  const onFormChange = e => {
    window.onbeforeunload = () => true
    updateWarnOnTabChangeToStore(true)
    const val = e.target.value || ""
    if (e.target?.name == Subtotal_Fields.DataGroup) {
      setForm({ ...form, [e.target.name]: val })
    }
    if (e.target?.name == Subtotal_Fields.Label) {
      setForm({ ...form, [e.target.name]: val })
    }
    if (e.target?.name == Subtotal_Fields.Placement) {
      setForm({ ...form, [e.target.name]: val })
    }
  }
  const handleValueChange = (e, idx) => {
    window.onbeforeunload = () => true
    const { name, value } = e.target
    const list = [...valuesList]
    let item = { ...list[idx] }
    item[name] = value
    list[idx] = { ...item }
    setValuesList(list)
  }
  const handleAddValueCLick = () => {
    setValuesList([...valuesList, { DataSource: "", Column: "", Function: "" }])
  }
  const handleRemoveValueCLick = idx => {
    const list = [...valuesList]
    list.splice(idx, 1)
    setValuesList(list)
  }
  const refreshForm = () => {
    setForm({
      DataGroup: "",
      Label: "",
      Placement: ""
    })
    setValuesList([{ DataSource: "", Column: "", Function: "" }])
  }
  const getValuesList = () => {
    if (
      valuesList.length === 1 &&
      valuesList[0].DataSource == "" &&
      valuesList[0].Column == "" &&
      valuesList[0].Function == ""
    ) {
      return []
    } else {
      return valuesList
    }
  }
  const subTotalsSortOrder =
    configData?.outputSubTotals?.length > 0
      ? configData?.outputSubTotals?.reduce(
          (a: any, b: { SortOrder: number }) => Math.max(b.SortOrder) || a,
          0
        )
      : 0
  const grandTotalsSortOrder =
    configData?.outputGrandTotals?.length > 0
      ? configData?.outputGrandTotals?.reduce(
          (a: any, b: { SortOrder: number }) => Math.max(b.SortOrder) || a,
          0
        )
      : 0
  const goBack = data => {
    updateWarnOnTabChangeToStore(false)
    if (path == OUTPUT_FILE_TYPE.SUB_TOTAL) {
      let subTotalConfig = {
        ...newOutputFile,
        SubTotals: data
      }
      dispatch(setCurrentOutputFile(subTotalConfig))
      dispatch(setOutputSubTotals(data))
      setTimeout(() => {
        history.push(PATH.CREATE_EDIT_OUTPUTFILES + search, {
          data: { ...locationState.data, SubTotals: data },
          subTotalEdit: true,
          isSubTotalEdit: locationState.isSubTotalEdit,
          isEdit: locationState.isParentEdit,
          name: locationState?.form?.Name,
          form: locationState?.form
        })
      })
    } else {
      let grandTotalConfig = {
        ...newOutputFile,
        GrandTotals: data
      }
      dispatch(setCurrentOutputFile(grandTotalConfig))
      dispatch(setOutputGrandTotals(data))
      setTimeout(() => {
        history.push(PATH.CREATE_EDIT_OUTPUTFILES + search, {
          data: { ...locationState.data, GrandTotals: data },
          grandTotalEdit: true,
          isGrandTotalEdit: locationState.isGrandTotalEdit,
          isEdit: locationState.isParentEdit,
          name: locationState?.form?.Name,
          form: locationState?.form
        })
      })
    }
  }
  const handleSaveClick = () => {
    let _item: any = {
      Label: form?.Label,
      AggregateConfigs: getValuesList(),
      SortOrder:
        path == OUTPUT_FILE_TYPE.SUB_TOTAL
          ? subTotalsSortOrder
          : grandTotalsSortOrder + 1
    }
    if (path == OUTPUT_FILE_TYPE.SUB_TOTAL) {
      _item = { ..._item, DataGroup: dataSource }
    }
    if (form?.Placement) {
      _item = { ..._item, Placement: form?.Placement }
    }
    let subTotalsFoundIndex: any = configData?.outputSubTotals?.findIndex(
      x => x?.DataGroup == locationState?.subTotalData?.DataGroup
    )
    let grandTotalsFoundIndex: any = configData?.outputGrandTotals?.findIndex(
      x => x?.DataGroup == locationState?.grandTotalData?.DataGroup
    )
    if (path == OUTPUT_FILE_TYPE.SUB_TOTAL) {
      if (locationState?.isSubTotalEdit) {
        let newArr = [...configData?.outputSubTotals]
        newArr[subTotalsFoundIndex] = _item
        goBack([...newArr])
      } else {
        let subTot = configData?.outputSubTotals || []
        goBack([...subTot, _item])
      }
    } else {
      if (locationState?.isGrandTotalEdit) {
        let newArr = [...configData?.outputGrandTotals]
        newArr[grandTotalsFoundIndex] = _item
        goBack([...newArr])
      } else {
        let grandTot = configData?.outputGrandTotals || []
        goBack([...grandTot, _item])
      }
    }
  }
  const handleSaveNewClick = e => {
    let _item: any = {
      Label: form?.Label,
      AggregateConfigs: getValuesList(),
      SortOrder:
        path == OUTPUT_FILE_TYPE.SUB_TOTAL
          ? subTotalsSortOrder
          : grandTotalsSortOrder + 1
    }
    if (path == OUTPUT_FILE_TYPE.SUB_TOTAL) {
      _item = { ..._item, DataGroup: form?.DataGroup }
    }
    if (form?.Placement) {
      _item = { ..._item, Placement: form?.Placement }
    }
    let subTot = configData?.outputSubTotals || []
    let grandTot = configData?.outputGrandTotals || []

    let subTotalConfig = {
      ...newOutputFile,
      SubTotals: [...subTot, _item]
    }
    let grandTotalConfig = {
      ...newOutputFile,
      GrandTotals: [...grandTot, _item]
    }
    if (path == OUTPUT_FILE_TYPE.SUB_TOTAL) {
      dispatch(setCurrentOutputFile(subTotalConfig))
      dispatch(setOutputSubTotals([...subTot, _item]))
      refreshForm()
    } else {
      dispatch(setCurrentOutputFile(grandTotalConfig))
      dispatch(setOutputGrandTotals([...grandTot, _item]))
      refreshForm()
    }
  }
  const handleCancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    history.goBack()
  }
  const handleDataSourceChange = (event, values) => {
    handleAutoCompleteDataSourceChange(
      values,
      dataSource,
      result,
      setDataSource
    )
  }
  const getDisabledInfo = () => {
    if (path == OUTPUT_FILE_TYPE.SUB_TOTAL) {
      if (dataSource.length > 0) {
        return false
      } else return true
    } else {
      if (form?.Label) {
        return false
      } else return true
    }
  }
  return (
    <div>
      <div className="main-toolbar">
        <div className="main-container">
          <Grid container>
            <Grid item sm={12} className="d-flex align-items-center">
              <div className="custom-breadcrumb CP">
                <BreadCrumb
                  customPath={"New Sub Total"}
                  breadCrumbInfo={getBreadcrumbConfig()}
                  renderedPage={""}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {loading ? (
        Loader()
      ) : (
        <Grid container className="main-container">
          <Grid item xs={12} className="mb-8">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <label className="form-control__label mb-3">
                  {getTranslations(t, Translates.Basic_Details)}
                </label>
                {path == OUTPUT_FILE_TYPE.SUB_TOTAL ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      {getDataSourceAutoComplete(
                        result,
                        dataSource,
                        handleDataSourceChange,
                        t
                      )}
                      <Divider className="dashed my-4" />
                    </Grid>
                  </Grid>
                ) : null}

                <Grid container>
                  <Grid item xs={12} sm={8}>
                    <div className="text-box">
                      <TextField
                        data-testid="label"
                        fullWidth
                        label={getTranslations(t, Translates.Label)}
                        variant="filled"
                        autoSave="false"
                        autoComplete="off"
                        className="custom-form-control"
                        name="Label"
                        value={form?.Label}
                        onChange={e => onFormChange(e)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider className="dashed my-4" />
            <Grid container>
              <Grid item xs={12} sm={12}>
                <label className="form-control__label mb-3">
                  {getTranslations(t, Translates.Values)}
                </label>
              </Grid>
              {valuesList?.map((value, index) => (
                <Grid key={value} container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <FormControl
                      variant="filled"
                      className="custom-form-control select"
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        variant="filled"
                      >
                        {getTranslations(t, Translates.Data_Source)}
                      </InputLabel>

                      <Select
                        data-testid="dataSource"
                        id="demo-multiple-checkbox"
                        value={value.DataSource}
                        name="DataSource"
                        onChange={e => handleValueChange(e, index)}
                      >
                        {result?.map((item: any) => (
                          <MenuItem
                            key={item}
                            value={item}
                            className="dropdown-list"
                          >
                            <small>{item}</small>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl
                      variant="filled"
                      className="custom-form-control select"
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        variant="filled"
                      >
                        {getTranslations(t, Translates.Column_Name)}
                      </InputLabel>

                      <Select
                        data-testid="column"
                        id="demo-multiple-checkbox"
                        value={value.Column}
                        name="Column"
                        onChange={e => handleValueChange(e, index)}
                      >
                        {columnNames?.map((item: any) => (
                          <MenuItem
                            key={item}
                            value={item}
                            className="dropdown-list"
                          >
                            <small>{item}</small>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl
                      variant="filled"
                      className="custom-form-control select"
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        variant="filled"
                      >
                        {getTranslations(t, Translates.Function)}
                      </InputLabel>
                      <Select
                        data-testid="function"
                        id="demo-multiple-checkbox"
                        value={value?.Function}
                        onChange={e => handleValueChange(e, index)}
                        name="Function"
                      >
                        {SubTotalFunctionTypeList?.map((name: any) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className="dropdown-list"
                          >
                            <small>{getTranslations(t, name)}</small>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {valuesList.length - 1 === index ? (
                      <Button
                        data-testid="add"
                        style={{ width: "56px", height: "56px" }}
                        variant="contained"
                        className="secondary-btn icon-btn pa-0 mr-1"
                        onClick={handleAddValueCLick}
                      >
                        <AddIcon />
                      </Button>
                    ) : null}
                    {valuesList.length > 1 ? (
                      <Button
                        data-testid="close"
                        style={{ width: "56px", height: "56px" }}
                        variant="contained"
                        className="secondary-btn icon-btn pa-0 "
                        onClick={() => handleRemoveValueCLick(index)}
                      >
                        <CloseIcon />
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl
                variant="filled"
                className="custom-form-control select"
              >
                <InputLabel id="demo-multiple-checkbox-label" variant="filled">
                  {getTranslations(t, Translates.Placement)}
                </InputLabel>
                <Select
                  data-testid="placement"
                  id="demo-multiple-checkbox"
                  value={form?.Placement}
                  onChange={e => onFormChange(e)}
                  name="Placement"
                >
                  {PlacementTypeList?.map((name: any) => (
                    <MenuItem key={name} value={name} className="dropdown-list">
                      <small>{getTranslations(t, name)}</small>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="inner-view__footer">
              <Button
                data-testid="cancelBtn"
                variant="text"
                className="primary-btn cancel-btn mr-5"
                onClick={handleCancelClick}
              >
                {getTranslations(t, Translates.Cancel)}
              </Button>

              {!locationState?.isSubTotalEdit &&
              !locationState?.isGrandTotalEdit ? (
                <Button
                  variant="text"
                  data-testid="saveNew"
                  className="primary-btn mr-5 cancel-btn"
                  disabled={getDisabledInfo() || configTypeFromStore === "view"}
                  onClick={e => handleSaveNewClick(e)}
                >
                  {getTranslations(t, Translates.Save_New)}
                </Button>
              ) : null}
              <Button
                disabled={getDisabledInfo() || configTypeFromStore === "view"}
                data-testid="saveBtn"
                variant="contained"
                className="primary-btn"
                onClick={handleSaveClick}
              >
                {getTranslations(t, Translates.Save)}
              </Button>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default CreateEditTotals
