import { Grid, Divider } from "@mui/material"
import ForwardIcon from "@mui/icons-material/Forward"
import React, { useEffect, useState } from "react"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import { useTranslation } from "react-i18next"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import {
  setLocationMappingData,
  setLocationReset,
  setMappingPayloadData
} from "src/redux/slices/settingsSlice"
import { SettingsService } from "src/service/settings.service"
import { Translates } from "src/i18n/i18n"
import { encryptBulkColumns, getTranslations } from "src/utils/helper"
import BulkDataSelectField from "../Component/BulkDataSelectField"
import BulkUploadButtons from "../Component/BulkUploadButtons"
import LocationAndScheduleTopHeadings from "../Component/LocationAndScheduleTopHeadings"
import { LOCATION_FIELD_MAP } from "./LocationHelper"
import { PromptTypes } from "src/utils/constants"

const LocationBulkDataMapping = ({ setPage }) => {
  const [locationData, setLocationData] = useState({} as any)
  const { t } = useTranslation<any>()
  const dispatch = useAppThunkDispatch()
  const history = useHistory()
  const [bulkData, setBulkData] = useState([] as any)
  const settingsService = new SettingsService()
  const locationFileData: any = useSelector(
    (state: RootState) => state.settings?.locationFileData
  )
  const locationMappingData = useSelector(
    (state: RootState) => state.settings?.locationMappingData
  )
  const csvLocationColumnList = useSelector(
    (state: RootState) => state.settings?.csvLocationColumnList
  )
  const [fileDataString, setFileDataString] = useState("" as any)
  const getColumnHeader = value => {
    if (csvLocationColumnList?.includes(value)) {
      return true
    } else {
      return false
    }
  }
  const getIndividualLocationDetail = val => {
    if (val === "name") {
      if (locationMappingData.name) {
        return locationMappingData.name
      } else return getColumnHeader("Name") ? "Name" : ""
    } else if (val === "siteId") {
      if (locationMappingData.siteId) {
        return locationMappingData.siteId
      } else return getColumnHeader("Site ID") ? "Site ID" : ""
    } else if (val === "banner") {
      if (locationMappingData.banner) {
        return locationMappingData.banner
      } else return getColumnHeader("Banner") ? "Banner" : ""
    } else if (val === "phone") {
      if (locationMappingData.phone) {
        return locationMappingData.phone
      } else return getColumnHeader("Phone") ? "Phone" : ""
    } else if (val === "email") {
      if (locationMappingData.email) {
        return locationMappingData.email
      } else return getColumnHeader("Email") ? "Email" : ""
    } else if (val === "region1") {
      if (locationMappingData.region1) {
        return locationMappingData.region1
      } else return getColumnHeader("Region 1") ? "Region 1" : ""
    } else if (val === "region2") {
      if (locationMappingData.region2) {
        return locationMappingData.region2
      } else return getColumnHeader("Region 2") ? "Region 2" : ""
    } else if (val === "region3") {
      if (locationMappingData.region3) {
        return locationMappingData.region3
      } else return getColumnHeader("Region 3") ? "Region 3" : ""
    } else if (val === "region4") {
      if (locationMappingData.region4) {
        return locationMappingData.region4
      } else return getColumnHeader("Region 4") ? "Region 4" : ""
    } else if (val === "locationAttribute1") {
      if (locationMappingData.locationAttribute1) {
        return locationMappingData.locationAttribute1
      } else return getColumnHeader("Attribute 1") ? "Attribute 1" : ""
    } else if (val === "locationAttribute2") {
      if (locationMappingData.locationAttribute2) {
        return locationMappingData.locationAttribute2
      } else return getColumnHeader("Attribute 2") ? "Attribute 2" : ""
    } else if (val === "locationAttribute3") {
      if (locationMappingData.locationAttribute3) {
        return locationMappingData.locationAttribute3
      } else return getColumnHeader("Attribute 3") ? "Attribute 3" : ""
    } else if (val === "locationAttribute4") {
      if (locationMappingData.locationAttribute4) {
        return locationMappingData.locationAttribute4
      } else return getColumnHeader("Attribute 4") ? "Attribute 4" : ""
    } else if (val === "locationAttribute5") {
      if (locationMappingData.locationAttribute5) {
        return locationMappingData.locationAttribute5
      } else return getColumnHeader("Attribute 5") ? "Attribute 5" : ""
    } else if (val === "locationAttribute6") {
      if (locationMappingData.locationAttribute6) {
        return locationMappingData.locationAttribute6
      } else return getColumnHeader("Attribute 6") ? "Attribute 6" : ""
    } else if (val === "locationAttribute7") {
      if (locationMappingData.locationAttribute7) {
        return locationMappingData.locationAttribute7
      } else return getColumnHeader("Attribute 7") ? "Attribute 7" : ""
    } else if (val === "locationAttribute8") {
      if (locationMappingData.locationAttribute8) {
        return locationMappingData.locationAttribute8
      } else return getColumnHeader("Attribute 8") ? "Attribute 8" : ""
    } else if (val === "locationAttribute9") {
      if (locationMappingData.locationAttribute9) {
        return locationMappingData.locationAttribute9
      } else return getColumnHeader("Attribute 9") ? "Attribute 9" : ""
    } else if (val === "locationAttribute10") {
      if (locationMappingData.locationAttribute10) {
        return locationMappingData.locationAttribute10
      } else return getColumnHeader("Attribute 10") ? "Attribute 10" : ""
    } else if (val === "address1") {
      if (locationMappingData.address1) {
        return locationMappingData.address1
      } else return getColumnHeader("Address 1") ? "Address 1" : ""
    } else if (val === "address2") {
      if (locationMappingData.address2) {
        return locationMappingData.address2
      } else return getColumnHeader("Address 2") ? "Address 2" : ""
    } else if (val === "address3") {
      if (locationMappingData.address3) {
        return locationMappingData.address3
      } else return getColumnHeader("Address 3") ? "Address 3" : ""
    } else if (val === "city") {
      if (locationMappingData.city) {
        return locationMappingData.city
      } else return getColumnHeader("City") ? "City" : ""
    } else if (val === "state") {
      if (locationMappingData.state) {
        return locationMappingData.state
      } else return getColumnHeader("State") ? "State" : ""
    } else if (val === "country") {
      if (locationMappingData.country) {
        return locationMappingData.country
      } else return getColumnHeader("Country") ? "Country" : ""
    } else {
      if (locationMappingData.postalCode) {
        return locationMappingData.postalCode
      } else return getColumnHeader("Postal Code") ? "Postal Code" : ""
    }
  }
  const getLocationDetails = () => {
    return {
      name: getIndividualLocationDetail("name"),
      siteId: getIndividualLocationDetail("siteId"),
      banner: getIndividualLocationDetail("banner"),
      phone: getIndividualLocationDetail("phone"),
      email: getIndividualLocationDetail("email"),
      region1: getIndividualLocationDetail("region1"),
      region2: getIndividualLocationDetail("region2"),
      region3: getIndividualLocationDetail("region3"),
      region4: getIndividualLocationDetail("region4"),
      locationAttribute1: getIndividualLocationDetail("locationAttribute1"),
      locationAttribute2: getIndividualLocationDetail("locationAttribute2"),
      locationAttribute3: getIndividualLocationDetail("locationAttribute3"),
      locationAttribute4: getIndividualLocationDetail("locationAttribute4"),
      locationAttribute5: getIndividualLocationDetail("locationAttribute5"),
      locationAttribute6: getIndividualLocationDetail("locationAttribute6"),
      locationAttribute7: getIndividualLocationDetail("locationAttribute7"),
      locationAttribute8: getIndividualLocationDetail("locationAttribute8"),
      locationAttribute9: getIndividualLocationDetail("locationAttribute9"),
      locationAttribute10: getIndividualLocationDetail("locationAttribute10"),
      address1: getIndividualLocationDetail("address1"),
      address2: getIndividualLocationDetail("address2"),
      address3: getIndividualLocationDetail("address3"),
      city: getIndividualLocationDetail("city"),
      state: getIndividualLocationDetail("state"),
      postalCode: getIndividualLocationDetail("postalCode"),
      country: getIndividualLocationDetail("country")
    }
  }
  const [locationDetails, setLocationDetails] = useState(getLocationDetails())
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  useEffect(() => {
    getFileData()
    getLocationsByStore()
  }, [customerId])
  useEffect(() => {
    getLocationsByStore()
  }, [])
  const getLocationsByStore = async () => {
    try {
      await settingsService
        .getLocationsByStore([customerId])
        .then((temp: any) => {
          setLocationData(temp.data[0])
        })
    } catch (_error) {
      console.error(_error)
    }
  }

  const getFileData = () => {
    setFileDataString(locationFileData)
    let keys: any = csvLocationColumnList
    setBulkData(keys)
    setLocationDetails(getLocationDetails())
  }

  const handleNoCustomerId = () => {
    if (!customerId) {
      setTimeout(() => {
        history.push(PATH.LOCATIONS)
      }, 3000)
    }
  }

  const handleChangeLocation = (e, field: any) => {
    let locDetails = { ...locationDetails }
    locDetails[field] = e.target.value
    setLocationDetails({
      ...locDetails
    })
  }
  const handleValidation = () => {
    dispatch(setLocationMappingData(locationDetails))
    sendPayload()
  }
  const sendPayload = () => {
    const COLUMNS = [
      {
        dataAttribute: "Name",
        csvAttribute: locationDetails.name
      },
      {
        dataAttribute: "Site ID",
        csvAttribute: locationDetails.siteId
      },
      {
        dataAttribute: "Banner",
        csvAttribute: locationDetails.banner
      },
      {
        dataAttribute: "Phone",
        csvAttribute: locationDetails.phone
      },
      {
        dataAttribute: "Email",
        csvAttribute: locationDetails.email
      },
      {
        dataAttribute: "Region 1",
        csvAttribute: locationDetails.region1
      },
      {
        dataAttribute: "Region 2",
        csvAttribute: locationDetails.region2
      },
      {
        dataAttribute: "Region 3",
        csvAttribute: locationDetails.region3
      },
      {
        dataAttribute: "Region 4",
        csvAttribute: locationDetails.region4
      },
      {
        dataAttribute: "Attribute 1",
        csvAttribute: locationDetails.locationAttribute1
      },
      {
        dataAttribute: "Attribute 2",
        csvAttribute: locationDetails.locationAttribute2
      },
      {
        dataAttribute: "Attribute 3",
        csvAttribute: locationDetails.locationAttribute3
      },
      {
        dataAttribute: "Attribute 4",
        csvAttribute: locationDetails.locationAttribute4
      },
      {
        dataAttribute: "Attribute 5",
        csvAttribute: locationDetails.locationAttribute5
      },
      {
        dataAttribute: "Attribute 6",
        csvAttribute: locationDetails.locationAttribute6
      },
      {
        dataAttribute: "Attribute 7",
        csvAttribute: locationDetails.locationAttribute7
      },
      {
        dataAttribute: "Attribute 8",
        csvAttribute: locationDetails.locationAttribute8
      },
      {
        dataAttribute: "Attribute 9",
        csvAttribute: locationDetails.locationAttribute9
      },
      {
        dataAttribute: "Attribute 10",
        csvAttribute: locationDetails.locationAttribute10
      },
      {
        dataAttribute: "Address 1",
        csvAttribute: locationDetails.address1
      },
      {
        dataAttribute: "Address 2",
        csvAttribute: locationDetails.address2
      },
      {
        dataAttribute: "Address 3",
        csvAttribute: locationDetails.address3
      },
      {
        dataAttribute: "City",
        csvAttribute: locationDetails.city
      },
      {
        dataAttribute: "State",
        csvAttribute: locationDetails.state
      },
      {
        dataAttribute: "Postal Code",
        csvAttribute: locationDetails.postalCode
      },
      {
        dataAttribute: "Country",
        csvAttribute: locationDetails.country
      }
    ].map(i =>
      i.csvAttribute === PromptTypes.NONE ? { ...i, csvAttribute: "" } : i
    )
    const payloadToAdd = {
      idCustomer: customerId,
      IsConfirmed: false,
      continueStatus: null,
      columns: encryptBulkColumns(COLUMNS),
      fileData: fileDataString
    }
    dispatch(setMappingPayloadData(payloadToAdd))
    setPage("page3")
  }
  const handlePreviousClick = () => {
    dispatch(setLocationMappingData(locationDetails))
    setPage("page1")
  }
  const handleCancelClick = () => {
    dispatch(setLocationReset())
    history.push(PATH.LOCATIONS)
  }
  const saveDisable = () => {
    if (
      locationDetails.siteId == "" ||
      locationDetails.address1 == "" ||
      locationDetails.city == "" ||
      locationDetails.state == "" ||
      locationDetails.postalCode == "" ||
      locationDetails.country == ""
    ) {
      return true
    } else {
      return false
    }
  }
  const bulkDataWithNone = [PromptTypes.NONE, ...bulkData]
  return (
    <Grid container>
      <MiniHeader
        headerTitle={getTranslations(t, Translates.UPLOAD_LOCATIONS)}
        multiCustomer={handleNoCustomerId}
      />
      <Grid container className="inner-view">
        <Grid item xs={12} className="main-container">
          <LocationAndScheduleTopHeadings />
          <Grid container className="ml-2 mt-2">
            <Grid container className="mb-3">
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.Name)}</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={locationDetails?.name}
                  defaultValue={getColumnHeader("Name")}
                  handleOnChange={e =>
                    handleChangeLocation(e, LOCATION_FIELD_MAP.NAME)
                  }
                  fieldIndex={1}
                  bulkData={bulkDataWithNone}
                />
              </Grid>
            </Grid>
            <Grid container className="mt-2">
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.Site_ID)}*</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={locationDetails?.siteId}
                  defaultValue={""}
                  handleOnChange={e =>
                    handleChangeLocation(e, LOCATION_FIELD_MAP.SITE_ID)
                  }
                  fieldIndex={2}
                  bulkData={bulkData}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="pb-3 pt-1">
              <Divider />
            </Grid>
            <Grid container className="mb-3">
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.Banner)}</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={locationDetails?.banner}
                  defaultValue={""}
                  handleOnChange={e =>
                    handleChangeLocation(e, LOCATION_FIELD_MAP.BANNER)
                  }
                  fieldIndex={3}
                  bulkData={bulkDataWithNone}
                  FormControlClass={"mb-0"}
                />
              </Grid>
            </Grid>
            <Grid container className="mb-3">
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.Phone)}</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={locationDetails?.phone}
                  defaultValue={""}
                  handleOnChange={e =>
                    handleChangeLocation(e, LOCATION_FIELD_MAP.PHONE)
                  }
                  fieldIndex={4}
                  bulkData={bulkDataWithNone}
                />
              </Grid>
            </Grid>
            <Grid container className="mb-3">
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.Email)}</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={locationDetails?.email}
                  defaultValue={""}
                  handleOnChange={e =>
                    handleChangeLocation(e, LOCATION_FIELD_MAP.EMAIL)
                  }
                  fieldIndex={5}
                  bulkData={bulkDataWithNone}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="py-3">
              <Divider />
            </Grid>
            {locationData?.regionalGroup1Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>{getTranslations(t, Translates.Region1)}</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.region1}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(e, LOCATION_FIELD_MAP.REGION_1)
                    }
                    fieldIndex={6}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            {locationData?.regionalGroup2Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>{getTranslations(t, Translates.Region2)}</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.region2}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(e, LOCATION_FIELD_MAP.REGION_2)
                    }
                    fieldIndex={7}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            {locationData?.regionalGroup3Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>{getTranslations(t, Translates.Region3)}</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.region3}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(e, LOCATION_FIELD_MAP.REGION_3)
                    }
                    fieldIndex={8}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            {locationData?.regionalGroup4Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>{getTranslations(t, Translates.Region4)}</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.region4}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(e, LOCATION_FIELD_MAP.REGION_4)
                    }
                    fieldIndex={9}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid item xs={12} className="py-3">
              <Divider />
            </Grid>
            {locationData?.locationAttribute1Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>Attribute 1</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.locationAttribute1}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(
                        e,
                        LOCATION_FIELD_MAP.LOCATION_ATTRIBUTE_1
                      )
                    }
                    fieldIndex={10}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            {locationData?.locationAttribute2Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>Attribute 2</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.locationAttribute2}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(
                        e,
                        LOCATION_FIELD_MAP.LOCATION_ATTRIBUTE_2
                      )
                    }
                    fieldIndex={11}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            {locationData?.locationAttribute3Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>Attribute 3</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.locationAttribute3}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(
                        e,
                        LOCATION_FIELD_MAP.LOCATION_ATTRIBUTE_3
                      )
                    }
                    fieldIndex={12}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            {locationData?.locationAttribute4Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>Attribute 4</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.locationAttribute4}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(
                        e,
                        LOCATION_FIELD_MAP.LOCATION_ATTRIBUTE_4
                      )
                    }
                    fieldIndex={13}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            {locationData?.locationAttribute5Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>Attribute 5</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.locationAttribute5}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(
                        e,
                        LOCATION_FIELD_MAP.LOCATION_ATTRIBUTE_5
                      )
                    }
                    fieldIndex={14}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            {locationData?.locationAttribute6Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>Attribute 6</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.locationAttribute6}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(
                        e,
                        LOCATION_FIELD_MAP.LOCATION_ATTRIBUTE_6
                      )
                    }
                    fieldIndex={15}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            {locationData?.locationAttribute7Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>Attribute 7</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.locationAttribute7}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(
                        e,
                        LOCATION_FIELD_MAP.LOCATION_ATTRIBUTE_7
                      )
                    }
                    fieldIndex={16}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            {locationData?.locationAttribute8Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>Attribute 8</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.locationAttribute8}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(
                        e,
                        LOCATION_FIELD_MAP.LOCATION_ATTRIBUTE_8
                      )
                    }
                    fieldIndex={17}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            {locationData?.locationAttribute9Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>Attribute 9</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.locationAttribute9}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(
                        e,
                        LOCATION_FIELD_MAP.LOCATION_ATTRIBUTE_9
                      )
                    }
                    fieldIndex={18}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            {locationData?.locationAttribute10Label ? (
              <Grid container className="mb-3">
                <Grid item sm={3} className="d-flex align-items-center">
                  <label>Attribute 10</label>
                </Grid>
                <Grid item sm={1} className="d-flex align-items-center">
                  <ForwardIcon className="mr-1 blue-text" />
                </Grid>
                <Grid item sm={4}>
                  <BulkDataSelectField
                    label={getTranslations(t, Translates.CSV_Column_Header)}
                    value={locationDetails?.locationAttribute10}
                    defaultValue={""}
                    handleOnChange={e =>
                      handleChangeLocation(
                        e,
                        LOCATION_FIELD_MAP.LOCATION_ATTRIBUTE_10
                      )
                    }
                    fieldIndex={19}
                    bulkData={bulkDataWithNone}
                    FormControlClass="mb-0"
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid item xs={12} className="py-3">
              <Divider />
            </Grid>
            <Grid container className="mb-3">
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.Address1)}*</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={locationDetails?.address1}
                  defaultValue={""}
                  handleOnChange={e =>
                    handleChangeLocation(e, LOCATION_FIELD_MAP.ADDRESS_1)
                  }
                  fieldIndex={20}
                  bulkData={bulkData}
                  FormControlClass="mb-0"
                />
              </Grid>
            </Grid>
            <Grid container className="mb-3">
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.Address2)}</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={locationDetails?.address2}
                  defaultValue={""}
                  handleOnChange={e =>
                    handleChangeLocation(e, LOCATION_FIELD_MAP.ADDRESS_2)
                  }
                  fieldIndex={21}
                  bulkData={bulkDataWithNone}
                  FormControlClass="mb-0"
                />
              </Grid>
            </Grid>
            <Grid container className="mb-3">
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.Address3)}</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={locationDetails?.address3}
                  defaultValue={""}
                  handleOnChange={e =>
                    handleChangeLocation(e, LOCATION_FIELD_MAP.ADDRESS_3)
                  }
                  fieldIndex={22}
                  bulkData={bulkDataWithNone}
                  FormControlClass="mb-0"
                />
              </Grid>
            </Grid>
            <Grid container className="mb-3">
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.City)}*</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={locationDetails?.city}
                  defaultValue={""}
                  handleOnChange={e =>
                    handleChangeLocation(e, LOCATION_FIELD_MAP.CITY)
                  }
                  fieldIndex={23}
                  bulkData={bulkData}
                  FormControlClass="mb-0"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.State)}*</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={locationDetails?.state}
                  defaultValue={""}
                  handleOnChange={e =>
                    handleChangeLocation(e, LOCATION_FIELD_MAP.STATE)
                  }
                  fieldIndex={24}
                  bulkData={bulkData}
                  FormControlClass="mb-0"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="py-3">
              <Divider />
            </Grid>
            <Grid container>
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.Postal_Code)}*</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={locationDetails?.postalCode}
                  defaultValue={""}
                  handleOnChange={e =>
                    handleChangeLocation(e, LOCATION_FIELD_MAP.POSTAL_CODE)
                  }
                  fieldIndex={25}
                  bulkData={bulkData}
                  FormControlClass="mb-0"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="py-3">
              <Divider />
            </Grid>
            <Grid container>
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.Country)}*</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={locationDetails?.country}
                  defaultValue={""}
                  handleOnChange={e =>
                    handleChangeLocation(e, LOCATION_FIELD_MAP.COUNTRY)
                  }
                  fieldIndex={26}
                  bulkData={bulkData}
                  FormControlClass="mb-0"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="py-3">
              <Divider />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BulkUploadButtons
              previousBLabel={getTranslations(t, Translates.Previous)}
              handlePreviousClick={handlePreviousClick}
              cancelBLabel={getTranslations(t, Translates.Cancel)}
              handleCancelClick={handleCancelClick}
              nextBLabel={getTranslations(t, Translates.NextValidation)}
              handleNextClick={handleValidation}
              saveDisable={saveDisable}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default React.memo(LocationBulkDataMapping)
