import { useFormContext } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Divider, Grid } from "@mui/material"
import { Translates } from "src/i18n/i18n"
import { getTranslations, replaceAllMultiple } from "src/utils/helper"
import AceEditorField from "src/components/MUIFormFields/AceEditorField"
import { updateWarnOnTabChangeToStore } from "../../helper"
import { useAppThunkDispatch } from "src/redux/store"
import { getVariableBasedOnComponent } from "./Utils/Helper"
import FormHeading from "src/components/FormHeading"

const ReadOnlySQLEditor = ({
  fieldName,
  componentName
}: {
  fieldName: string
  componentName: string
}) => {
  const { t } = useTranslation<any>()
  const history = useHistory()
  const dispatch = useAppThunkDispatch()
  const { getValues } = useFormContext()
  const { id }: { id: string } = useParams()
  const storeId: number = id !== undefined ? Number(id) : -1 //want to get index of array means subtract 1

  let search = window.location.search || ""

  let { variableSetRedux, createEditPath } = getVariableBasedOnComponent(
    componentName,
    storeId
  )
  // istanbul ignore next
  const redirectToWriteSql = e => {
    updateWarnOnTabChangeToStore(false)
    dispatch(variableSetRedux(getValues()))

    setTimeout(() => {
      history.push(
        replaceAllMultiple(
          createEditPath,
          [":tab", ":tabId"],
          [componentName.toLocaleLowerCase(), `${storeId}`]
        ) + search,
        {
          variableRedux: getValues()
        }
      )
    })
  }
  return (
    <Grid item xs={12} sm={12} className="sqlEditor-readOnly">
      <Divider className="dashed my-4" />
      <FormHeading heading={`${getTranslations(t, Translates.SQL)} *`} />
      <AceEditorField
        inputAttributes={{
          name: fieldName,
          readOnly: true,
          onFocus: redirectToWriteSql
        }}
      />
    </Grid>
  )
}

export default ReadOnlySQLEditor
