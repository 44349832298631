import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SettingsService } from "src/service/settings.service"

export interface SettingsState {
  // selectedCustomerForOperation: string
  status: string
  locationsByStore: any
  locationsByAccount: any
  locationFilters: any
  locationFilterForm: any
  customers: any[]
  selectedCustomerForOperation: string
  selectedCustomerNameForOperation: string
  validLocationColumnList: string[]
  csvLocationColumnList: string[]
  locationMappingData: any
  locationFileData: any
  mappingResponseData: any
  mappingPayloadData: any
  mappingProgress: any
  bulkLocationFilePath: string
  companyDetails: any
  locationSort: any
  locationColumn: any
}

const initialState: SettingsState = {
  status: "",
  locationsByStore: {},
  locationsByAccount: [],
  locationFilters: {},
  locationFilterForm: {},
  customers: [],
  selectedCustomerForOperation: "",
  selectedCustomerNameForOperation: "",
  validLocationColumnList: [
    "Name",
    "Site ID",
    "Banner",
    "Phone",
    "Email",
    "Region 1",
    "Region 2",
    "Region 3",
    "Region 4",
    "Address 1",
    "Address 2",
    "Address 3",
    "City",
    "State",
    "Postal Code",
    "Country"
  ],
  csvLocationColumnList: [],
  locationMappingData: {},
  locationFileData: [""],
  mappingResponseData: {},
  mappingPayloadData: {},
  mappingProgress: 0,
  bulkLocationFilePath: "",
  companyDetails: {},
  locationSort: "",
  locationColumn: ""
}
const settingsService = new SettingsService()

export const getlocationsByAccount = createAsyncThunk(
  "settingsList/getlocationsByAccount",
  async (params: any) => {
    let customerId = params.customerId
    return settingsService.getalllocationsbyaccount(customerId)
  }
)

export const settingsSlice = createSlice({
  name: "settingsList",
  initialState,
  reducers: {
    setLocationFilters: (state, _action: PayloadAction<any>) => {
      state.locationFilters = { ...state.locationFilters, ..._action.payload }
    },
    setLocationFilterForm: (state, _action: PayloadAction<any>) => {
      state.locationFilterForm = {
        ...state.locationFilterForm,
        ..._action.payload
      }
    },
    setCustomers: (state, action: PayloadAction<any>) => {
      state.customers = [...action.payload]
    },
    setCustomerForOperation: (state, action: PayloadAction<any>) => {
      state.selectedCustomerForOperation = action.payload
    },
    setCustomerNameForOperation: (state, action: PayloadAction<any>) => {
      state.selectedCustomerNameForOperation = action.payload
    },
    setCSVLocationColumnList: (state, _action: PayloadAction<any>) => {
      state.csvLocationColumnList = [..._action.payload]
    },
    setLocationMappingData: (state, _action: PayloadAction<any>) => {
      state.locationMappingData = { ..._action.payload }
    },
    setLocationFileData: (state, _action: PayloadAction<any>) => {
      state.locationFileData = _action.payload
    },
    setBulkLocationFile: (state, _action: PayloadAction<any>) => {
      state.bulkLocationFilePath = _action.payload
    },
    setLocationReset: () => initialState,
    setMappingResponseData: (state, _action: PayloadAction<any>) => {
      state.mappingResponseData = _action.payload
    },
    setMappingProgress: (state, _action: PayloadAction<any>) => {
      state.mappingProgress = _action.payload
    },
    setMappingPayloadData: (state, _action: PayloadAction<any>) => {
      state.mappingPayloadData = _action.payload
    },
    setCompanyDetails: (state, _action: PayloadAction<any>) => {
      state.companyDetails = _action.payload
    },
    setLocationSort: (state, _action: PayloadAction<any>) => {
      state.locationSort = _action.payload
    },
    setLocationColumn: (state, _action: PayloadAction<any>) => {
      state.locationColumn = _action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getlocationsByAccount.pending, state => {
      state.status = "loading"
    })
    //istanbul ignore next
    builder.addCase(getlocationsByAccount.fulfilled, (state, { payload }) => {
      state.status = "success"
      state.locationsByAccount = payload.data
    })
    builder.addCase(getlocationsByAccount.rejected, state => {
      state.status = "failed"
    })
  }
})
export const {
  setLocationFilters,
  setLocationFilterForm,
  setCustomers,
  setCustomerForOperation,
  setCustomerNameForOperation,
  setCSVLocationColumnList,
  setLocationMappingData,
  setLocationFileData,
  setBulkLocationFile,
  setLocationReset,
  setMappingResponseData,
  setMappingPayloadData,
  setMappingProgress,
  setCompanyDetails,
  setLocationSort,
  setLocationColumn
} = settingsSlice.actions

export default settingsSlice.reducer
