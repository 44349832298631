import shortid from "shortid"
import { getBreadcrumbConfig } from "../helper"
import { setIfElseLayout, setLayout } from "./layout.service"
import {
  braces,
  functionTypes,
  sideBarItemKeys,
  SIDEBAR_ITEMS,
  utilTypes
} from "./LRConstants"
import { PATH } from "src/constants/paths"
import { getTranslations } from "src/utils/helper"
import { CONFIG_MODULES } from "src/utils/constants"
import { ruleEditorFunctions } from "./LRConstants/functionNames"
import {
  booleanError,
  booleanErrorMessage,
  sourceField
} from "./LRConstants/booleanFunctions"
import {
  currentFieldOptions,
  sourceForAuditRecordLevelOptions,
  sourceForAuditTagLevelOptions
} from "./LRConstants/fieldOptions"

import { Translates } from "src/i18n/i18n"
// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed) // inserting task in new index

  return result
}

export const remove = (arr, index) => [
  ...arr.slice(0, index),
  ...arr.slice(index + 1)
]

export const insert = (arr, index, newItem) => {
  return [
    ...arr.slice(0, index),
    newItem,
    ...([functionTypes.If, functionTypes.Braces].includes(newItem?.function)
      ? [
          {
            id: newItem.id,
            type: "row",
            content: ")",
            function: newItem?.function,
            block: "ends"
          }
        ]
      : []),
    ...arr.slice(index)
  ]
}

export const handleMoveSidebarComponentIntoParent = (
  layout,
  splitDropZonePath,
  item
) => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0])
    return insert(layout, dropZoneIndex, item)
  }
  return layout
}

export const ifElseToReadable = (layout: any, finalStr: string = "") => {
  if (ArrayConditionCheck(layout)) {
    let IfCondition: string = ""
    let BraceCondition: string = ""
    let withinBraceCondition: boolean = false
    layout.forEach((item, inx) => {
      if (
        [functionTypes.If, functionTypes.Braces].includes(item.function) &&
        typeof item.content === "object"
      ) {
        if ([functionTypes.If].includes(item.function)) {
          finalStr += item.function.toUpperCase() + " "

          IfCondition = item.condition
        } else {
          finalStr +=
            ([functionTypes.If].includes(layout[inx - 1].function)
              ? ""
              : IfCondition) + " ( "
          BraceCondition = item.condition
          withinBraceCondition = true
        }
      } else if (
        [functionTypes.Braces].includes(item.function) &&
        item.content === ")"
      ) {
        finalStr += item.content + " "

        withinBraceCondition = false
      } else if (
        [functionTypes.If].includes(item.function) &&
        item.content === ")"
      ) {
        finalStr = finalStr + ""
      } else if (
        item.function &&
        ![functionTypes.If, functionTypes.Braces].includes(item.function)
      ) {
        const LocCon = withinBraceCondition ? BraceCondition : IfCondition
        const locCondition =
          [functionTypes.If, functionTypes.Braces].includes(
            layout[inx - 1].function
          ) && typeof layout[inx - 1].content === "object"
            ? ""
            : LocCon
        finalStr += `${locCondition} ${
          item?.functionSource ? item.functionSource + "." : ""
        }${item.content} `

        if (ArrayConditionCheck(item.parameters)) {
          let params = item.parameters.filter(i => i || i === 0)
          params.forEach((element, inxE) => {
            //istanbul ignore next
            if (ArrayConditionCheck(element, false)) {
              finalStr += element.join(",") + " "
            } else {
              finalStr += element + " "
            }
            if (inxE !== params?.length - 1) {
              finalStr += ", "
            }
          })
        }
      }
    })
  }
  return finalStr
}

const all_functions = SIDEBAR_ITEMS.map((item, inx) =>
  item[Object.keys(item)[0]].map((item1, inx1) => item1)
).reduce(function (pre, cur) {
  return pre.concat(cur)
})
export const LookupConfigToEditor = (Instructions, isConditionOnly = false) => {
  let parentArray: any = []

  if (ArrayConditionCheck(Instructions)) {
    parentArray = FunctionConditionCheck(
      parentArray,
      Instructions,
      isConditionOnly
    )

    return parentArray
  }
  return []
}
const FunctionConditionCheck = (
  parentArray,
  forEachArray,
  isConditionOnly = false
) => {
  if (ArrayConditionCheck(forEachArray)) {
    if (!isConditionOnly) {
      forEachArray.forEach(item1 => {
        if ([functionTypes.If].includes(item1.Function)) {
          const id_gen = shortid.generate()
          parentArray = IfConditionFunction(parentArray, item1, id_gen)
          parentArray = IfTrueFunction(parentArray, item1)
          parentArray = [
            ...parentArray,
            {
              function: functionTypes.Else,
              id: id_gen,
              isExpandMore: true,
              content: functionTypes.Else
            }
          ]
          parentArray = ElseFunction(parentArray, item1)
          parentArray = [
            ...parentArray,
            {
              function: functionTypes.Endif,
              id: id_gen,
              content: functionTypes.Endif
            }
          ]
        } else {
          parentArray = NotIfFunction(parentArray, item1)
        }
      })
    } else {
      const id_gen = shortid.generate()
      parentArray = IfConditionFunction(
        parentArray,
        { Function: functionTypes.If, Condition: forEachArray },
        id_gen
      )
      parentArray = [
        ...parentArray,
        {
          function: functionTypes.Else,
          id: id_gen,
          isExpandMore: true,
          content: functionTypes.Else
        },
        {
          function: functionTypes.Endif,
          id: id_gen,
          content: functionTypes.Endif
        }
      ]
    }
  }
  return parentArray
}
const IfConditionFunction = (parentArray, configItem, id_gen) => {
  let nestedArray: any = []
  all_functions.forEach(item1 => {
    if (configItem.Function === item1.function) {
      nestedArray = BraceFunctionStartToCall(
        nestedArray,
        item1,
        configItem.Condition,
        item1.function
      )
      return true
    }
  })
  parentArray.push({
    function: configItem.Function,
    array: nestedArray,
    id: id_gen,
    isExpandMore: true,
    content: ifElseToReadable(nestedArray)
  })
  return parentArray
}
const IfTrueFunction = (parentArray, configItem) => {
  if (ArrayConditionCheck(configItem.IfTrue)) {
    parentArray = FunctionConditionCheck(parentArray, configItem.IfTrue)
  }
  return parentArray
}
const ElseFunction = (parentArray, configItem) => {
  if (ArrayConditionCheck(configItem.Else)) {
    parentArray = FunctionConditionCheck(parentArray, configItem.Else)
  }
  return parentArray
}
const BraceFunctionStartToCall = (
  nestedArray,
  item,
  forEachArray,
  functionType
) => {
  if (ArrayConditionCheck(forEachArray)) {
    const id_gen = shortid.generate()
    forEachArray.forEach((item1, inx1) => {
      nestedArray = BraceFunction(
        nestedArray,
        item1,
        item,
        functionType,
        id_gen
      )
      if (["OR", "AND"].includes(forEachArray[inx1 - 1])) {
        nestedArray.push({
          ...item,
          ...{
            function: functionType,
            label: functionType,
            isExpandMore: true,
            content: ")",
            type: "row",
            id: id_gen
          }
        })
      }
    })
  }
  return nestedArray
}
const BraceFunction = (nestedArray, configItem, item, type, id_gen) => {
  const funcType =
    type === functionTypes.If ? functionTypes.If : functionTypes.Braces
  if (["OR", "AND"].includes(configItem)) {
    nestedArray.push({
      ...item,
      ...{
        function: funcType,
        label: funcType,
        condition: configItem,
        content: {},
        type: "row",
        id: id_gen
      }
    })
  } else if (ArrayConditionCheck(configItem)) {
    nestedArray = BraceFunctionStartToCall(
      nestedArray,
      item,
      configItem,
      functionTypes.Braces
    )
  } else if (
    typeof configItem === "object" &&
    !ArrayConditionCheck(configItem)
  ) {
    nestedArray = NotIfFunction(nestedArray, configItem)
  }

  return nestedArray
}
const NotIfFunction = (parentArray, configItem) => {
  const lastIndex = configItem?.Function?.lastIndexOf(".")
  const functionSource = configItem?.Function?.substring(0, lastIndex)
  const functionName = configItem?.Function?.substring(lastIndex + 1)

  all_functions.forEach(item => {
    if (item?.function === functionName) {
      parentArray.push({
        ...item,
        ...{
          id: shortid.generate(),
          parameters: configItem.Parameters,
          type: "row",
          ...(configItem?.OnFail && {
            OnFail: configItem?.OnFail,
            OnFailMessage: configItem?.OnFailMessage
          }),
          ...(functionSource && {
            functionSource: functionSource,
            functionStore: configItem?.Function
          })
        }
      })
      return true
    }
  })

  return parentArray
}
const ArrayConditionCheck = (DataArray, isEmptyCheck = true) => {
  if (isEmptyCheck) {
    return DataArray && Array.isArray(DataArray) && DataArray.length
  }
  return DataArray && Array.isArray(DataArray)
}
export const margin_left_find_for_row = (row, margin_left) => {
  const r_c_type_object = typeof row?.content === "object"
  const r_c__if_array = row?.array ? true : false
  const r_c_close_brace = row?.content === ")"

  const if_start =
    row?.function === functionTypes.If && (r_c_type_object || r_c__if_array)
  const if_end =
    (row?.function === functionTypes.If && r_c_close_brace) ||
    row?.function === functionTypes.Endif
  const brace_start = row?.function === functionTypes.Braces && r_c_type_object
  const brace_end = row?.function === functionTypes.Braces && r_c_close_brace

  if (if_start || brace_start) {
    margin_left += 1
  }
  if (if_end || brace_end) {
    margin_left -= 1
  }
  return {
    ml: margin_left,
    start: if_start || brace_start || row?.function === functionTypes.Else
  }
}

//editor to config conversion

export function LookupEditorToConfig(lookup) {
  if (ArrayConditionCheck(lookup)) {
    let overAllArray: any = []
    let IfTrueCount: number = 0
    let IfTrueCountMax: number = 0
    lookup.forEach(obj => {
      if (
        obj.function !== functionTypes.If &&
        obj.function !== functionTypes.Else &&
        obj.function !== functionTypes.Endif
      ) {
        let func = obj.function
        let params = obj.parameters
        const locFucObject: any = {
          Function:
            obj?.functionType === ruleEditorFunctions.booleanFn &&
            obj.functionStore
              ? obj.functionStore
              : func,
          ...(params?.length > 0 && { Parameters: params }),
          ...(obj?.functionType === ruleEditorFunctions.booleanFn &&
            obj?.OnFail && {
              OnFail: obj?.OnFail,
              OnFailMessage: obj?.OnFailMessage
            })
        }
        overAllArray.push(locFucObject)
      } else if (
        obj.function === functionTypes.If &&
        obj.array &&
        Array.isArray(obj.array)
      ) {
        IfTrueCount++
        if (IfTrueCount > IfTrueCountMax) {
          IfTrueCountMax = IfTrueCount
        }
        overAllArray.push(`IfCondition-${IfTrueCount}`)
        let ifElseAr = obj.array
        let ifElseFunc = obj.function
        let ifElseObj: any = {}
        ifElseObj = generateIfElseObj(ifElseAr, ifElseFunc)
        const { Condition } = ifElseObj
        let finalConfigObj = generateConfigObj(Condition)
        ifElseObj = { ...ifElseObj, ...{ Condition: finalConfigObj } }
        overAllArray.push(ifElseObj)
        overAllArray.push(`IfTrue-${IfTrueCount}`)
      } else if (obj.function === functionTypes.Else) {
        overAllArray.push(`-IfTrue-${IfTrueCount}`)
        overAllArray.push(`Else-${IfTrueCount}`)
      } else if (obj.function === functionTypes.Endif) {
        overAllArray.push(`-Else-${IfTrueCount}`)
        overAllArray.push(`-IfCondition-${IfTrueCount}`)
        IfTrueCount--
      }
    })
    return overAllRecurObj(overAllArray, IfTrueCountMax)
  }
  return []
}

function generateIfElseObj(arr, func) {
  let ifElseInstructions = { Function: func, Condition: [] }
  const { Condition }: { Condition: any } = ifElseInstructions
  let updatedArr: any = []
  let count = 0

  for (let row of arr) {
    if (row.function === functionTypes.If && row.content !== ")") {
      Condition.push(row.condition)
    } else if (row.function === functionTypes.Braces && row.content !== ")") {
      updatedArr.push(row.condition)
      count++
      updatedArr.push(count)
    } else if (row.content === ")" && count >= 1) {
      updatedArr.push(count * -1)
      count--
    } else if (row.function !== functionTypes.Braces && row.content !== ")") {
      updatedArr.push({
        Function:
          row?.functionType === ruleEditorFunctions.booleanFn &&
          row?.functionStore
            ? row.functionStore
            : row?.function,
        Parameters: row.parameters
      })
    }
  }
  Condition.push(updatedArr)
  return ifElseInstructions
}

function generateConfigObj(ifElseCond) {
  let ConfigConditions: any = []
  ifElseCond.forEach(data => {
    if (Array.isArray(data)) {
      let count = getCount(data)
      let innerObj = recurObj(data, count)
      ConfigConditions.push(innerObj)
    } else {
      ConfigConditions.push(data)
    }
  })
  return ConfigConditions
}

function getCount(arr) {
  let maxCount = 0
  for (let val of arr) {
    if (typeof val === "number") {
      maxCount = Math.max(maxCount, val)
    }
  }
  return maxCount
}

function recurObj(arr, initCount) {
  if (initCount === 0) return arr

  let conditionWhileLoop: boolean = true
  while (conditionWhileLoop) {
    let startIndex = arr.indexOf(initCount)
    if (startIndex !== -1) {
      let endIndex = arr.indexOf(initCount * -1)
      let sliceArr = arr.slice(startIndex + 1, endIndex)
      arr.splice(startIndex, endIndex - startIndex + 1, sliceArr)
    } else {
      conditionWhileLoop = false
    }
  }

  return recurObj(arr, --initCount)
}

function overAllRecurObj(arr, initCount) {
  if (initCount === 0) return arr

  let conditionWhileLoop: boolean = true
  let newArr: any = arr
  while (conditionWhileLoop) {
    let conditionStartIndex = newArr.indexOf(`IfCondition-${initCount}`)
    if (conditionStartIndex !== -1) {
      let conditionEndIndex = newArr.indexOf(`-IfCondition-${initCount}`)
      let IfTrueStartIndex = newArr.indexOf(`IfTrue-${initCount}`)
      let IfTrueEndIndex = newArr.indexOf(`-IfTrue-${initCount}`)
      let sliceArr = newArr.slice(IfTrueStartIndex + 1, IfTrueEndIndex)
      if (sliceArr.length) {
        newArr[conditionStartIndex + 1].IfTrue = sliceArr
      }

      let ElseStartIndex = newArr.indexOf(`Else-${initCount}`)
      let ElseEndIndex = newArr.indexOf(`-Else-${initCount}`)
      sliceArr = newArr.slice(ElseStartIndex + 1, ElseEndIndex)
      if (sliceArr.length) {
        newArr[conditionStartIndex + 1].Else = sliceArr
      }

      newArr = newArr.filter(
        (j, i) =>
          !(
            (IfTrueStartIndex <= i && i <= IfTrueEndIndex) ||
            (ElseStartIndex <= i && i <= ElseEndIndex) ||
            [conditionStartIndex, conditionEndIndex].includes(i)
          )
      )
    } else {
      conditionWhileLoop = false
    }
  }

  return overAllRecurObj(newArr, --initCount)
}

export function getAllIndexes(arr, val, arrayOfObject, key) {
  let indexes: number[] = []
  for (let i = 0; i < arr.length; i++) {
    if (arrayOfObject) {
      if (arr[i][key] === val) {
        indexes.push(i)
      }
    } else {
      if (arr[i] === val) {
        indexes.push(i)
      }
    }
  }
  return indexes
}
const functionNamesInUpperCase = ["IF", "ELSE"]
export const prepareIfTextJsx = (content: string) => {
  let fNames = Object.keys(functionTypes)
  let uNames = Object.keys(utilTypes)
  let contentSplit: any = content.split(" ")
  contentSplit = contentSplit.map(c => {
    if (fNames.includes(c) || functionNamesInUpperCase.includes(c))
      return <span style={{ color: "red" }}>{c} </span>
    else if (uNames.includes(c))
      return <span style={{ color: "#006600" }}>{c} </span>
    else return <span style={{ color: "blue" }}>{c} </span>
  })
  return <>{contentSplit}</>
}
const tabSpaces: number = 30
export const renderRowLayoutChildren = data => {
  let fn = data?.content

  let isRedColorText = [
    functionTypes.If,
    functionTypes.Else,
    functionTypes.Endif,
    functionTypes.Braces
  ].includes(data?.function)
  let content = isRedColorText
    ? data.content
    : data?.parameters
        ?.filter(i => i || i === 0 || i === false)
        ?.map(j => {
          let param = j
          if (Array.isArray(j) && (j[0]?.["en-us"] || j[0]?.["en-us"] === "")) {
            param = j[0]?.["en-us"]
          }
          return param
        })
        .join(",")

  if (data?.functionType === ruleEditorFunctions.booleanFn) {
    if (data?.OnFail) {
      content = `${content}${content ? "," : ""}${data?.OnFail},${
        data?.OnFailMessage[0]?.["en-us"]
      }`
    } else if (data?.functionSource) {
      fn = `${data?.functionSource ? data?.functionSource + "." : ""}${fn}`
    }
  }
  if (data?.function === functionTypes.If && data?.array && content) {
    content = prepareIfTextJsx(content)
  }
  let ml = data.ml ? Math.floor(data.ml * tabSpaces) : 0
  return (
    <span className="row-content" style={{ marginLeft: `${ml}px` }}>
      {!isRedColorText && (
        <>
          <span style={{ color: "blue" }}>{fn}</span>&nbsp;
        </>
      )}
      <span style={{ color: "red" }}>{content}</span>
    </span>
  )
}

export const makeLayoutAllVariableEmpty = () => {
  setIfElseLayout([])
  setLayout([])
}

export const getBreadcrumbPath = (storeId: number, t, nextPage = false) => {
  let componentName =
    storeId !== -1
      ? getTranslations(t, Translates.Edit_lookup_routines)
      : getTranslations(t, Translates.Create_lookup_routines)

  const { bConfig } = getBreadcrumbConfig({
    landingName: getTranslations(t, Translates.Lookup_routines),
    landingPath: PATH.CONFIG_LOOKUP_ROUTINES.slice(1),
    componentName: componentName,
    nextPage: nextPage,
    storeId: storeId,
    createPath: PATH.CONFIG_NEW_LOOKUP_ROUTINE,
    editPath: PATH.CONFIG_NEW_LOOKUP_ROUTINE
  })
  return { componentName, bConfig }
}

export const getSideBarItems = (isIfElseState: boolean, component: string) => {
  let sideBarItems: any = []
  if (isIfElseState) {
    SIDEBAR_ITEMS.forEach(item => {
      if (Object.keys(item)[0] === sideBarItemKeys.booleanFunctions) {
        let key = Object.keys(item)[0]
        sideBarItems = sideBarItems.concat(item[key])
      }
    })
    sideBarItems = [{ IfElse: [...[braces], ...sideBarItems] }]
  } else {
    if (component === CONFIG_MODULES.LookupRoutines) {
      sideBarItems = SIDEBAR_ITEMS.filter(i =>
        [
          sideBarItemKeys.lookupFunctions,
          sideBarItemKeys.ifElse,
          sideBarItemKeys.stringFunctions
        ].includes(Object.keys(i)[0])
      ).map(i1 => {
        if ([sideBarItemKeys.lookupFunctions].includes(Object.keys(i1)[0])) {
          const booleanFns: any = i1[Object.keys(i1)[0]]
          const filterFn: any = booleanFns?.filter(
            i2 => i2.function !== functionTypes.LookupRoutine
          )
          return { [Object.keys(i1)[0]]: filterFn }
        }
        return i1
      })
    } else if (component === CONFIG_MODULES.CountPrograms.setup) {
      sideBarItems = SIDEBAR_ITEMS.filter(i =>
        [sideBarItemKeys.inputSetupFunctions, sideBarItemKeys.ifElse].includes(
          Object.keys(i)[0]
        )
      )
    } else if (
      [
        CONFIG_MODULES.CountPrograms.validation,
        CONFIG_MODULES.CountFieldInstructions.validation
      ].includes(component)
    ) {
      sideBarItems = SIDEBAR_ITEMS.filter(i =>
        [
          sideBarItemKeys.stringFunctions,
          sideBarItemKeys.booleanFunctions,
          sideBarItemKeys.ifElse
        ].includes(Object.keys(i)[0])
      )
    } else if (
      [
        CONFIG_MODULES.CountPrograms.Lookup,
        CONFIG_MODULES.CountFieldInstructions.Lookup
      ].includes(component)
    ) {
      sideBarItems = SIDEBAR_ITEMS.filter(i =>
        [
          sideBarItemKeys.lookupFunctions,
          sideBarItemKeys.ifElse,
          sideBarItemKeys.stringFunctions
        ].includes(Object.keys(i)[0])
      ).map(i1 => {
        if ([sideBarItemKeys.lookupFunctions].includes(Object.keys(i1)[0])) {
          const booleanFns: any = i1[Object.keys(i1)[0]]
          const filterFn: any = booleanFns?.filter(i2 =>
            [functionTypes.LookupRoutine, functionTypes.Error].includes(
              i2.function
            )
          )
          return { [Object.keys(i1)[0]]: filterFn }
        }
        return i1
      })
    } else if (
      [CONFIG_MODULES.Audits, CONFIG_MODULES.AuditRule].includes(component)
    ) {
      sideBarItems = SIDEBAR_ITEMS.filter(i =>
        [sideBarItemKeys.ifElse].includes(Object.keys(i)[0])
      )
    }
  }
  return { sideBarItems }
}

export const booleanFunctionFieldAdding = (
  item: any,
  isIfElseState: boolean,
  component: string,
  isRecordLevelAudit
) => {
  let itemCopy: any = {}
  if (
    [
      CONFIG_MODULES.CountPrograms.setup,
      CONFIG_MODULES.CountPrograms.validation,
      CONFIG_MODULES.CountPrograms.Lookup,
      CONFIG_MODULES.Audits,
      CONFIG_MODULES.AuditRule,
      CONFIG_MODULES.CountFieldInstructions.validation,
      CONFIG_MODULES.CountFieldInstructions.Lookup
    ].includes(component)
  ) {
    if (item?.functionType === ruleEditorFunctions.booleanFn) {
      itemCopy = JSON.parse(JSON.stringify(item))
      itemCopy.inputType = item.inputType?.filter(
        i =>
          ![
            sourceField.name,
            booleanError.name,
            booleanErrorMessage.name
          ].includes(i.name)
      )
      if (isIfElseState) {
        let newOptions = sourceField.options
        if (
          [CONFIG_MODULES.Audits, CONFIG_MODULES.AuditRule].includes(component)
        ) {
          newOptions = sourceForAuditTagLevelOptions
          if (isRecordLevelAudit) {
            newOptions = sourceForAuditRecordLevelOptions
          }
        }

        const sourceChangeInput = {
          ...sourceField,
          ...{
            options: [...currentFieldOptions, ...newOptions],
            validation: {
              ...sourceField.validation,
              ...{
                oneOf: [
                  ...[currentFieldOptions[0].value],
                  ...[...newOptions].map(i => i.value)
                ]
              }
            }
          }
        }
        if (itemCopy?.inputType) {
          itemCopy?.inputType.unshift(sourceChangeInput)
        } else {
          itemCopy.inputType = [sourceChangeInput]
        }
      } else {
        if (itemCopy.inputType) {
          itemCopy.inputType.push(booleanError, booleanErrorMessage)
        } else {
          itemCopy.inputType = [booleanError, booleanErrorMessage]
          itemCopy.parameters = []
        }
      }
    }
  }

  return Object.keys(itemCopy).length ? { itemCopy } : { itemCopy: item }
}

export const ruleEditorTitle = (component: string, t) => {
  let title = getTranslations(t, Translates.Lookup_Routines_Instructions)
  if (component === CONFIG_MODULES.CountPrograms.setup) {
    title = getTranslations(t, Translates.Setup_Instructions)
  } else if (component === CONFIG_MODULES.CountPrograms.validation) {
    title = getTranslations(t, Translates.Validation_Instructions)
  } else if (component === CONFIG_MODULES.CountPrograms.Lookup) {
    title = getTranslations(t, Translates.Lookup_Instructions)
  } else if (
    [CONFIG_MODULES.Audits, CONFIG_MODULES.AuditRule].includes(component)
  ) {
    title = getTranslations(t, Translates.Audit_Conditions)
  } else if (
    [
      CONFIG_MODULES.CountFieldInstructions.validation,
      CONFIG_MODULES.CountFieldInstructions.Lookup
    ].includes(component)
  ) {
    title = getTranslations(t, Translates.Count_Field_Instructions)
  }

  return { title }
}
