import { getBreadcrumbConfig } from "src/configurationPortal/pages/helper"
import {
  ConfigSkipSections,
  ConfigurationStoreTypeKeys
} from "src/constants/configurationPortal"
import { PATH } from "src/constants/paths"
import { getTranslations } from "src/utils/helper"

export const configFileValidationCheck = (
  dt: any
): { errorCount: number; error: string } => {
  let errorCount: number = 0
  let error: string = ""
  const BreakException = {}
  try {
    Object.keys(dt).forEach(i => {
      if (!ConfigSkipSections.includes(i) && typeof dt[i] === "object") {
        if (Array.isArray(dt[i])) {
          if (!dt[i].length) {
            errorCount++
          }
        } else if (!Array.isArray(dt[i])) {
          if (
            i === ConfigurationStoreTypeKeys.FullStoreVariances ||
            i === ConfigurationStoreTypeKeys.GroupedVariances
          ) {
            if (
              !Object.keys(dt.FullStoreVariances).length &&
              !Object.keys(dt.GroupedVariances).length
            ) {
              errorCount++
            }
          } else {
            if (!Object.keys(dt[i]).length) {
              errorCount++
            }
          }
        }
      }

      if (errorCount > 0) {
        error = `Configuration "${i}" can not be empty`
        throw BreakException
      }
    })
  } catch (e) {
    //istanbul ignore next
    if (e !== BreakException) throw e
  }

  return { errorCount, error }
}
export const getTabValue = () => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get("tab")
}

export const getColumns = (selectedCustomerList, columnsData) => {
  if (selectedCustomerList && selectedCustomerList.length > 1) {
    return columnsData
  }
  if (columnsData[0].Header === "CUSTOMER") columnsData.shift()
  return columnsData
}

export const getConfigHistoryBreadcrumbPath = (t, Translates) => {
  let componentName = getTranslations(t, Translates.History)
  const { bConfig } = getBreadcrumbConfig({
    landingName: getTranslations(t, Translates.Configuration),
    landingPath: `${PATH.CONFIGURATION}`.slice(1),
    componentName: componentName,
    showHomeIcon: true
  })
  return { componentName, bConfig }
}
