import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import BreadCrumb from "src/components/BreadCrumb/BreadCrumb"
import { PATH } from "src/constants/paths"
import { Loader } from "src/utils/reusables/reusable"
import CheckIcon from "@mui/icons-material/Check"
import {
  Customer_Fields,
  Customer_Input_Fields,
  FieldDelimiterList,
  FileTypeRadioValueList,
  INPUT_FILE_ACTION_TYPE,
  INPUT_FILE_TYPE,
  RecordDelimiterList
} from "src/constants/inputFiles"
import { useDispatch, useSelector } from "react-redux"
import {
  setCustomerFormValues,
  setDelimitedInputFields,
  setExcelInputFields,
  setFixedLengthInputFields,
  setInputFiles
} from "src/redux/slices/configSlice"
import { RootState } from "src/redux/store"
import {
  getTranslations,
  handleFocus,
  setWeekFieldError
} from "src/utils/helper"
import GlobalTableDragDrop from "src/components/GlobalTable/GlobalTableDragDrop"
import ConfirmDialog from "src/components/ConfirmDialog/ConfirmDialog"
import editIcon from "src/assets/images/Edit.png"
import TrashIcon from "src/assets/images/TrashIcon.svg"
import Warning from "@mui/icons-material/Warning"
import AddIcon from "@mui/icons-material/Add"
import { updateWarnOnTabChangeToStore } from "../helper"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
interface IForm {
  Name: string
  Description: string
  LegacyLocation: string
  CustomerPortalCheck: boolean
  InStoreCheck: boolean
  FileName: string
  Position: string
  FileType: string
  HeaderRow: boolean
  FieldDelimiter: string
  RecordDelimiter: string
  SurroundCharacter: string
  Fields: any
  ASCIICharValue: any
}
const CreateEditInputFileCustomer = () => {
  const { t } = useTranslation<any>()
  const configData = useSelector((state: RootState) => state.config)
  const configTypeFromStore = useSelector(
    (state: RootState) => state.config?.configType
  )
  const [loading, setLoading] = useState(false)
  const [duplicateNameError, setDuplicateNameError] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  let search = window.location.search
  const [form, setForm] = React.useState<IForm>({
    Name: "",
    Description: "",
    LegacyLocation: "",
    CustomerPortalCheck: false,
    InStoreCheck: false,
    FileName: "",
    Position: "",
    FileType: "",
    HeaderRow: false,
    FieldDelimiter: "",
    RecordDelimiter: "",
    SurroundCharacter: "",
    Fields: [],
    ASCIICharValue: ""
  } as IForm)
  const location: any = useLocation()
  let locationState: any = { ...location.state }
  const [helperText, setHelperText] = React.useState("")
  const [fileTypeError, setFileTypeError] = React.useState("")
  const [nameError, setNameError] = useState(false)
  const [recordDelimiterError, setRecordDelimiterError] = useState(false)
  const [emptyError, setEmptyError] = useState(false)
  const [deleteRow, setDeleteRow] = useState({} as any)
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [weekIVFields, setWeekIVFields] = useState({
    Name: ""
  })
  const fixedLengthInputFields = useSelector(
    (state: RootState) => state.config?.fixedLengthInputFields
  )
  const delimitedInputFields = useSelector(
    (state: RootState) => state.config?.delimitedInputFields
  )
  const excelInputFields = useSelector(
    (state: RootState) => state.config?.excelInputFields
  )
  const customerFormValues = useSelector(
    (state: RootState) => state.config?.customerFormValues
  )
  let columnHeadersList: any
  const [inputFieldValue, setInputFieldValue] = React.useState("")
  const [inputRecordValue, setInputRecordValue] = React.useState("")
  const [surroundCharacterValue, setSurroundCharacterValue] = React.useState("")
  const surroundCharacterValues = ['""', "''"]
  useEffect(() => {
    setLoading(true)

    if (
      !fixedLengthInputFields?.length &&
      locationState.data?.Fields?.length &&
      locationState.data.FileType === FileTypeRadioValueList[0].value
    ) {
      dispatch(setFixedLengthInputFields(locationState.data.Fields))
    }
    if (
      !delimitedInputFields?.length &&
      locationState.data?.Fields?.length &&
      locationState.data.FileType === FileTypeRadioValueList[1].value
    ) {
      dispatch(setDelimitedInputFields(locationState.data.Fields))
    }
    if (
      !excelInputFields?.length &&
      locationState.data?.Fields?.length &&
      locationState.data.FileType === FileTypeRadioValueList[2].value
    ) {
      dispatch(setExcelInputFields(locationState.data.Fields))
    }
    if (customerFormValues && Object.keys(customerFormValues).length) {
      setForm({
        ...customerFormValues
      })
    } else {
      if (locationState.type === INPUT_FILE_ACTION_TYPE.EDIT) {
        setForm({
          ...locationState.data
        })
      }
    }
    setLoading(false)
  }, [customerFormValues])

  const onFormChange = e => {
    window.onbeforeunload = () => true
    updateWarnOnTabChangeToStore(true)
    const val = e.target.value
    const checked = e.target.checked
    if (e.target.name) {
      setForm({ ...form, [e.target.name]: val })
    }
    if (e.target.name === Customer_Fields.CustomerPortalCheck) {
      setHelperText("")
      setForm({ ...form, [e.target.name]: checked })
    }
    if (e.target.name === Customer_Fields.InStoreCheck) {
      setHelperText("")
      setForm({ ...form, [e.target.name]: checked })
    }
    if (e.target.name === Customer_Fields.Name) {
      setDuplicateNameError(false)
      if (val) {
        setEmptyError(false)
        setNameError(false)
      } else {
        setNameError(true)
        setEmptyError(true)
      }
      setWeekFieldError({ setWeekIVFields, val, t, field: e.target.name })
    }

    if (e.target.name === Customer_Fields.FileType) {
      setFileTypeError("")
      if (val === FileTypeRadioValueList[0].value) {
        columnHeadersList = columnHeadersList.splice(2, 2)
      }
    }
    if (e.target.name === Customer_Fields.HeaderRow) {
      setForm({ ...form, [e.target.name]: checked })
    }
    if (e.target.name === Customer_Fields.ASCIICharValue) {
      setRecordDelimiterError(false)
    }
  }
  const onFormDelimitedChange = e => {
    setForm({ ...form, FieldDelimiter: e })
  }
  const onFormRecordChange = e => {
    setRecordDelimiterError(false)
    setForm({ ...form, RecordDelimiter: e })
  }
  const onFormSurroundCharacterChange = e => {
    setForm({ ...form, SurroundCharacter: e })
  }
  const getLookupBreadcrumb = () => {
    if (locationState.type === INPUT_FILE_ACTION_TYPE.EDIT) {
      return `${locationState.data?.Name}`
    } else {
      return getTranslations(t, Translates.New_Customer_File)
    }
  }
  const getBreadcrumbConfig = () => {
    let inputFiles = "inputFiles"
    let custLinks: any = {}
    custLinks["@inputFiles"] = "configuration/inputFiles"
    let newCustomerFile = getTranslations(t, Translates.New_Customer_File)
    let currentPath: string = `##configuration##/@${inputFiles}/${newCustomerFile}`
    let variables: any = {}
    variables[`@${inputFiles}`] = getTranslations(t, Translates.Input_Files)
    variables[`${newCustomerFile}`] = getLookupBreadcrumb()
    let bConfig: any = {
      custLinks,
      showHomeIcon: false,
      currentPath,
      mapObj: variables
    }
    return bConfig
  }
  const onCancelClick = () => {
    updateWarnOnTabChangeToStore(false)
    dispatch(setFixedLengthInputFields([]))
    dispatch(setDelimitedInputFields([]))
    dispatch(setExcelInputFields([]))
    dispatch(setCustomerFormValues({}))
    history.goBack()
  }
  const onSaveClick = () => {
    const inputFiles = configData?.inputFiles || []
    let _item = {
      Name: form.Name,
      Description: form.Description,
      LegacyLocation: form.LegacyLocation,
      CustomerPortalCheck: form.CustomerPortalCheck,
      InStoreCheck: form.InStoreCheck,
      Type: INPUT_FILE_TYPE.CustomerFile,
      FileType: form.FileType,
      HeaderRow: form.HeaderRow,
      Fields: getInputFieldsData()
    }
    const getItem = () => {
      if (form.FileType === FileTypeRadioValueList[1].value) {
        let delimitedPayload: any = {
          ..._item,
          FieldDelimiter: form.FieldDelimiter,
          RecordDelimiter: form.RecordDelimiter,
          SurroundCharacter: form.SurroundCharacter
        }
        if (form.RecordDelimiter === RecordDelimiterList[4]) {
          delimitedPayload = {
            ...delimitedPayload,
            ASCIICharValue: form.ASCIICharValue
          }
        }
        return delimitedPayload
      } else {
        return _item
      }
    }

    let error: any = false
    const customerObject: any = inputFiles.find(
      (i: any, idx) => i.Name === form.Name && idx !== locationState.index
    )
    if (customerObject) {
      setDuplicateNameError(true)
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      error = true
    } else if (!form.CustomerPortalCheck && !form.InStoreCheck) {
      error = true
      setHelperText(
        getTranslations(
          t,
          Translates.Please_select_at_least_one_of_the_upload_locations
        )
      )
    } else if (!form.FileType) {
      error = true
      setFileTypeError(
        getTranslations(
          t,
          Translates.Please_select_at_least_one_of_the_file_type
        )
      )
    }
    //istanbul ignore next
    else if (form.Name === "") {
      error = true
      setNameError(true)
    }
    //istanbul ignore next
    else if (
      form.RecordDelimiter === RecordDelimiterList[4] &&
      (form.ASCIICharValue === "" || form.ASCIICharValue === undefined)
    ) {
      error = true
      setRecordDelimiterError(true)
    } else {
      setDuplicateNameError(false)
    }
    if (!error) {
      setNameError(false)
      let preparedItem: any = form.InStoreCheck
        ? {
            ...getItem(),
            FileName: form.FileName,
            Position: form.Position
          }
        : getItem()
      if (locationState.type === INPUT_FILE_ACTION_TYPE.EDIT) {
        let foundIndex: any = inputFiles.findIndex(x => {
          return x.Name === locationState.data.Name
        })
        let newArr = [...inputFiles]
        newArr[foundIndex] = preparedItem
        dispatch(setInputFiles([...newArr]))
      } else {
        dispatch(setInputFiles([...inputFiles, preparedItem]))
      }

      updateWarnOnTabChangeToStore(false)
      setTimeout(() => {
        history.push(PATH.INPUT_FILES + search)
      })
    }
  }

  const displaySpan = (props: any) => {
    return <span className="d-block">{props.value ? props.value : "-"}</span>
  }
  const deleteField = (row: any) => {
    setIsConfirmDeleteOpen(true)
    setDeleteRow(row)
  }
  const setDeleteContent = () => {
    const title = `${getTranslations(
      t,
      Translates.Are_you_sure_you_want_to_delete
    )} ${deleteRow.Name}?`
    return (
      <Alert severity="error" icon={<Warning />} className="custom-alert">
        <AlertTitle className="alert__title">
          <h2>{getTranslations(t, Translates.Warning)}</h2>
        </AlertTitle>
        <p>
          {" "}
          <strong className="d-block">{title}</strong>
        </p>
      </Alert>
    )
  }
  const callbackDeleteConfirmation = (res: boolean) => {
    if (form.FileType === FileTypeRadioValueList[0].value) {
      if (res) {
        let dt: any
        dt = fixedLengthInputFields.filter(f => f.Name !== deleteRow.Name)
        dt = dt.map((m: any, i) => {
          return { ...m, SortOrder: i + 1 }
        })
        dispatch(setFixedLengthInputFields(dt))
      }
    } else if (form.FileType === FileTypeRadioValueList[1].value) {
      if (res) {
        let dt: any
        dt = delimitedInputFields.filter(f => f.Name !== deleteRow.Name)
        dt = dt.map((m: any, i) => {
          return { ...m, SortOrder: i + 1 }
        })
        dispatch(setDelimitedInputFields(dt))
      }
    } else {
      if (res) {
        let dt: any
        dt = excelInputFields.filter(f => f.Name !== deleteRow.Name)
        dt = dt.map((m: any, i) => {
          return { ...m, SortOrder: i + 1 }
        })
        dispatch(setExcelInputFields(dt))
      }
    }
    setIsConfirmDeleteOpen(false)
  }
  const editField = (row: any) => {
    dispatch(setCustomerFormValues(form))
    history.push(PATH.CREATE_INPUT_FIELDS + search, {
      type: INPUT_FILE_ACTION_TYPE.EDIT,
      radioValue: form.FileType,
      data: row,
      index: Number(row.id)
    })
  }
  const commonValues = {
    headerClassName: "temp_class",
    disableFilters: true,
    disableSortBy: true,
    setWidth: 150
  }
  columnHeadersList = [
    {
      ...{
        Header: getTranslations(t, Translates.Sort),
        accessor: "SortOrder"
      },
      ...commonValues,
      ...{ setWidth: 2 }
    },
    {
      ...{ Header: getTranslations(t, Translates.Name), accessor: "Name" },
      ...commonValues
    },
    {
      ...{ Header: getTranslations(t, Translates.LENGTH), accessor: "Length" },
      ...commonValues
    },
    {
      ...{
        Header: getTranslations(t, Translates.DATA_TYPE),
        accessor: "DataType"
      },
      ...commonValues
    },
    {
      ...{
        Header: getTranslations(t, Translates.ALLOWED_LENGTHS),
        accessor: "AllowedLengths"
      },
      ...commonValues
    },
    {
      ...{ Header: getTranslations(t, Translates.Reg_Ex), accessor: "Regex" },
      ...commonValues
    },
    {
      ...{
        Header: getTranslations(t, Translates.ERROR_LEVEL),
        accessor: "ErrorLevel"
      },
      ...commonValues
    }
  ]
  const getColumnHeaders = () => {
    if (form.FileType === FileTypeRadioValueList[0].value) {
      return columnHeadersList.splice(0, 6)
    } else {
      return columnHeadersList.filter(
        f => f.accessor !== Customer_Input_Fields.Length
      )
    }
  }
  let columnsData = React.useMemo(
    () => [
      getColumnHeaders().map(id => {
        return {
          Header: id.Header,
          accessor: id.accessor,
          headerClassName: id.headerClassName,
          disableFilters: id.disableFilters,
          disableSortBy: id.disableSortBy,
          setWidth: id.setWidth,
          Cell: props => {
            return displaySpan(props)
          }
        }
      }),
      {
        Header: getTranslations(t, Translates.ACTIONS),
        accessor: "action", // accessor is the "key" in the data
        headerClassName: "temp_class",
        disableFilters: true,
        disableSortBy: false,
        setWidth: 150,
        Cell: props => {
          return (
            <span className="action-btns d-block">
              <Tooltip arrow title={getTranslations(t, Translates.Edit)}>
                <IconButton
                  data-testid="edit"
                  onClick={() =>
                    editField({
                      ...props.row.original,
                      ...{ id: props.row.id }
                    })
                  }
                >
                  <img alt="EditIcon" src={editIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title={Translates.Delete}>
                <span>
                  <IconButton
                    data-testid="Delete"
                    onClick={() =>
                      deleteField({
                        ...props.row.original,
                        ...{ id: props.row.id }
                      })
                    }
                    className={`${
                      configTypeFromStore === "view" ? "disable-img" : "pointer"
                    }`}
                  >
                    <img alt="TrashIcon" src={TrashIcon} />
                  </IconButton>
                </span>
              </Tooltip>
            </span>
          )
        }
      }
    ],
    [form, t]
  )
  columnsData = columnsData.flat(Infinity)
  const getInputFieldsData = () => {
    let returnArr: any = []
    if (form.FileType === FileTypeRadioValueList[0].value) {
      returnArr = fixedLengthInputFields
    } else if (form.FileType === FileTypeRadioValueList[1].value) {
      returnArr = delimitedInputFields
    } else {
      returnArr = excelInputFields
    }
    return returnArr || []
  }
  const displayGrid = () => {
    return (
      <Grid container className="custom-table-responsive categories-table">
        <Grid xs={12} item data-testid="CEFCGlopTables">
          <GlobalTableDragDrop
            component="inputFiles"
            columns={columnsData}
            data={getInputFieldsData()}
            noDataText={getTableNoDataText()}
          ></GlobalTableDragDrop>
        </Grid>
        {isConfirmDeleteOpen && (
          <ConfirmDialog
            open={isConfirmDeleteOpen}
            callBack={callbackDeleteConfirmation}
            title={getTranslations(t, Translates.DELETE)}
            content={setDeleteContent}
            custref={{}}
            confirmButtonText={getTranslations(t, Translates.CONFIRM)}
            cancelButtonText={getTranslations(t, Translates.Cancel)}
          ></ConfirmDialog>
        )}
      </Grid>
    )
  }
  const getTableTitle = () => {
    if (form.FileType === INPUT_FILE_TYPE.Excel) {
      return getTranslations(t, FileTypeRadioValueList[2].value.toUpperCase())
    } else if (form.FileType === INPUT_FILE_TYPE.Delimited) {
      return getTranslations(t, FileTypeRadioValueList[1].value.toUpperCase())
    } else {
      return getTranslations(t, FileTypeRadioValueList[0].label.toUpperCase())
    }
  }
  const getTableNoDataText = () => {
    if (form.FileType === INPUT_FILE_TYPE.Excel) {
      return getTranslations(t, FileTypeRadioValueList[2].noDataText)
    } else if (form.FileType === INPUT_FILE_TYPE.Delimited) {
      return getTranslations(t, FileTypeRadioValueList[1].noDataText)
    } else {
      return getTranslations(t, FileTypeRadioValueList[0].noDataText)
    }
  }
  const handleNewFieldClick = () => {
    dispatch(setCustomerFormValues(form))
    updateWarnOnTabChangeToStore(false)
    setTimeout(() => {
      history.push(PATH.CREATE_INPUT_FIELDS + search, {
        type: INPUT_FILE_ACTION_TYPE.ADD,
        radioValue: form.FileType
      })
    })
  }
  const changeFieldText = e => {
    setInputFieldValue(e.target.value)
    setForm({ ...form, FieldDelimiter: e.target.value })
  }
  const changeRecordText = e => {
    setInputRecordValue(e.target.value)
    setForm({ ...form, RecordDelimiter: e.target.value })
  }
  const changeSurroundCharacterText = e => {
    setSurroundCharacterValue(e.target.value)
    setForm({ ...form, SurroundCharacter: e.target.value })
  }

  const getNameError = () => {
    if (nameError || emptyError) {
      return getTranslations(t, Translates.Name_cannot_be_empty)
    } else if (duplicateNameError) {
      return getTranslations(t, Translates.Name_already_exists)
    } else if (weekIVFields.Name) {
      return weekIVFields.Name
    }
    return ""
  }
  return (
    <>
      {loading ? (
        //istanbul ignore next
        Loader()
      ) : (
        <div>
          <div className="main-toolbar">
            <div className="main-container">
              <Grid container>
                <Grid item sm={6} className="d-flex align-items-center">
                  <div className="custom-breadcrumb CP">
                    <BreadCrumb
                      customPath={"New Customer File"}
                      breadCrumbInfo={getBreadcrumbConfig()}
                      renderedPage={""}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid container className="main-container">
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={8}>
                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <div className="text-box">
                        <TextField
                          data-testid={"notes1"}
                          name="Name"
                          fullWidth
                          label={getTranslations(
                            t,
                            Translates.Customer_Friendly_Name
                          )}
                          variant="filled"
                          onFocus={handleFocus}
                          autoSave="false"
                          autoComplete="off"
                          value={form.Name}
                          onChange={e => onFormChange(e)}
                          className="custom-form-control"
                          error={
                            nameError ||
                            emptyError ||
                            duplicateNameError ||
                            !!weekIVFields.Name
                          }
                          helperText={getNameError()}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <div className="text-box">
                        <TextField
                          data-testid={"DescriptionTextArea"}
                          multiline
                          fullWidth
                          name="Description"
                          onFocus={handleFocus}
                          label={getTranslations(t, Translates.Description)}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          value={form.Description}
                          onChange={e => onFormChange(e)}
                          inputProps={{ maxLength: 50 }}
                          rows={3}
                          className="custom-form-control"
                        />
                        <span className="note-text">
                          {form.Description?.length}/50
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="mt-2">
                    <Grid item xs={12} sm={4}>
                      <div className="text-box">
                        <TextField
                          data-testid={"notes1"}
                          fullWidth
                          onFocus={handleFocus}
                          name="LegacyLocation"
                          label={getTranslations(t, Translates.Legacy_Location)}
                          variant="filled"
                          autoSave="false"
                          autoComplete="off"
                          value={form.LegacyLocation}
                          onChange={e => onFormChange(e)}
                          className="custom-form-control"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Divider className="dashed my-4" />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <strong className="d-block mb-2">
                        {getTranslations(t, Translates.UPLOAD_LOCATIONS)}
                      </strong>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={e => onFormChange(e)}
                              checked={form.CustomerPortalCheck}
                              className="custom-check light"
                              name="CustomerPortalCheck"
                              checkedIcon={<CheckIcon />}
                            />
                          }
                          label={getTranslations(t, Translates.Customer_Portal)}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={e => onFormChange(e)}
                              checked={form.InStoreCheck}
                              className="custom-check light"
                              name="InStoreCheck"
                              checkedIcon={<CheckIcon />}
                            />
                          }
                          label={getTranslations(t, Translates.In_Store)}
                        />
                      </FormGroup>
                      <FormHelperText className="red-text">
                        {helperText}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Divider className="dashed my-4" />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <strong className="d-block mb-2">
                        {getTranslations(t, Translates.FILE_TYPE)}
                      </strong>
                      <FormControl data-testid="CEIFCFileType">
                        <RadioGroup
                          row
                          value={form.FileType}
                          name="FileType"
                          onChange={e => onFormChange(e)}
                        >
                          {FileTypeRadioValueList.map(q => {
                            return (
                              <FormControlLabel
                                className="mr-7"
                                value={q.value}
                                key={q.value}
                                control={<Radio />}
                                label={getTranslations(t, q.label)}
                              />
                            )
                          })}
                        </RadioGroup>
                      </FormControl>
                      <FormHelperText className="red-text">
                        {fileTypeError}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Divider className="dashed my-4" />
                    </Grid>
                  </Grid>
                  {form.InStoreCheck ? (
                    <>
                      <Grid container>
                        <Grid item xs={12} sm={6}>
                          <strong className="d-block mb-2">
                            {getTranslations(
                              t,
                              Translates.STORE_NUMBER_WHEN_PROCESSED_IN_STORE
                            )}
                          </strong>
                          <Grid item xs={12} sm={8}>
                            <div className="text-box">
                              <TextField
                                data-testid={"fileName"}
                                fullWidth
                                name="FileName"
                                label={getTranslations(t, Translates.File_Name)}
                                variant="filled"
                                onFocus={handleFocus}
                                autoSave="false"
                                autoComplete="off"
                                value={form.FileName}
                                onChange={e => onFormChange(e)}
                                className="custom-form-control"
                              />
                            </div>
                            <div className="text-box">
                              <TextField
                                data-testid={"field"}
                                fullWidth
                                name="Position"
                                label={
                                  form.FileType ===
                                  FileTypeRadioValueList[2].label
                                    ? getTranslations(t, Translates.Column)
                                    : getTranslations(t, Translates.Position)
                                }
                                variant="filled"
                                autoSave="false"
                                autoComplete="off"
                                value={form.Position}
                                onFocus={handleFocus}
                                onChange={e => onFormChange(e)}
                                className="custom-form-control"
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Divider className="dashed my-4" />
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <strong className="d-block mb-2">
                        {getTranslations(t, Translates.FORMAT_VALIDATION)}
                      </strong>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={e => onFormChange(e)}
                              checked={form.HeaderRow}
                              className="custom-check light"
                              name="HeaderRow"
                              checkedIcon={<CheckIcon />}
                            />
                          }
                          label={getTranslations(t, Translates.Header_Row)}
                        />
                      </FormGroup>
                      {form.FileType === INPUT_FILE_TYPE.Delimited ? (
                        <Grid container>
                          <Grid item xs={12} sm={8}>
                            <Autocomplete
                              data-testid="FieldDelimiterAutoC"
                              id="FieldDelimiter"
                              value={form.FieldDelimiter}
                              onChange={(
                                event: any,
                                newValue: string | null
                              ) => {
                                onFormDelimitedChange(newValue)
                              }}
                              inputValue={inputFieldValue}
                              onInputChange={(event, newInputValue) => {
                                onFormDelimitedChange(newInputValue)
                                setInputFieldValue(newInputValue)
                              }}
                              freeSolo
                              className="custom-form-control select no-border mt-1"
                              options={FieldDelimiterList.map(option => option)}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label={getTranslations(
                                    t,
                                    Translates.Field_Delimiter
                                  )}
                                  variant="filled"
                                  onKeyDown={e => {
                                    if (e.key === "Enter") {
                                      changeFieldText(e)
                                    }
                                  }}
                                />
                              )}
                            />
                            <Autocomplete
                              data-testid="RecordDelimiterAutoC"
                              id="RecordDelimiter"
                              value={form.RecordDelimiter}
                              onChange={(
                                event: any,
                                newValue: string | null
                              ) => {
                                onFormRecordChange(newValue)
                              }}
                              inputValue={inputRecordValue}
                              onInputChange={(event, newInputValue) => {
                                onFormRecordChange(newInputValue)
                                setInputRecordValue(newInputValue)
                              }}
                              freeSolo
                              className="custom-form-control select no-border mt-1"
                              options={RecordDelimiterList.map(
                                option => option
                              )}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label={getTranslations(
                                    t,
                                    Translates.Record_Delimiter
                                  )}
                                  variant="filled"
                                  onKeyDown={e => {
                                    if (e.key === "Enter") {
                                      changeRecordText(e)
                                    }
                                  }}
                                />
                              )}
                            />
                            {form.RecordDelimiter === RecordDelimiterList[4] ? (
                              <div className="text-box">
                                <TextField
                                  data-testid={"field"}
                                  fullWidth
                                  name="ASCIICharValue"
                                  label={getTranslations(
                                    t,
                                    Translates.ASCIIChar_Value
                                  )}
                                  variant="filled"
                                  autoSave="false"
                                  autoComplete="off"
                                  value={form.ASCIICharValue}
                                  onFocus={handleFocus}
                                  onChange={e => onFormChange(e)}
                                  className="custom-form-control"
                                  error={recordDelimiterError}
                                  helperText={
                                    recordDelimiterError
                                      ? getTranslations(
                                          t,
                                          Translates.Please_enter_ASCIIChar_value
                                        )
                                      : ""
                                  }
                                />
                              </div>
                            ) : null}
                            <div className="text-box custom-popper input">
                              <Autocomplete
                                data-testid="SurroundCharacterAutoC"
                                id="SurroundCharacter"
                                value={form.SurroundCharacter}
                                onChange={(
                                  event: any,
                                  newValue: string | null
                                ) => {
                                  onFormSurroundCharacterChange(newValue)
                                }}
                                inputValue={surroundCharacterValue}
                                onInputChange={(event, newInputValue) => {
                                  onFormSurroundCharacterChange(newInputValue)
                                  setSurroundCharacterValue(newInputValue)
                                }}
                                freeSolo
                                className="custom-form-control select no-border mt-1"
                                options={surroundCharacterValues.map(
                                  option => option
                                )}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label={getTranslations(
                                      t,
                                      Translates.Surround_Character
                                    )}
                                    variant="filled"
                                    onKeyDown={e => {
                                      if (e.key === "Enter") {
                                        changeSurroundCharacterText(e)
                                      }
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {form.FileType ? (
              <Grid container className="main-container">
                <Grid item xs={12}>
                  <Divider className="dashed my-4" />
                </Grid>
                <Grid item xs={6}>
                  <strong className="d-block mt-2">{getTableTitle()}</strong>
                </Grid>
                <Grid item xs={6} className="text-right">
                  <Button
                    variant="contained"
                    className="primary-btn"
                    onClick={handleNewFieldClick}
                    data-testid="newFieldBtn"
                    disabled={configTypeFromStore === "view"}
                  >
                    <AddIcon className="mr-1" />{" "}
                    {getTranslations(t, Translates.ADD_FIELD)}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} className="mt-2">
                  {displayGrid()}
                </Grid>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <div className="inner-view__footer">
                <Button
                  data-testid="cancelBtn"
                  variant="text"
                  className="primary-btn cancel-btn mr-5"
                  onClick={onCancelClick}
                >
                  {getTranslations(t, Translates.Cancel)}
                </Button>
                <Button
                  data-testid="saveBtn"
                  variant="contained"
                  className="primary-btn"
                  disabled={
                    !form.Name ||
                    configTypeFromStore === "view" ||
                    Object.values(weekIVFields).some(s => s || s !== "")
                  }
                  onClick={onSaveClick}
                >
                  {getTranslations(t, Translates.Save)}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default CreateEditInputFileCustomer
