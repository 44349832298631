import { useEffect, useState } from "react"
import {
  Grid,
  TextField,
  Alert,
  AlertTitle,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  AlertColor,
  Autocomplete
} from "@mui/material"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"
import CheckIcon from "@mui/icons-material/Check"
import GeneralSettingsToolBar from "../../../src/components/ConfigurationPortal/GeneralSettingsToolBar/GeneralSettingsToolBar"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../src/redux/store"
import {
  setGeneralSettings,
  setDefaultConfigName,
  setEfficiencySettings,
  setAccuracySettings
} from "../../../src/redux/slices/configSlice"
import { DEFAULT_RADIX, STATUS_CONSTANTS } from "../../../src/utils/constants"
import { ConfigurationStoreType } from "src/utils/types/types"
import { ConfigurationStoreInitiate } from "src/constants/configurationPortal"
import AlertC from "src/components/Alert/Alert"
import { configFileValidationCheck } from "src/pages/Configuration/helper"
import shortid from "shortid"
import {
  getConfigurationDecryptAndSetRedux,
  getTranslations,
  validateDecimal,
  validateInputString
} from "src/utils/helper"
import { updateWarnOnTabChangeToStore } from "./helper"

import {
  formatInteger,
  generatePercentageValues,
  percentageValueConversion
} from "./Audits/AuditHelper"
import { ConfigDataHook } from "src/hooks/configurationFileData"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
export const currencies = [
  { key: "usd_currency", label: `$ - USD - US Dollar`, value:"$ - USD - US Dollar" },
  { key: "mxn_currency", label: `$ - MXN - MEXICAN PESO` , value:"$ - MXN - MEXICAN PESO"},
  { key: "cad_currency", label: `$ - CAD - Canadian Dollar`, value:"$ - CAD - Canadian Dollar" }
  // "¥ - CNY - Chinese Yuan" commenting as it is not yet integrated
]

const GeneralSettings = ({ liftSettingsSaveClick }) => {
  const {
    config: { customerConfigurations, isGetConfigDecrypt, configDetails }
  } = ConfigDataHook()
  const { t } = useTranslation<any>()
  const [configNameError, setConfigNameError] = useState(false)
  const handleLanguagesChange = index => {
    updateWarnOnTabChangeToStore(true)
    window.onbeforeunload = () => true
    let options = [...languages]
    options[index].selected = !options[index].selected
    setLanguages(options)
  }
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const customerName = useSelector(
    (state: RootState) => state.settings?.selectedCustomerNameForOperation
  )
  const configSliceData = useSelector((state: RootState) => state.config)
  const configNameFromStore = configSliceData?.configName
  const configTypeFromStore = configSliceData?.configType

  const dispatch = useDispatch()
  const [languages, setLanguages] = useState([] as any)
  const LANG_OPTIONS = [
    {
      label: getTranslations(t, Translates.english_lang_dropmenu),
      value: "en-US",
      selected: false
    },
    {
      label: getTranslations(t, Translates.french_lang_dropmenu),
      value: "fr-CA",
      selected: false
    },
    {
      label: getTranslations(t, Translates.spanish_lang_dropmenu),
      value: "es-MX",
      selected: false
    }
    // { label: "Simplified Chinese", value: "zh-CN", selected: false } // commenting as it is not yet integrated
  ]
  const STATUS_OBJECT = {
    Active: 1,
    InActive: 2,
    NotInstalled: 3
  }

  const prepareLanguageOptions = (selectedLanguages: string[] = []) => {
    if (!languages.length && !selectedLanguages.length) {
      setLanguages(LANG_OPTIONS)
      return
    }
    let options = LANG_OPTIONS.map(lang => {
      let langObject = { ...lang }
      langObject.selected = selectedLanguages?.includes(lang.value)
      return langObject
    })
    setLanguages(options)
  }

  const [alertProps, setAlertProps] = useState({
    title: "",
    severity: "success" as AlertColor,
    content: ""
  })
  const [showAlert, setShowAlert] = useState(false)

  const [configData, setConfigData] = useState<any>({})
  const [currency, setCurrency] = useState("")
  const [notes, setNotes] = useState("")
  const [notesLength, setNotesLength] = useState(0)
  const [configName, setConfigName] = useState("")
  const [status, setStatus] = useState("")

  // efficiency and accuracy setting fields
  const [efficiencyGoal, setEfficiencyGoal] = useState("")
  const [secondayEfficiencyGoal, setSecondayEfficiencyGoal] = useState("")
  const [downTimeGoal, setDownTimeGoal] = useState("")
  const [secondarydownTimeGoal, setSecondarydownTimeGoal] = useState("")
  const [accuracyGoal, setAccuracyGoal] = useState("")
  const [secondayAccuracyGoal, setSecondayAccuracyGoal] = useState("")
  const [includeAuditTags, setIncludeAuditTags] = useState(false)

  const [efficiencyGoalError, setEfficiencyGoalError] = useState("")
  const [secondayEfficiencyGoalError, setSecondayEfficiencyGoalError] =
    useState("")

  let selectedLanguages: any
  const [configEmptyError, setConfigEmptyError] = useState(false)
  const [configNameErrorMsg, setConfigNameErrorMsg] = useState("")
  const [isDisabled, setIsDisabled] = useState(false)
  useEffect(() => {
    prepareLanguageOptions()
  }, [])

  useEffect(() => {
    setConfigData(configDetails)
    setConfigName(configDetails?.name || configNameFromStore)
    if (configDetails?.configuration && isGetConfigDecrypt) {
      const configObject = getConfigurationDecryptAndSetRedux(configSliceData)
      setNotes(configObject?.GeneralSettings?.Notes)
      setNotesLength(configObject?.GeneralSettings?.Notes?.length)
      setCurrency(configObject?.GeneralSettings?.Currencies)
      // Efficiency
      setEfficiencyGoal(configObject?.Efficiency?.Goal)
      setSecondayEfficiencyGoal(configObject?.Efficiency?.SecondaryGoal)
      setDownTimeGoal(
        percentageValueConversion(
          configObject?.Efficiency?.DownTimeGoal
        )?.toString()
      )
      setSecondarydownTimeGoal(
        percentageValueConversion(
          configObject?.Efficiency?.DownTimeSecondaryGoal
        )?.toString()
      )

      //Accuracy
      setAccuracyGoal(
        percentageValueConversion(configObject?.Accuracy?.Goal)?.toString()
      )
      setSecondayAccuracyGoal(
        percentageValueConversion(
          configObject?.Accuracy?.SecondaryGoal
        )?.toString()
      )
      setIncludeAuditTags(configObject?.Accuracy?.IncludeTagAudits)

      prepareLanguageOptions(configObject?.GeneralSettings?.SupportedLanguages)
      dispatch(setGeneralSettings(configObject?.GeneralSettings))
    }
  }, [configDetails, isGetConfigDecrypt, t])

  const handleChange = (event, id) => {
    updateWarnOnTabChangeToStore(true)
    window.onbeforeunload = () => true
    let val = event.target.value
    if (id === 1) {
      setConfigEmptyError(false)
      setIsDisabled(false)
      setConfigNameErrorMsg("")
      setConfigName(event.target.value)
      if (event.target.value) {
        const isValid = validateInputString(val)
        if (!isValid) {
          setIsDisabled(true)
          setConfigNameErrorMsg(getTranslations(t, Translates.Invalid_Input))
        }
      } else {
        setConfigEmptyError(true)
        setIsDisabled(true)
      }
    } else if (id === 2) {
      setNotesLength(event.target.textLength || event.target.value.length)
      setNotes(event.target.value)
    } else if (id === 3) {
      setCurrency(event.target.value)
    } else if (id === 4) {
      if (event.target.value === STATUS_CONSTANTS.Active) {
        if (!configFileValidation()) return false
      }

      setStatus(event.target.value)
    } else if (id === 5) {
      if (event.target.name === "EfficiencyGoal") {
        setEfficiencyGoal(val)
        setEfficiencyGoalError("")
        if (!validateDecimal(val)) {
          setEfficiencyGoal(val.substring(0, val - 1))
        } else {
          if (Number(val || 0) <= Number(secondayEfficiencyGoal || 0)) {
            setEfficiencyGoalError(
              "Efficiency goal should greater than secondary efficiency goal"
            )
          } else {
            setSecondayEfficiencyGoalError("")
          }
        }
      }
    } else if (id === 6) {
      if (event.target.name === "SecondaryEfficiencyGoal") {
        setSecondayEfficiencyGoal(val)
        setSecondayEfficiencyGoalError("")
        if (!validateDecimal(val)) {
          setSecondayEfficiencyGoal(val.substring(0, val - 1))
        } else {
          if (efficiencyGoal !== "") {
            if (Number(efficiencyGoal) < Number(val)) {
              setSecondayEfficiencyGoalError(
                "Secondary efficiency goal should less than efficiency goal"
              )
            } else {
              setEfficiencyGoalError("")
            }
          }
        }
      }
    } else if (id === 7) {
      if (event.target.name === "DowntimeGoal") {
        setDownTimeGoal(val)
        if (!validateDecimal(val)) {
          setDownTimeGoal(val.substring(0, val - 1))
        }
      }
    } else if (id === 8) {
      if (event.target.name === "SecondaryDowntimeGoal") {
        setSecondarydownTimeGoal(val)
        if (!validateDecimal(val)) {
          setSecondarydownTimeGoal(val.substring(0, val - 1))
        }
      }
    } else if (id === 9) {
      if (event.target.name === "AccuracyGoal") {
        setAccuracyGoal(val)
        if (!validateDecimal(val)) {
          setAccuracyGoal(val.substring(0, val - 1))
        }
      }
    } else if (id === 10) {
      if (event.target.name === "SecondaryAccuracyGoal") {
        setSecondayAccuracyGoal(val)
        if (!validateDecimal(val)) {
          setSecondayAccuracyGoal(val.substring(0, val - 1))
        }
      }
    } else if (id === 11) {
      if (event.target.name === "includeAuditTags") {
        setIncludeAuditTags(event.target.checked)
      }
    }
  }

  const onPercentageChange = (event, val) => {
    updateWarnOnTabChangeToStore(true)
    let targetID = event?.target?.id?.split("-")[0]

    if (targetID === "DowntimeGoal") {
      setDownTimeGoal(val)
    } else if (targetID === "SecondaryDowntimeGoal") {
      setSecondarydownTimeGoal(val)
    } else if (targetID === "AccuracyGoal") {
      setAccuracyGoal(val)
    } else if (targetID === "SecondaryAccuracyGoal") {
      setSecondayAccuracyGoal(val)
    }
  }

  const setStatusValue = statusValue => {
    if (
      statusValue === STATUS_CONSTANTS.Active ||
      statusValue === STATUS_CONSTANTS.InActive
    ) {
      return STATUS_OBJECT[statusValue]
    } else {
      return STATUS_OBJECT.NotInstalled
    }
  }
  const settingsSaveClick = () => {
    let error: any = false
    selectedLanguages = languages.filter((lan: any) => lan.selected)
    selectedLanguages = selectedLanguages.map((sel: any) => {
      return sel.value
    })

    let generalSettings = {
      CustomerID: customerId,
      CustomerName: customerName,
      ConfigurationId: configData?.idConfig,
      ConfigurationName: configName,
      ConfigurationVersion: parseFloat(configData?.version).toFixed(1),
      Currencies: currency,
      Notes: notes,
      SupportedLanguages: selectedLanguages,
      Status: setStatusValue(status)
    }
    let efficiencySettings = {
      Goal: efficiencyGoal ? parseInt(efficiencyGoal, DEFAULT_RADIX) : 0,
      SecondaryGoal: secondayEfficiencyGoal
        ? parseInt(secondayEfficiencyGoal, DEFAULT_RADIX)
        : 0,
      DownTimeGoal: downTimeGoal
        ? parseFloat(formatInteger(downTimeGoal)?.toString())
        : 0,
      DownTimeSecondaryGoal: secondarydownTimeGoal
        ? parseFloat(formatInteger(secondarydownTimeGoal)?.toString())
        : 0
    }

    let accuracySettings = {
      IncludeTagAudits: includeAuditTags,
      Goal: accuracyGoal
        ? parseFloat(formatInteger(accuracyGoal)?.toString())
        : 0,
      SecondaryGoal: secondayAccuracyGoal
        ? parseFloat(formatInteger(secondayAccuracyGoal)?.toString())
        : 0
    }
    let configObject: any = customerConfigurations?.find((config: any) => {
      if (configName)
        return (
          config?.name?.toLowerCase() === configName?.toLowerCase() &&
          config.idConfig !== configData?.idConfig
        )
    })
    if (
      configObject &&
      configDetails.version <= 1.0 &&
      !configTypeFromStore?.includes("newVersion") &&
      !configTypeFromStore?.includes("reopen")
    ) {
      error = true
      setConfigNameError(true)
    } else {
      setConfigNameError(false)
    }
    if (!error) {
      window.onbeforeunload = null
      dispatch(setDefaultConfigName(generalSettings.ConfigurationName))
      dispatch(setGeneralSettings(generalSettings))
      dispatch(setEfficiencySettings(efficiencySettings))
      dispatch(setAccuracySettings(accuracySettings))
      if (!efficiencyGoalError && !secondayEfficiencyGoalError) {
        liftSettingsSaveClick(
          {
            ...generalSettings,
            SupportedLanguages: selectedLanguages,
            EfficiencySettings: efficiencySettings,
            AccuracySettings: accuracySettings
          },
          "GSTab"
        )
      }
    }
  }

  let alertVisibleStatus: any = customerConfigurations?.map(id => {
    return id.statusId === STATUS_OBJECT.Active
  })
  const setDisabledStatus = () => {
    if (
      configTypeFromStore &&
      (configTypeFromStore?.includes("newVersion") ||
        configTypeFromStore?.includes("reopen"))
    ) {
      return true
    } else if (configDetails?.version !== "1.0") {
      return true
    } else {
      return false
    }
  }

  const configFileValidation = (): boolean => {
    let dt: ConfigurationStoreType = {
      ...ConfigurationStoreInitiate,
      ...getConfigurationDecryptAndSetRedux(configSliceData)
    }
    const { errorCount, error } = configFileValidationCheck(dt)

    if (errorCount > 0) {
      toastMsgShow({
        title: getTranslations(
          t,
          Translates.Configuration_details_not_completed
        ),
        severity: "error" as AlertColor,
        content: error
      })
    }

    return errorCount === 0
  }
  const configErrMsg = (configNameError, configEmptyError) => {
    if ((configNameError || configEmptyError) && !configNameErrorMsg) {
      return getTranslations(t, Translates.Configuration_name_cannot_be_empty)
    } else if (configNameErrorMsg) {
      return configNameErrorMsg
    }

    return ""
  }
  const toastMsgShow = alertMsg => {
    setAlertProps({
      ...alertMsg
    })
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 3000)
  }
  return (
    <>
      <GeneralSettingsToolBar
        settingsSaveClick={settingsSaveClick}
        disabled={
          isDisabled || efficiencyGoalError || secondayEfficiencyGoalError
            ? true
            : false
        }
      />
      <Grid container className="main-container">
        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <label className="form-control__label mb-3">
                {getTranslations(t, Translates.Configuration_Name)}
              </label>
              <TextField
                data-testid="configName"
                fullWidth
                label={getTranslations(t, Translates.Name)}
                variant="filled"
                autoSave="false"
                inputProps={{ maxLength: 60 }}
                value={configName}
                onChange={e => handleChange(e, 1)}
                name="settingsConfigName"
                autoComplete="off"
                className="custom-form-control"
                error={
                  configNameError || configEmptyError || !!configNameErrorMsg
                }
                disabled={setDisabledStatus()}
                helperText={configErrMsg(configNameError, configEmptyError)}
              />
            </Grid>
          </Grid>
          <Divider className="dashed mt-3 mb-4" />
          <Grid container>
            <Grid item xs={12} sm={6}>
              <label className="form-control__label mb-2">
                {getTranslations(t, Translates.SupportedLanguages)}
              </label>
              {languages.map((lan: any, idx) => {
                return (
                  <FormGroup
                    className="lang-checks"
                    key={`${lan}-${shortid.generate()}`}
                    data-testid={"checkbox"}
                  >
                    <div className="lang-checks__row">
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="langChk"
                            checked={lan.selected}
                            name="english"
                            className="custom-check light"
                            onChange={e => handleLanguagesChange(idx)}
                            checkedIcon={<CheckIcon />}
                          />
                        }
                        label={lan.label}
                      />
                    </div>
                  </FormGroup>
                )
              })}
            </Grid>
          </Grid>
          <Divider className="dashed mt-3 mb-4" />
          <Grid container>
            <Grid item xs={12} sm={6}>
              <label className="form-control__label mb-2">
                {getTranslations(t, Translates.SelectCurrency)}
              </label>
              <div className="text-box">
                <FormControl
                  variant="filled"
                  className="custom-form-control select"
                >
                  <InputLabel
                    id="demo-multiple-checkbox-label"
                    variant="filled"
                  >
                    {getTranslations(t, Translates.Currency)}
                  </InputLabel>
                  <Select
                    id="demo-multiple-checkbox"
                    value={currency}
                    onChange={e => handleChange(e, 3)}
                    data-testid={"menu-item"}
                  >
                    {currencies?.map((c: any, index) => (
                      <MenuItem
                        key={c.label}
                        value={c.value}
                        className="dropdown-list"
                      >
                        <small>
                          <ListItemText
                            primary={
                              <>
                                <span style={{ fontWeight: "bold" }}>
                                  {c.label.split("-")[0]}
                                </span>{" "}
                                -{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {c.label.split("-")[1]}
                                </span>{" "}
                                - {getTranslations(t, c.key)}
                              </>
                            }
                          />
                        </small>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Divider className="dashed mt-3 mb-4" />
          <Grid container>
            <Grid item xs={12} sm={8}>
              <label
                className="form-control__label mb-2"
                data-testid={"notes1"}
              >
                {getTranslations(t, Translates.ConfigurationNotes)}
              </label>
              <div className="text-box">
                <TextField
                  data-testid="notes"
                  multiline
                  fullWidth
                  label={getTranslations(t, Translates.Notes)}
                  variant="filled"
                  autoSave="false"
                  autoComplete="off"
                  value={notes}
                  onChange={e => handleChange(e, 2)}
                  inputProps={{ maxLength: 255 }}
                  rows={3}
                  className="custom-form-control"
                />
                <span className="note-text">{notesLength}/255</span>
              </div>
            </Grid>
          </Grid>
          {configData?.statusId === STATUS_OBJECT.NotInstalled ? (
            <>
              <Divider className="dashed my-4" />
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <label className="form-control__label mb-2 d-block">
                    {getTranslations(t, Translates.STATUS)}
                  </label>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      onChange={e => handleChange(e, 4)}
                      value={status}
                    >
                      <FormControlLabel
                        value={STATUS_CONSTANTS.Active}
                        control={<Radio />}
                        label={getTranslations(t, STATUS_CONSTANTS.Active)}
                      />
                      <FormControlLabel
                        value={STATUS_CONSTANTS.InActive}
                        control={<Radio />}
                        label={getTranslations(t, STATUS_CONSTANTS.Inactive)}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container className="justify-content-end">
            {alertVisibleStatus?.includes(true) ? (
              <Grid item xs={12} sm={8}>
                <Alert
                  severity="warning"
                  icon={<NotificationsActiveIcon />}
                  className="custom-alert"
                >
                  <AlertTitle className="custom-alert__title f-600">
                    {getTranslations(t, "Alert")}
                  </AlertTitle>
                  <p>
                    <strong className="d-block">
                      {getTranslations(t, Translates.Default_Configuration)}
                    </strong>
                    {getTranslations(
                      t,
                      Translates.There_is_already_a_default_configuration_for_this_customer
                    )}
                  </p>
                </Alert>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={8} className="mt-5">
              <Grid container>
                <Grid item xs={12} sm={10}>
                  <label className="form-control__label mb-3">
                    {getTranslations(t, Translates.Goals)}
                  </label>
                  <TextField
                    data-testid="EfficiencyGoal"
                    fullWidth
                    label={getTranslations(t, Translates.Efficiency_Goal)}
                    variant="filled"
                    autoSave="false"
                    autoComplete="off"
                    className="custom-form-control"
                    name="EfficiencyGoal"
                    value={efficiencyGoal}
                    onChange={e => handleChange(e, 5)}
                    error={!!efficiencyGoalError}
                    helperText={efficiencyGoalError}
                  />
                  <TextField
                    data-testid="SecondaryEfficiencyGoal"
                    fullWidth
                    label={getTranslations(
                      t,
                      Translates.Secondary_Efficiency_Goal
                    )}
                    variant="filled"
                    autoSave="false"
                    autoComplete="off"
                    className="custom-form-control"
                    name="SecondaryEfficiencyGoal"
                    value={secondayEfficiencyGoal}
                    onChange={e => handleChange(e, 6)}
                    error={!!secondayEfficiencyGoalError}
                    helperText={secondayEfficiencyGoalError}
                  />
                  <FormControl
                    className="custom-form-control select no-border multi-select"
                    variant="filled"
                  >
                    <Autocomplete
                      data-testid="downtimeGoal"
                      id="DowntimeGoal"
                      className="auto-complete-full"
                      fullWidth
                      disablePortal={true}
                      options={generatePercentageValues()}
                      value={downTimeGoal}
                      onChange={onPercentageChange}
                      getOptionLabel={(option: any) => option}
                      renderInput={params => (
                        <TextField
                          {...params}
                          value={downTimeGoal}
                          name="DowntimeGoal"
                          label={getTranslations(t, Translates.Downtime_Goal)}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl
                    className="custom-form-control select no-border multi-select"
                    variant="filled"
                  >
                    <Autocomplete
                      data-testid="SecondaryDowntimeGoal"
                      id="SecondaryDowntimeGoal"
                      className="auto-complete-full"
                      fullWidth
                      disablePortal={true}
                      options={generatePercentageValues()}
                      onChange={onPercentageChange}
                      value={secondarydownTimeGoal}
                      getOptionLabel={(option: any) => option}
                      renderInput={params => (
                        <TextField
                          {...params}
                          value={secondarydownTimeGoal}
                          name="SecondaryDowntimeGoal"
                          label={getTranslations(
                            t,
                            Translates.Secondary_Downtime_Goal
                          )}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl
                    className="custom-form-control select no-border multi-select"
                    variant="filled"
                  >
                    <Autocomplete
                      data-testid="AccuracyGoal"
                      id="AccuracyGoal"
                      className="auto-complete-full"
                      fullWidth
                      disablePortal={true}
                      options={generatePercentageValues()}
                      onChange={onPercentageChange}
                      value={accuracyGoal}
                      getOptionLabel={(option: any) => option}
                      renderInput={params => (
                        <TextField
                          {...params}
                          value={accuracyGoal}
                          name="AccuracyGoal"
                          label={getTranslations(t, Translates.Accuracy_Goal)}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl
                    className="custom-form-control select no-border multi-select"
                    variant="filled"
                  >
                    <Autocomplete
                      data-testid="SecondaryAccuracyGoal"
                      id="SecondaryAccuracyGoal"
                      className="auto-complete-full"
                      fullWidth
                      disablePortal={true}
                      options={generatePercentageValues()}
                      onChange={onPercentageChange}
                      value={secondayAccuracyGoal}
                      getOptionLabel={(option: any) => option}
                      renderInput={params => (
                        <TextField
                          {...params}
                          value={secondayAccuracyGoal}
                          name="SecondaryAccuracyGoal"
                          label={getTranslations(
                            t,
                            Translates.Secondary_Accuracy_Goal
                          )}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid="SecondaryAccuracyChk"
                        //checked={}
                        name="includeAuditTags"
                        onChange={e => handleChange(e, 11)}
                        className="custom-check light"
                        checkedIcon={<CheckIcon />}
                        checked={includeAuditTags}
                      />
                    }
                    label={getTranslations(
                      t,
                      Translates.Include_Tag_Audits_in_Accuracy_Calculation
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>{showAlert ? <AlertC {...alertProps} /> : null}</Grid>
    </>
  )
}

export default GeneralSettings
